import React, {useState, useEffect} from 'react';
import {ResponsiveBar} from "@nivo/bar";
import { useTheme } from '@nivo/core';

const PLOT_COLORS_TESTS = {
  'A': 'hsl(192,82%,85%)',
  'M': 'hsl(263,71%,78%)',
  'P': 'hsl(334,75%,73%)',
  'SR': 'hsl(32,100%,81%)',
  'M2': 'hsl(107,74%,79%)',
  'LOADING': 'hsl(0,0%,91%)'
}

const DEFAULT_DATA_LOADING = [
  {type: 'A', tat: 14, barColor: PLOT_COLORS_TESTS['LOADING']},
  {type: 'M', tat: 14, barColor: PLOT_COLORS_TESTS['LOADING']},
  {type: 'P', tat: 14, barColor: PLOT_COLORS_TESTS['LOADING']},
  {type: 'SR', tat: 14, barColor: PLOT_COLORS_TESTS['LOADING']},
  {type: 'M2', tat: 14, barColor: PLOT_COLORS_TESTS['LOADING']}
]


export default (props) => {
  const {data, loading} = props;
  const [barData, setBarData] = useState(null);
  const [timeline, setTimeline] = useState({});

  useEffect(() => {
    if (data && data.caseTatSummary) {
      const tat = data.caseTatSummary;
      const {a, m, p, sr, m2, timeline} = tat;

      if (timeline && timeline.length > 0) {
        const res = {}
        timeline.forEach((item) => {
          res[item.id] = item.data
        })
        setTimeline(res);
      }

      const getData = (type) => {
        if (timeline[type] && timeline[type].data) {
          return timeline[type].data
        }
        return []
      }

      if ((a + m + p + sr + m2) > 0) {
        setBarData([
          {type: 'A', tat: a, barColor: PLOT_COLORS_TESTS['A'], data: getData('A')},
          {type: 'M', tat: m, barColor: PLOT_COLORS_TESTS['M'], data: getData('M')},
          {type: 'P', tat: p, barColor: PLOT_COLORS_TESTS['P'], data: getData('P')},
          {type: 'SR', tat: sr, barColor: PLOT_COLORS_TESTS['SR'], data: getData('SR')},
          {type: 'M2', tat: m2, barColor: PLOT_COLORS_TESTS['M2'], data: getData('M2')}
        ]);
      } else {
        setBarData(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setBarData(DEFAULT_DATA_LOADING);
    }
  }, [loading]);

  const CustomTick = (tick) => {
    const theme = useTheme();
    let rate_pct = 0;
    const deadline = tick.value === 'M2' ? 7 : 14

    // console.log(tick)

    const data = timeline[tick.value]
    if (data && data.length > 0) {
      //const aboveDeadline = data.filter((item) => item.y > deadline).length
      const belowDeadline = data.filter((item) => item.y <= deadline).length

      rate_pct = belowDeadline / data.length * 100
      rate_pct = Math.round((rate_pct + Number.EPSILON) * 100) / 100
    }


    // barData.forEach(item => {
    //   if (!loading && item.type === tick.value) {
    //     const goal = tick.value === 'M2' ? 7 : 14
    //     const diff = item.tat - goal
    //     const rate = (1 - (diff <= 0 ? 0 : (diff / goal))) * 100
    //     rate_pct = Math.round((rate + Number.EPSILON) * 100) / 100
    //   }
    // })

    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: rate_pct < 100 ? 'rgba(231,145,17,0.81)' : '#333',
            fontSize: 10,
          }}
        >
          {tick.value} ({rate_pct}%)
        </text>
      </g>
    )
  }

  return barData ? (
    <div style={{height: 120, marginTop: 40}}>
      <ResponsiveBar
        data={barData}
        keys={['tat']}
        indexBy={'type'}
        margin={{top: 10, right: 12, bottom: 54, left: 30}}
        padding={0.3}
        colors={bar => bar.data.barColor}
        borderColor={{from: 'color', modifiers: [['darker', 1.9]]}}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 40,
          renderTick: CustomTick
        }}
        enableGridY={true}
        gridYValues={[0, 7, 14, 21]}
        axisLeft={{
          tickValues: [7, 14, 21]
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={loading ? 'transparent' : {from: 'color', modifiers: [['darker', 1.6]]}}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  ) : (
    <div>
      No Data
    </div>
  )
}
