import React, {FunctionComponent, useState} from 'react';
import {Button, Classes, Dialog, EditableText, H4, Icon, Intent, Tag} from "@blueprintjs/core";


type ComponentProps = {
  isOpen: boolean,
  onClose: () => void,
  onUpdate: (args: any) => void,
  data: any
}


const StepDialog: FunctionComponent<ComponentProps> = ({isOpen= false, data = {}, onClose, onUpdate}) => {
  // status: completed, started, canceled
  const [completed, setCompleted] = useState(!!data.completed)
  const [started, setStarted] = useState(!!data.started)
  const [canceled, setCanceled] = useState(!!data.canceled)
  const [notes, setNotes] = useState<any[]>([])
  const [currentNote, setCurrentNote] = useState('')

  const save = () => {
    onUpdate({
      ...data,
      completed: completed,
      started: started,
      canceled: canceled
    })
  }

  const saveNote = (value: string) => {
    if (!!value) {
      const newNotes = notes.slice()
      newNotes.push({text: value})
      setNotes(newNotes)
      setCurrentNote('')
    }
  }

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            <H4><Tag
              round
              minimal
            >
              {(data.type === 'SPOT') && (<span>Spot</span>)}
              {(data.type === 'RANGE') && (<span>Range</span>)}
              {(data.type === 'FLOW') && (<span>Flow</span>)}
            </Tag> {' '}
              {data.title ? data.title : ('Step #' + data.id)}</H4>
          </div>

          {
            (data.type === 'FLOW' && !!data.subFlow && !!data.subFlow.steps && data.subFlow.steps.length > 0) && (
              <div style={{paddingTop: 20, paddingBottom: 20}}>
                <p>Steps</p>
                {
                  data.subFlow.steps.map((subStep: any, subStepIndex: number) => {
                    return (
                      <div key={'sub-step-' + subStepIndex}>
                        - {subStep.title ? subStep.title : ('Step #' + subStep.id)}
                      </div>
                    )
                  })
                }
              </div>
            )
          }

          <div style={{
            paddingTop: 20,
            paddingBottom: 20
          }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              {
                (data.type !== 'FLOW') && (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    {
                      (!!data.type && data.type === 'RANGE') && (
                        <div style={{paddingRight: 6}}>
                          <Button
                            icon="play"
                            active={started}
                            onClick={
                              () => {
                                setStarted(!started)
                                setCompleted(false)
                                setCanceled(false)
                              }
                            }
                          >
                            Start
                          </Button>
                        </div>
                      )
                    }

                    {
                      (!!data.type && data.type !== 'FLOW') && (
                        <div style={{paddingRight: 6}}>
                          <Button
                            icon="tick"
                            active={completed}
                            onClick={
                              () => {
                                setCompleted(!completed)
                                setCanceled(false)
                              }
                            }
                          >
                            Complete
                          </Button>
                        </div>
                      )
                    }
                  </div>
                )
              }

              <div>
                <Button
                  icon="cross"
                  active={canceled}
                  onClick={() => {
                    setCanceled(!canceled)
                    setStarted(false)
                    setCompleted(false)
                  }}
                >
                  Cancel
                </Button>
              </div>

              <div>
                <Button
                  icon="refresh"
                  active={!canceled && !started && !completed}
                  onClick={() => {
                    setCanceled(false)
                    setStarted(false)
                    setCompleted(false)
                  }}
                >
                  Pending
                </Button>
              </div>
            </div>
          </div>


          {
            (data.type === 'RANGE' && data.started && !!data.durationDays) && (
              <div style={{paddingTop: 20, paddingBottom: 20}}>
                <Icon icon={'time'} /> Time left: {data.durationDays} days
              </div>
            )
          }


          <div>
            <p><strong><Icon icon={'comment'}/> Notes</strong></p>

            {
              (notes.length > 0) && (
                notes.map((note, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: 12,
                        position: 'relative',
                        paddingLeft: 30
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          opacity: 0.5
                        }}
                      >
                        <Icon icon={'circle'}/>
                      </div>

                      <p>
                        {note.text}<br/>
                        <span style={{fontSize: 12}} className={Classes.TEXT_MUTED}>
                          By: author | today
                        </span>
                      </p>
                    </div>
                  )
                })
              )
            }

            <div style={{marginTop: 20}}>
              <EditableText
                maxLength={255}
                maxLines={12}
                minLines={3}
                multiline={true}
                placeholder="Enter here..."
                selectAllOnFocus={false}
                confirmOnEnterKey={true}
                value={currentNote}
                onChange={(value) => setCurrentNote(value)}
                onConfirm={(value) => saveNote(value)}
              />
            </div>
          </div>

        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.PRIMARY} onClick={() => save()}>Save</Button>
            <Button onClick={onClose}>Close</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}


export default StepDialog
