import React, {FunctionComponent, useState, useEffect, useRef} from 'react'
import {
  Button,
  Checkbox,
  Classes,
  EditableText,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import {gql, useMutation} from "@apollo/client";
import moment from "moment";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";


type ComponentProps = {
  data: any | null,
  onChange?: (data: any) => void
}


const UPDATE_STEP = gql`
    mutation updateFlowStep($input: StepInput!) {
        updateFlowStep(input: $input) {
            flowStep {
                id
                title
                description
                required
                alertOnStart
                alertOnComplete
                alertBeforeDeadline
                alertOnDeadline
                alertAfterDeadline
                alertOnNote
                type
                durationDays
                completed
                active
                canceled
                started
                canceledReason
            }
        }
    }
`;


const ADD_STEP_NOTE = gql`
    mutation addStepNote($id: ID!, $content: String!) {
        addStepNote(id: $id, content: $content) {
            note {
                id
                content
                userName
            }
        }
    }
`;


const REMOVE_STEP_NOTE = gql`
    mutation removeStepNote($id: ID!) {
        removeStepNote(id: $id) {
            note {
                id
                content
                userName
            }
        }
    }
`;


const StepForm: FunctionComponent<ComponentProps> = ({
                                                       data, onChange = () => {
  }
                                                     }) => {
  const [completed, setCompleted] = useState(!!data.completed)
  const [started, setStarted] = useState(!!data.started)
  const [canceled, setCanceled] = useState(!!data.canceled)

  const [notes, setNotes] = useState<any[]>(data.notes || [])
  const [currentNote, setCurrentNote] = useState('')
  const [subStepsChecked, setSubStepsChecked] = useState<any>({})

  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);

  const [updateFlowStep, {loading: updateFlowStepLoading}] = useMutation(UPDATE_STEP, {
    update: () => {
      //setDialogIsOpen(false)
    }
  });

  const [addStepNote, {loading: addStepNoteLoading}] = useMutation(ADD_STEP_NOTE, {
    update: (cache, resp: any) => {
      if (resp.data && resp.data.addStepNote && resp.data.addStepNote.note) {
        const newNotes = notes.slice()
        newNotes.push(resp.data.addStepNote.note)
        setNotes(newNotes)
        setCurrentNote('')
      }
    }
  });

  const [removeStepNote, {loading: removeStepNoteLoading}] = useMutation(REMOVE_STEP_NOTE, {
    update: (cache, resp: any) => {
      if (resp.data && resp.data.removeStepNote && resp.data.removeStepNote.note) {
        const newNotes = notes.slice()
        setNotes(newNotes.filter((item) => (item.id !== resp.data.removeStepNote.note.id)))
      }
    }
  });

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    let reason: string | null = ''

    if (canceled) {
      reason = prompt("Please specify the reason", "");

      if (reason === null || reason === "") {
        alert('The reason must be specified')
        setCanceled(false)
        return
      } else {

      }
    }

    updateFlowStep({
      variables: {
        input: {
          id: data.id,
          patch: {
            started: started,
            completed: completed,
            canceled: canceled,
            canceledReason: reason
          }
        }
      }
    })

  }, [updateFlowStep, data.id, canceled, started, completed]);


  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      return;
    }
  }, [updateFlowStep, data.id, notes]);


  useEffect(() => {
    if (data.type === 'FLOW' && !!data.subFlow && !!data.subFlow.steps && data.subFlow.steps.length > 0) {
      const newSubStepChecked: any = {}
      data.subFlow.steps.filter((item: any) => item.completed).forEach((item: any) => {
        newSubStepChecked[item.id] = true
      })
      setSubStepsChecked(newSubStepChecked)
    }
  }, [data]);


  const saveNote = (value: string) => {
    if (!!value) {
      addStepNote({
        variables: {
          id: data.id,
          content: value
        }
      })
    }
  }

  const removeNote = (id: string) => {
    if (!!id) {
      removeStepNote({
        variables: {
          id: id,
        }
      })
    }
  }


  const StepStatusMenu: FunctionComponent = () => {
    return (
      <Menu>
        <MenuItem
          icon="refresh"
          text={'Pending'}
          active={!canceled && !started && !completed}
          disabled={data.type === 'FLOW'}
          onClick={() => {
            setCanceled(false)
            setCompleted(false)
            setStarted(false)
          }}
        />

        {
          data.type === 'RANGE' && (
            <MenuItem
              icon="play"
              text={'Start'}
              active={started}
              onClick={() => {
                setCanceled(false)
                setCompleted(false)
                setStarted(true)
              }}
            />
          )
        }

        <MenuItem
          icon="tick-circle"
          text={'Complete'}
          active={completed}
          disabled={data.type === 'FLOW'}
          onClick={() => {
            setCanceled(false)
            setCompleted(true)
            setStarted(false)
          }}
        />
        <MenuItem
          icon="chevron-forward"
          text={'Authorize Skip'}
          active={canceled}
          onClick={() => {
            setCanceled(true)
            setCompleted(false)
            setStarted(false)
          }}
        />
      </Menu>
    )
  }

  const CurrentMenuStatus: FunctionComponent = () => {
    if (canceled) return <Button icon="chevron-forward" text="Skipped"/>
    if (completed) return <Button icon="tick-circle" text="Completed"/>
    if (started) return <Button icon="play" text="Started"/>

    if (data.type === 'FLOW') {
      if (data.completed) {
        return <Button icon="tick-circle" text="Completed"/>
      } else {
        return <Button icon="refresh" text="Pending"/>
      }
    }

    return (
      <Button icon="refresh" text="Pending"/>
    )
  }

  return (
    <div>
      {
        (updateFlowStepLoading || addStepNoteLoading || removeStepNoteLoading) && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 6
          }}/>
        )
      }

      {/*{*/}
      {/*  data.type !== 'FLOW' && (*/}
      {/*    <div style={{*/}
      {/*      marginBottom: 30*/}
      {/*    }}>*/}
      {/*      Status: {' '}*/}
      {/*      <Popover content={<StepStatusMenu/>} position={Position.BOTTOM}>*/}
      {/*        <CurrentMenuStatus/>*/}
      {/*      </Popover>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*}*/}

      <div style={{
        marginBottom: 30
      }}>
        Status: {' '}
        <Popover content={<StepStatusMenu/>} position={Position.BOTTOM}>
          <CurrentMenuStatus/>
        </Popover>
      </div>

      {
        (data.canceled && data.canceledReason) && (
          <div style={{marginTop: 10, marginBottom: 20}}>Reason for skipping: {data.canceledReason}</div>
        )
      }

      {
        (data.type === 'FLOW' && !!data.subFlow && !!data.subFlow.steps && data.subFlow.steps.length > 0) && (
          <div style={{paddingTop: 0, paddingBottom: 30}}>
            {/*<div style={{marginBottom: 20}}>*/}
            {/*  <p>*/}
            {/*    <Tag minimal round>Status: {data.completed ? 'Completed' : 'Pending'}</Tag>*/}
            {/*  </p>*/}
            {/*</div>*/}

            {
              data.subFlow.steps.map((subStep: any, subStepIndex: number) => {
                return (
                  <div key={'sub-step-' + subStepIndex}>
                    <Checkbox
                      checked={!!subStepsChecked[subStep.id]}
                      label={subStep.title ? subStep.title : ('Step #' + subStep.id)}
                      onChange={(e: any) => {
                        const newSubStepsChecked: any = {...subStepsChecked}
                        newSubStepsChecked[subStep.id] = e.target.checked
                        setSubStepsChecked(newSubStepsChecked)

                        updateFlowStep({
                          variables: {
                            input: {
                              id: subStep.id,
                              patch: {
                                started: false,
                                completed: e.target.checked,
                                canceled: false
                              }
                            }
                          }
                        })
                      }}
                    />
                  </div>
                )
              })
            }
          </div>
        )
      }

      <div>
        <p><strong><Icon icon="edit"/> Notes</strong></p>
        {
          (notes.length > 0) && (
            notes.map((note, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: 20,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <Icon icon="circle"/>
                    </div>

                    <div style={{
                      flexGrow: 1,
                      paddingLeft: 12
                    }}>
                      <div>
                        {note.content}
                      </div>

                      <div style={{fontSize: 12, marginTop: 6}} className={Classes.TEXT_MUTED}>
                        {note.userName} | {moment(note.createdAt).format("MM/DD/YYYY HH:mm")}
                      </div>
                    </div>

                    <div>
                      <Button
                        onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            removeNote(note.id)
                          }
                        }}
                        minimal
                        icon="cross"
                        intent={Intent.DANGER}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          )
        }

        <div style={{marginTop: 20}}>
          <EditableText
            maxLength={255}
            maxLines={12}
            minLines={3}
            multiline={true}
            placeholder="Enter here..."
            selectAllOnFocus={false}
            confirmOnEnterKey={true}
            value={currentNote}
            onChange={(value) => setCurrentNote(value)}
            onConfirm={(value) => saveNote(value)}
          />
        </div>
      </div>
    </div>
  )
}


export default StepForm
