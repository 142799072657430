import React from 'react';
import {Text,View} from '@react-pdf/renderer'


const Disclaimer = ({ style, hasCustomId, hasWholeEmbryo, reportingPreferences }) => {
    let featureText = '';
    if (reportingPreferences.embryoGrade && reportingPreferences.embryoId && hasCustomId && reportingPreferences.biopsyDay) {
      featureText = 'Embryo IDs, Embryo Grades and Biopsy Days';
    } else {
      if (reportingPreferences.embryoId && hasCustomId) {
        featureText = 'Embryo IDs';
      }
      if (reportingPreferences.embryoGrade) {
        featureText += (featureText ? ' and ' : '') + 'Embryo Grades';
      }
      if (reportingPreferences.biopsyDay) {
        featureText += (featureText ? ' and ' : '') + 'Biopsy Days';
      }
    }

    if (featureText === '' && !hasWholeEmbryo) return null;

    return (
        <View style={style} wrap={false}>
          {hasWholeEmbryo && (
            <Text>
              This report includes results for whole/arrested embryos at the request of the referring clinician.
              The quality of DNA in this sample type is reduced resulting in lowered accuracy and an increased chance of "inconclusive" and "no amp" results.
            </Text>
          )}
          {(featureText !== '') && (
            <Text>
              *{featureText} are provided by the clinic and included here for convenience, but should not be considered
              part of the clinical genetic testing report provided by Genomic Prediction Clinical Laboratory.
            </Text>
          )}
        </View>
   )
};
export default Disclaimer;