import React, {useMemo} from 'react'
import {Document} from "@react-pdf/renderer";
import ReportPage from "components/Report/Page";
import SamplePages from "components/Report/SamplePages";
import LanguagePage from "components/Report/LanguagePage";

/**
 * <PDFDocument> component - Display PDF Report in browser
 * @param {object} data - {patient/case data, selectPanel, diseases, language}
 * @param {{all: *[], euploid: *[], aneuploid: *[], failed: *[]}} sampleData - arrays of sample data
 *        see CASES in components/Report/index.js for sample fields
 * @param onRender
 * @param {string} title - 'Report.pdf'   or    case-id + '.pdf'
 * @returns {JSX.Element|null}
 */
export default ({data, sampleData, onRender, title}) => {
  const samples = useMemo(() => {
    let items = [];
    items = items.concat(sampleData.euploid);
    items = items.concat(sampleData.aneuploid);
    return items;
  }, [sampleData])

  return data ? (
    <Document
      onRender={onRender}
      title={title}
    >
      <ReportPage data={data} sampleData={sampleData}/>

      {
        (data.testTypes.includes('P')) && samples.map((sample, i) => {
          if (!sample.aneuploid) {
            return <SamplePages data={data} sample={sample} key={'sample-page-' + i}/>
          } else {
            return null;
          }
        })
      }

      {
        (!data.testTypes.includes('M2')) && (
          <LanguagePage data={data}/>
        )
      }
    </Document>
  ) : null
}
