import React from 'react';
import {View, StyleSheet} from "@react-pdf/renderer";


const styles = StyleSheet.create({
  tableHead: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#000000'
  }
});


export default (props) => {
  return (
    <View style={styles.tableHead}>
      {props.children}
    </View>
  )
}
