import React, {useState} from 'react';
import CaseList from "components/Home/CaseList";
import Summary from "components/Home/Summary";
import './style/main.css';
import {Row, Col} from "react-flexbox-grid";
import {Button, Dialog, Classes} from "@blueprintjs/core";
import {useQuery, gql} from '@apollo/client';
import LoadingOverlay from "components/Common/LoadingOverlay";
import {Pagination} from "components/Common";
import ExportForm from "components/Home/ExportForm";

const CASES = gql`
  query Cases($first: Int!, $skip: Int!, $search: String!) {
    total,
    caseList(first: $first, skip: $skip, search: $search){
      count,
      objects {
        id,
        caseId,
        patientName,
        partnerName,
        caseStatus,
        testTypes,
        clinicName,
        sampleCount,
        salivaSampleCount,
        clinicianName
      }
    }
  }
`;


export default (props) => {
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [skip, setSkip] = useState(0);

  const [exportIsOpen, setExportIsOpen] = useState(false);
  const [exportKitIsOpen, setExportKitIsOpen] = useState(false);

  const first = 10;
  const {loading, error, data} = useQuery(CASES, {
    //fetchPolicy: 'network-only',
    variables: {
      first: first,
      skip: skip,
      search: search
    }
  });

  if (error) return <p>Error querying cases: {error.message}</p>
  return (
    <div>
      <LoadingOverlay isOpen={loading}/>
      <div
        style={{
          position: 'fixed',
          top: 60,
          left: 0,
          bottom: 0,
          width: 320,
          padding: 20
        }}
      >
        <p>Search</p>
        <form onSubmit={(e) => {
          e.preventDefault();
          setSearch(searchInput);
          setSkip(0);
        }}>
          <div className="bp3-input-group bp3-fill">
            <span className="bp3-icon bp3-icon-search"/>

            <input
              className="bp3-input"
              type="search"
              placeholder="Search input"
              dir="auto"
              value={searchInput}
              onChange={(e) => {
                if (!!searchInput && e.target.value === '') {
                  setSearch('');
                  setSkip(0);
                }
                setSearchInput(e.target.value)
              }}
            />
          </div>
        </form>

        <div
          style={{
            marginTop: 30
          }}
        >
          <p>Export</p>
            <p className="exportLink" onClick={(e) => {e.preventDefault(); setExportIsOpen(true)}}>Cases (CSV)</p>
            <p className="exportLink" onClick={(e) => {e.preventDefault(); setExportKitIsOpen(true)}}>Kits (CSV)</p>
        </div>
      </div>

      <div style={{
        paddingLeft: 320
      }}>
        <Summary/>

        <div style={{marginTop: 30}}>
          <Row>
            <Col xs>
              <Pagination
                first={first}
                skip={skip}
                totalItems={data ? data.caseList.count : 1}
                onSelect={(pageIndex) => {
                  setSkip(first * pageIndex);
                }}
              />
            </Col>
          </Row>
        </div>

        {
          (!!data) && (
            <CaseList
              items={data.caseList.objects}
            />
          )
        }
      </div>

      <Dialog
        icon="export"
        onClose={() => setExportIsOpen(false)}
        title="Export CSV"
        isOpen={exportIsOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <ExportForm type={'cases'} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setExportIsOpen(false)}>Close</Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        icon="export"
        onClose={() => setExportKitIsOpen(false)}
        title="Export CSV"
        isOpen={exportKitIsOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <ExportForm type={'kits'} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setExportKitIsOpen(false)}>Close</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
