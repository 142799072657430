import React from 'react';
import {
    Switch,
    Link,
    Route,
    Redirect,
    useParams,
    useRouteMatch,
    useLocation,
    generatePath
} from 'react-router-dom';
import KPIPage from '../KPI';
import CaseVolume from '../Stats/CaseVolume';
import Clinics from '../Stats/Clinics';
import Samples from '../Stats/Samples';
import TAT from '../Stats/TAT';
import ClinicsTable from '../../components/Clinics';
import './style.css';
// import Sidebar from '../../components/PerformanceStats/Sidebar'


const PerformanceStats = () => {
    const { path, url } = useRouteMatch();
    const { pathname } = useLocation();


    return (
        <div>
            {/* <div className='side-bar'>
               <Sidebar />
            </div> */}
            {/* <div className='page-content'> */}
            <div className="performance-subnav">
                <ul className="performance-ul">
                    <li className={pathname === `${url}/kpi` ? 'active' : ''}>
                        <Link to={generatePath(path, {topicId: 'kpi'})}>KPI</Link>
                    </li>
                    <li className={pathname === `${url}/case_volume` ? 'active' : ''}>
                        <Link to={`${url}/case_volume`}>Volume</Link>
                    </li>
                    <li className={pathname === `${url}/tat` ? 'active' : ''}>
                        <Link to={`${url}/tat`}>TAT</Link>
                    </li>
                    <li className={pathname === `${url}/clinics` ? 'active' : ''}>
                        <Link to={`${url}/clinics`}>Clinics</Link>
                    </li>
                    <li className={pathname === `${url}/samples` ? 'active' : ''}>
                        <Link to={`${url}/samples`}>Samples</Link>
                    </li>
                    <li className={pathname === `${url}/table` ? 'active' : ''}>
                        <Link to={`${url}/table`}>Table</Link>
                    </li>
                </ul>
            </div>
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={`${url}kpi`} />
                    </Route>
                    <Route exact path={`${path}/:topicId`}>
                        <Topic />
                    </Route>
                </Switch>
            {/* </div> */}
        </div>
    )
};

    
const Topic = () => {
    let { topicId } = useParams();
    console.log(topicId)
    switch(topicId) {
        case 'case_volume':
            return <CaseVolume />
        case 'kpi': 
            return <KPIPage />
        case 'tat':
            return <TAT />
        case 'clinics': 
            return <Clinics />
        case 'samples':
            return <Samples />
        case 'table': 
            return <ClinicsTable />
        default: 
            console.log(`${topicId} not found`)
    }

    return (
    <div>
    <h2>{topicId}</h2>
    </div>
    )
};

export default PerformanceStats;