import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import {
  MainLayout,
  EmptyLayout
} from 'layouts';
import AuthLogin from 'pages/Auth/Login';
import Home from 'components/Home';
import TAT from 'components/TAT';
import ReportPage from 'pages/Report';
import QReviewPage from "pages/QReview";
import Tracking from 'containers/Tracking';
import NewClinicForm from 'pages/NewClinicForm';
import Kits from 'pages/Kits';
import PerformanceStats from 'pages/PerformanceStats';
import NotFound from "pages/NotFound";
import {useApolloClient} from '@apollo/client';
import {SET_AUTH} from "services/graphql/auth";
import SampleTrackingCaseContainer from "containers/Tracking/SampleTrackingCaseContainer";


const defaultLayout = MainLayout;

const routes = [
  {
    key: 'Login',
    path: '/auth/login',
    component: AuthLogin,
    protected: false,
    layout: EmptyLayout
  },
  {
    key: 'Home',
    path: '/',
    component: Home,
    protected: true
  },
  {
    key: 'TAT',
    path: '/cases-and-tat',
    component: TAT,
    protected: true
  },
  {
    key: 'ReportSingle',
    path: '/report/:case_id',
    component: ReportPage,
    protected: true
  },
  {
    key: 'SampleTrackingCase',
    path: '/case/:case_id',
    component: SampleTrackingCaseContainer,
    protected: true
  },
  {
    key: 'ReportList',
    path: '/report',
    component: ReportPage,
    protected: true
  },
  {
    key: 'QReview',
    path: '/qreview',
    component: QReviewPage,
    protected: true
  },
  {
    key: 'Tracking',
    path: '/tracking',
    component: Tracking,
    protected: true
  },
  {
    key: 'ClinicForm',
    path: '/clinicform',
    component: NewClinicForm,
    protected: true
  }, 
  {
    key: 'Performance',
    path: '/performance',
    component: PerformanceStats,
    protected: true
  },
  {
    key: 'Kits',
    path: '/kits',
    component: Kits,
    protected: true
  }
];

const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  return !!token;
};

export default (props) => {
  const client = useApolloClient();
  const [auth, setAuth] = useState(isAuthenticated());

  client.cache.watch({
    query: SET_AUTH,
    optimistic: true,
    callback: ({complete, result}) => {
      const isAuth = result.auth ? result.auth.isAuth : false
      if (auth !== isAuth) {
        setAuth(isAuth);
      }
    }
  });


  return (
    <Router>
      <Switch>
        <Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} />
        {
          routes.map((route, i) => {
            const Component = route.component;
            const LayoutComponent = route.layout ? route.layout : defaultLayout;
            return (
              <Route
                exact={route.path === '/'}
                path={route.path}
                key={'route-' + i}
                render={(r) => {
                  if (route.protected && !isAuthenticated()) {
                    return <Redirect push to="/auth/login"/>
                  }

                  if (route.key === 'Login' && auth) {
                    return <Redirect push to="/"/>
                  }


                  return (
                    <LayoutComponent>
                      <Component />
                    </LayoutComponent>
                  )
                }}
              />
            );
          })
        }
        <Route component={NotFound}/>
      </Switch>
    </Router>
  )
}
