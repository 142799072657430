import React, { useState } from 'react';
import {useQuery, gql} from '@apollo/client';
import { Col, Row } from 'react-flexbox-grid';
import { ButtonGroup, Button } from '@blueprintjs/core';
import MetricsKPI from '../../components/Metrics/MetricsKPI';
import VolumeKPI from '../../components/Metrics/VolumeKPI';
import KPIChart from '../../components/Metrics/KPIChart';
import LoadingOverlay from "components/Common/LoadingOverlay";
import styled from 'styled-components';
import '../../components/Metrics/styles.css';



const DATA = gql`
query clinicKpi($timeFrame: [DateTime], $clinicId: ID, $nFrames: Int) {
  clinicKpi(timeFrame: $timeFrame, clinicId: $clinicId, nFrames: $nFrames) {
    current {
      timeFrame
      isCurrent
      samplesTotalCount
      samplesRebiopsyCount
      cyclesTotalCount
      failureRate
      aneuploidRate
      dqc
      callrate
      mapd
    } 
    previous {
      timeFrame
      isCurrent
      samplesTotalCount
      samplesRebiopsyCount
      cyclesTotalCount
      failureRate
      aneuploidRate
      dqc
      callrate
      mapd
    }
    avg {
      failureRate
      aneuploidRate
      dqc
      callrate
      mapd
    }
    timeline {
      timeFrame
      isCurrent
      samplesTotalCount
      samplesRebiopsyCount
      cyclesTotalCount
      failureRate
      aneuploidRate
      dqc
      callrate
      mapd
  }
  }
}
`

const KPIContainer = (props) => {

  const { clinicId, companyName, dateRange, selectAllClinics, setSelectAllClinics } = props;
  const [samplesView, setSamplesView] = useState(true);
  const [cyclesView, setCyclesView] = useState(false);
  const nFrames = 10;
    
  const Title = styled.h1`
    text-align: center;
    color: #2F80ED;
    margin-top: 0;
  `;


  //not working is clinic isn't selected bc id argument is required currently.
    const { loading, error, data } = useQuery(DATA, {
      fetchPolicy: 'no-cache',
      variables: {
         timeFrame: dateRange,
         clinicId: clinicId,
         nFrames: nFrames
      }
   });
  
console.log('container', dateRange, clinicId, companyName)
console.log('selectAll in container', selectAllClinics)
//  useEffect(()=>{
//   if(data) {
//     console.log('data in CURENERT', data)
//     const { clinicKpi } = data;
//     console.log('data', clinicKpi);
//     setKpi(clinicKpi);
//   }
//  }, [data]);
const handleCheck = () => {
  console.log('here');
  props.handleCheck();

}

 const toggleSamplesView = () => {
    setSamplesView(true);
    setCyclesView(false);
 }
 const toggleCyclesView = () => {
   setSamplesView(false);
   setCyclesView(true);
 }
 console.log('data in container', data)
  return (
      <>
      <LoadingOverlay isOpen={loading}/>
      <Col className="kpiCol" offset={4}xs={8} sm={8} md={4} lg={4}>
         <Title>Volume</Title>
         <VolumeKPI data={data} loading={loading} error={error} companyName={companyName} clinicId={clinicId} />
         <Row>
           <KPIChart data={data} error={error} loading={loading} clinicId={clinicId} dateRange={dateRange} selectAllClinics={selectAllClinics} setSelectAllClinics={setSelectAllClinics} handleCheck={handleCheck} samplesView={samplesView} cyclesView={cyclesView} companyName={companyName} />
         </Row>
         <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <ButtonGroup>
              <Button className={`samplesBtn-${samplesView ? "clicked" : "" }`} onClick={toggleSamplesView}>Samples</Button>
              <Button className={`cyclesBtn-${cyclesView ? "clicked" : ""}`} onClick={toggleCyclesView}>Cycles</Button>
            </ButtonGroup>
         </Row>
         
      </Col>
      <Col className="kpiCol" offset={4} xs={8} sm={8} md={4} lg={4}>
        <Title>KPI</Title>
        <MetricsKPI error={error} data={data} loading={loading} clinicId={clinicId}/>
      </Col>
      </>
  )
}
export default KPIContainer;