import React from 'react';
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { CLINIC_DETAIL, DELETE_CONTACT, DELETE_NOTE } from './Helpers/gql';

const ConfirmDelete = ({ toDelete, clinicId, isOpen, setIsOpen, category, noteType, noteId }) => {
    const [ deleteContact, { loading: deleteContactLoading }] = useMutation(DELETE_CONTACT, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: clinicId
                }
            }
        ], 
        onCompleted(newContactList) {
            if (newContactList) {
                setIsOpen(false)
            }
        },
        onError(e){
            console.log(e.message)
        }
    });

    const [deleteNote, { loading: deleteNoteLoading }] = useMutation(DELETE_NOTE, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: clinicId
                }
            }
        ], 
        onCompleted(deleteData){
            if (deleteData) {
                setIsOpen(false)
            }
        },
        onError(e) {
            console.log(e.message)
        }
    });


    const handleDelete = () => {
        if (category === "contact") {
            deleteContact({
                variables: {
                    clinicId: clinicId,
                    contactName: toDelete
                }
            })
        }
       else if (category === "note") {
        deleteNote({
            variables: {
                clinicId: clinicId,
                noteId: noteId,
                type: noteType
            }
        })
       }
    }
    return (
        <div>
             <Dialog 
                isOpen={isOpen}
                onClose={()=>setIsOpen(false)}
                title={`Confirm deletion of ${category}`}
            >
                <div className={Classes.DIALOG_BODY}>
                   <p>{`Are you sure you want to delete this ${category}? `}</p>
                   {(deleteContactLoading || deleteNoteLoading) && <p>Deleting...</p>}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Button onClick={handleDelete} intent={Intent.SUCCESS}>Confirm</Button>
                    <Button intent={Intent.NONE} onClick={()=>setIsOpen(false)}>Cancel</Button>
                </div>
            </Dialog>
        </div>
    )
};
export default ConfirmDelete;
