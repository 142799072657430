import React, {FunctionComponent, useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Step from "./Step/Step";

type ComponentProps = {
  data: any,
  onStepOrderUpdate: (args?: any[]) => void;
  onStepDelete: (id: number) => void;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  marginBottom: 20,
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  padding: 0,
  width: '100%'
});

const FlowStepList: FunctionComponent<ComponentProps> = ({data, onStepOrderUpdate, onStepDelete}) => {
  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    if (data && data.steps) {
      console.log(data.steps)
      setItems(data.steps)
    }
  }, [data])

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const it = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );

    setItems(it)

    onStepOrderUpdate(it)
  }

  return (
    <div>
      {
        !!items && (
          <div style={{marginTop: 40}}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {
                  (provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {
                        items.map((item, i) => {
                          return (
                            <Draggable
                              index={i}
                              key={item.id}
                              draggableId={item.id}
                              // disableInteractiveElementBlocking={true}
                            >
                              {
                                (provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    // onClick={(e) => {
                                    //   if(e.defaultPrevented) {
                                    //     return;
                                    //   }
                                    //
                                    //   e.currentTarget.focus();
                                    // }}
                                  >
                                    <Step
                                      index={i}
                                      data={item}
                                      isOpenDefault={false}
                                      onStepDelete={onStepDelete}
                                    />
                                  </div>
                                )
                              }

                            </Draggable>
                          )
                        })
                      }
                    </div>
                  )
                }

              </Droppable>
            </DragDropContext>
          </div>
        )
      }
    </div>
  )
}

export default FlowStepList;
