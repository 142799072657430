const transformChr = (value) => parseInt(value.toString().replace('X', '23').replace('Y', '24'));

const getAneuploidChromosomes = (karyotype, complexAneuploid = false, upd=false) => {

  let gain = [];
  let deficiency = [];
  let list = [];

  karyotype = karyotype.replace(' ', '');
  const [ , sexChr, ...otherChr] = karyotype.split(',');

  otherChr.forEach((chr) => {
    const chrInt = parseInt(chr);
    if (chrInt > 0) {
      gain.push(Math.abs(chrInt));
    } else if (chrInt < 0) {
      deficiency.push(Math.abs(chrInt));
    }

    list.push(Math.abs(chrInt))

    if (chr.includes('del(')) {
      const value = chr.match(/del\(([^)]+)\)/).pop();
      deficiency.push(transformChr(value));
      list.push(transformChr(value));
    }

    if (chr.includes('dup(')) {
      const value = chr.match(/dup\(([^)]+)\)/).pop();
      gain.push(transformChr(value));
      list.push(transformChr(value));
    }
  })

  // Gain of either X or Y chromosome
  if (!!sexChr && !['XX', 'XY'].includes(sexChr)) {
    const extra_x = (sexChr.match(/X/g) || []).length - 1;
    const extra_y = (sexChr.match(/Y/g) || []).length;

    if (extra_x > 0) {
      gain.push(23);
      list.push(23);
    }

    if (extra_y > 0) {
      gain.push(24);
      list.push(24);
    }
  }

  // Deficiency of X chromosome
  if (sexChr === 'X') {
    deficiency.push(23);
    list.push(23);
  }

  if (complexAneuploid || upd) {
    gain = [];
    deficiency = [];
    list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  }

  return {list, gain, deficiency};
}

export default getAneuploidChromosomes;
