import React from 'react';
import {Image, Text, View} from "@react-pdf/renderer";
import signaturePNG from "assets/images/nathan.png";
import moment from "moment";
import ConvertHTMLToPDF from "./ReportLanguage/ConvertHTMLToPDF";

export default ({data, ...props}) => {
  return (
    <View style={{
      //fontFamily: 'Poppins',
      fontSize: 7,
    }}>

      <ConvertHTMLToPDF html={data.language}/>

      <View
        style={{marginTop: 0, marginBottom: 0}}
        wrap={false}
      >
        {
          (data.testTypes.includes('M2') && data.testPerformedBy) && (
            <View style={{marginTop: 12}}>
              <Text>Test Performed by: {data.testPerformedBy}</Text>
            </View>
          )
        }
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <View>
            {
              data.testTypes.includes('M2') && (
                <Text>Test Reviewed by:</Text>
              )
            }
            <Text>Nathan R. Treff, PhD, HCLD (ABB)</Text>
            <Text>Clinical Laboratory Director</Text>
            <Text>Genomic Prediction Clinical Laboratory</Text>
          </View>
          <View>
            <Image
              source={signaturePNG}
              style={{
                width: 1869 / 12,
                height: 845 / 12,
              }}
              fixed
            />
          </View>
          <View>
            <Text>{moment(data.reportSigned).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>

      <View style={{marginTop: 0, fontSize: 7}}>
        <Text>CAP: 8488628, CLIA: 31D2152380, New Jersey DOH: 00056206</Text>
      </View>
    </View>
  )
}
