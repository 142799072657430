import React from 'react';
import TATSummary from "./TATSummary";
import {H2} from "@blueprintjs/core";

export default (props) => {

  return (
    <div>

      <div style={{padding: '30px 0'}}>
        <H2>Cases & TAT</H2>
      </div>

      <TATSummary/>
    </div>
  )
}
