import React, { createContext, useReducer } from 'react';

export const FormContext = createContext();

const initialState = {
    dirtyFields: {},
    isEditing: false,
    account: [], 
    contacts: [],
    addresses: [],
    billing: [],
    billingNotes: [],
    kits: [],
    kitNotes: [],
    workflows: [],
    salesData: [],
    attachments: [],
  
}

const reducer = (state, action) => {
    console.log('action', action)
    switch(action.type) {
        case "MARK_DIRTY_FIELD":
            if (state.dirtyFields[action.payload]) return {...state}
            else {
                return {
                    ...state, dirtyFields: {...state.dirtyFields, [action.payload] : true}
                 };
            };
        case "RESET_DIRTY_FIELDS": 
        return {
            ...state, dirtyFields: {}
        }
        case "SET_EDITING": 
            return {
                ...state, isEditing: !state.isEditing
            };
        case "ADD_CONTACT": 
            return {
                ...state, contacts: [...state.contacts, action.payload]
            };
       case "EDIT_CONTACT":
           return {
               ...state, contacts: [...state.contacts.filter(contact => contact.name !== action.payload.name), action.payload]
           };
        case "ADD_ACCOUNT": 
            return {
                ...state, account: [...state.account, action.payload]
            };
        case "ADD_ADDRESS":
            return {
                ...state, addresses: [...state.addresses, action.payload]
            };
        case "ADD_BILLING":
            return {
                ...state, billing: [...state.billing, action.payload]
            };
        case "ADD_KIT": 
            return {
                ...state, kits: [...state.kits, action.payload]
            }
        case "ADD_KIT_NOTE":
            return {
                ...state, kitNotes: [...state.kitNotes, action.payload]
            }
        case "ADD_WORKFLOW": 
            return {
                ...state, workflows: [...state.workflows, action.payload]
            }
        case "ADD_SALES_DATA": 
            return {
                ...state, salesData: [...state.salesData, action.payload]
            };
        case "ADD_ATTACHMENT": 
            return {
                ...state, attachments: [...state.attachments, action.payload]
            };
        case "ADD_BILLING_NOTE": 
            return {
                ...state, billingNotes: [...state.billingNotes, action.payload]
            };
       default: 
        throw new Error();
    }
};


export const FormContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <FormContext.Provider value={[state, dispatch]}>
            {props.children}
        </FormContext.Provider>
    );
};
