import React, {FunctionComponent, useEffect, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {GET_FLOWS} from "containers/Tracking/index";
import { HTMLSelect } from '@blueprintjs/core';
import usePrevious from "services/hooks/usePrevious";


type ComponentProps = {
  parentFlowID: number,
  initialValue: number | null,
  onSelect: (id: number | null) => void
}


const SubFlowForm: FunctionComponent<ComponentProps> = ({parentFlowID, initialValue, onSelect}) => {
  const client = useApolloClient();

  const [selectedFlowID, setSelectedFlowID] = useState<number | null>(initialValue)

  // @ts-ignore
  const prevSelectedFlowID = usePrevious(selectedFlowID, initialValue)

  const { flows } = client.readQuery({
    query: GET_FLOWS
  });

  useEffect(() => {
    if(selectedFlowID !== prevSelectedFlowID) {
      onSelect(selectedFlowID)
    }
  }, [selectedFlowID, prevSelectedFlowID, onSelect])

  return (
    <div>
      {
        (!!flows && flows.length === 0) && (
          <p>No flows to select. Create a flow with steps first.</p>
        )
      }

      {
        (!!flows && flows.length > 0) && (
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <div style={{paddingRight: 20}}>
                Select Flow
              </div>
              <div style={{flexGrow: 2}}>
                <HTMLSelect
                  onChange={(event) => {
                    const val = event.currentTarget.value
                    setSelectedFlowID(val === '' ? null : parseInt(val))
                  }}
                >
                  <option value={''}>
                    Not Selected
                  </option>
                  {
                    flows.filter((item: any) => (item.id !== parentFlowID)).map((flow: any, i: number) => {
                      return (
                        <option
                          key={'sub-flow-' + flow.id}
                          value={flow.id}
                          selected={flow.id === selectedFlowID}
                        >
                          {flow.title ? flow.title : ('Flow #' + flow.id)}
                        </option>
                      )
                    })
                  }
                </HTMLSelect>
              </div>
              <div style={{flexGrow: 4}}>

              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default SubFlowForm
