import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Dialog, Intent, Classes, Button, FormGroup, InputGroup } from '@blueprintjs/core';
import * as axios from 'axios';

const AUTH_DATA = gql`
    query accountToken {
        accountToken {
            value,
            expires
        }
    }
`;

const SendCSV = ({ isOpen, setIsOpen }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { data: tokenData } = useQuery(AUTH_DATA);


    const submitForm = () => {
        const endpoint = `clinic-form-csv/?send_to=${email}`
        const getToken = () => {
            if (tokenData && tokenData.accountToken && tokenData.accountToken.value) {
                return tokenData.accountToken.value
              }
              return null
        }
        if (getToken()) {
            axios({
                method: 'GET',
                responseType: 'json',
                baseURL: process.env.REACT_APP_API_HOST,
                url: endpoint,
                headers: {
                  "Authorization": `Token ${getToken()}`
                }
              })
                .then((resp) => {
                  // all good. do nothing
                  setSuccess(true)
                  setError(false)
                  console.log(resp)
                })
                .catch((e) => {
                  setError(true)
                  setSuccess(false)
                })
                .finally(() => {
                  setLoading(false);
                });
        }
    }
    return (
        <Dialog 
            isOpen={isOpen}
            onClose={()=>setIsOpen(false)}
            title="Export to CSV"
        >
        {
        loading ? (
          <p>Please wait...</p>
        ) : (
          <div>
            {
              success ? (
                <div>
                    Request submitted. Wait for the email at {email}.
                    <Button intent={Intent.PRIMARY} onClick={()=>{
                        setIsOpen(false);
                    }}
                    >OK</Button>
                </div>
              ) : (
                error ? (
                  <div>
                    Error {error.message}
                  </div>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!!email) {
                        submitForm(email)
                      }
                    }}
                  >
                     <div className={Classes.DIALOG_BODY}>
                        <FormGroup labelFor="email" label="Please enter your email address: *">
                         <InputGroup id="email" value={email} onChange={(e)=>setEmail(e.target.value)} required={true} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Button intent={Intent.PRIMARY} type="submit" onClick={()=>{}}>Send</Button>
                        <Button intent={Intent.NONE} onClick={()=>setIsOpen(false)}>Cancel</Button>
                    </div>
                  </form>
                )
              )
            }
          </div>
        )
      }
        </Dialog>
        
    )
};
export default SendCSV;