import React, {FunctionComponent} from 'react';
import StepDialog from "./StepDialog";
import {Classes, Icon, Intent, Tag} from "@blueprintjs/core";


type ComponentProps = {
  data: any,
  showArrow: boolean,
  showTitle?: boolean,
  onUpdate: (args: any) => void,
  openID: number | null,
  onClose: (id: number) => void,
  onOpen: (id: number) => void
}



const Step: FunctionComponent<ComponentProps> = ({data = {}, openID = null, onClose, onOpen, showArrow, onUpdate, showTitle= false}) => {
  //const [isOpen, setIsOpen] = useState(false)

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
      <StepDialog
        isOpen={!!openID && openID === data.id}
        onClose={() => onClose(data.id)}
        data={data}
        onUpdate={onUpdate}
      />

      <div>
        <Tag
          minimal
          round
          interactive
          intent={data.canceled ? Intent.NONE : (data.completed ? Intent.SUCCESS : (data.started ? Intent.WARNING : Intent.PRIMARY))}
          fill={data.type === 'FLOW' && data.subFlow && data.subFlow.steps && data.subFlow.steps.length}
          onClick={() => onOpen(data.id)}
          //icon={data.type ? TYPE_ICONS[data.type] : null}
        >
          {data.completed && <Icon icon={'small-tick'}/>}
          {(data.started && !data.completed) && <Icon icon={'stopwatch'}/>}
          {(!data.started && !data.completed) && <Icon icon={showTitle ? 'dot' : 'blank'}/>}
          {
            showTitle && <span className={data.canceled ? Classes.TEXT_MUTED : ''}>{data.title ? data.title : ('Step #' + data.id)}</span>
          }
          {/*<span className={data.canceled ? Classes.TEXT_MUTED : ''}>{data.title ? data.title : ('Step #' + data.id)}</span>*/}
        </Tag>

        {
          (data.type === 'FLOW' && data.subFlow && data.subFlow.steps && data.subFlow.steps.length) && (
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {
                data.subFlow.steps.map((subFlowStep: any) => {
                  return (
                    <p>
                      <div>
                        {/*<Tag*/}
                        {/*  minimal*/}
                        {/*  round*/}
                        {/*  interactive*/}
                        {/*  intent={Intent.NONE}*/}

                        {/*>*/}
                        {/*  {subFlowStep.title ? subFlowStep.title : ('Step #' + subFlowStep.id)}*/}
                        {/*</Tag>*/}
                        <Step
                          showTitle={showTitle}
                          data={subFlowStep}
                          showArrow={false}
                          onUpdate={onUpdate}
                          openID={openID}
                          onClose={() => onClose(subFlowStep.id)}
                          onOpen={() => onOpen(subFlowStep.id)}
                        />
                      </div>
                    </p>
                  )
                })
              }
            </div>
          )
        }
      </div>

      {
        (showArrow) && (
          <div style={{paddingLeft: 3, paddingRight: 3, opacity: 0.3}}>
            <Icon icon={'arrow-right'} intent={Intent.PRIMARY} />
          </div>
        )
      }
    </div>
  )
}

export default Step
