import React, { useState } from 'react';
import { Dialog, Classes , Button, Intent, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import { useMutation } from '@apollo/client';
import { UPDATE_CLINIC } from './Helpers/gql';
import { PROVIDERS } from './Helpers/gql';
import LoadingOverlay from 'components/Common/LoadingOverlay';

const ValidateStatusChange = ({ isOpen, setIsOpen, newStatus, clinicId }) => {
    const [username, setUsername] = useState("");
    const [reason, setReason] = useState("");
    const [updateClinic, { error, loading }] = useMutation(UPDATE_CLINIC, {
        onCompleted(updateClinic) {
            if (updateClinic) {
                setIsOpen(false)
            }
        }, 
        refetchQueries: [
            {
                query: PROVIDERS
            }
        ]
    });


    const handleSubmit = () => {
        updateClinic({
            variables: {
                id: clinicId,
                input: {
                    accountStatus: {
                        username: username,
                        reason: reason,
                        currentStatus: newStatus
                    }
                }
            }
        })
    }
    if (error) return <div>{`Error: ${error.message}`}</div>
    return (
        <div>
            <LoadingOverlay show={loading} />
            <Dialog 
                isOpen={isOpen}
                onClose={()=>setIsOpen(false)}
                title="Confirm Status Change"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup labelFor="username" label="Please enter your name: *">
                        <InputGroup id="username" value={username} onChange={(e)=>setUsername(e.target.value)} required={true} />
                    </FormGroup>
                    <FormGroup
                        labelFor="reason"
                        label="Enter reason for status change: *"
                    >
                        <TextArea 
                            id="reason"
                            required={true}
                            value={reason}
                            onChange={(e)=>setReason(e.target.value)}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Button intent={Intent.SUCCESS} disabled={!username.length || !reason.length} onClick={handleSubmit}>Confirm</Button>
                    <Button intent={Intent.NONE} onClick={()=>setIsOpen(false)}>Cancel</Button>
                </div>
            </Dialog>
        </div>
    )
};

export default ValidateStatusChange;