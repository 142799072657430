import React, {FunctionComponent} from 'react'
import FlowStep from "./FlowStep";


type ComponentProps = {
  flowData: any | null
}

const SampleTrackingCase: FunctionComponent<ComponentProps> = ({flowData}) => {
  if (flowData === null) return <p>Flow data does not exist.</p>

  return (
    <div>
      {
        flowData.steps && flowData.steps.map((stepData: any, stepIndex: number) => {
          return (
            <FlowStep
              stepData={stepData}
              key={stepIndex}
            />
          )
        })
      }
    </div>
  )
}

export default SampleTrackingCase
