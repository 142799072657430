import React from 'react';

const Kits = () => {
    return (
        <div>
            container
        </div>
    )
};

export default Kits;