import React from 'react';
import {Card, H5} from "@blueprintjs/core";
import Container from './Container';
import LoginForm from 'components/LoginForm';


export default (props) => {
  return (
    <Container>
      <div style={{width: 260}}>
        <Card>
          <H5>
            <p>Sign In</p>
          </H5>

          <LoginForm/>
        </Card>
      </div>
    </Container>
  )
}
