import React from 'react';
import { FormGroup, HTMLSelect, InputGroup, Card } from '@blueprintjs/core';
import { ShippingNotes } from './Notes';



const AddAddress = ({ data, id, countries, options, isEditable }) => {

    return data.clinicDetail.shipping.map((obj, i) => (
        <div key={`address-${i+1}`}>
            <Card>
                <FormGroup
                        labelFor="Shipping"
                        label="Shipping Preference:"
                        >
                        <InputGroup  key={`preference-${i+1}`} id="preference" name="preference" type="text" value={data.clinicDetail.shipping[0].preference || ""} readOnly={true} />
                </FormGroup>
                <FormGroup
                    labelFor="Priority"
                    label="Indicate Urgency:"
                >
                    <InputGroup id="Priority" type="text" value={data.clinicDetail.shipping[0].urgency || ""} readOnly={true} />
                </FormGroup>
            <FormGroup
                    label="Address:"
                    labelFor="Clinic Address 1"
                    labelInfo="(Line 1)"
                >
                    <InputGroup id="Clinic Address 1" value={obj.address.address1 || ""} readOnly={true} />
            </FormGroup>
            <FormGroup
                    labelFor="Clinic Address 2"
                    labelInfo="(Line 2)"
            >
                    <InputGroup id="Clinic Address 2" value={obj.address.address2 || ""} readOnly={true} />
            </FormGroup>
            <FormGroup
                    label="City:"
                    labelFor="Clinic City"
            >
                    <InputGroup id="Clinic City" value={obj.address.city || ""} readOnly={true}/>
            </FormGroup>
            <FormGroup
                labelFor="Clinic State"
                label="State or Province"
            >
                    <HTMLSelect
                    id="Clinic State"
                    className="stateSelect"
                    value={obj.address.stateUs || ""}
                    readOnly={true}
                >
                    {options}
                </HTMLSelect>
            </FormGroup>
            {
                obj.address.provinceOther  &&
                <FormGroup
                    label="Enter Province:"
                    labelFor="province"
                >
                    <InputGroup id="province" value={obj.address.provinceOther || ""} readOnly={true} />
                </FormGroup>
            }
            <FormGroup
                labelFor="country"
                label="Country"
            >
                <HTMLSelect
                    name="country"
                    value={obj.address.country || ""}
                    readOnly={true}
                >
                    {countries}
                </HTMLSelect>
            </FormGroup>
            <FormGroup
                label="ZIP/Postal Code:"
                labelFor="postal"
            >
                <InputGroup
                    id="postal"
                    value={obj.address.postal || ""}
                    readOnly={true}
                        />
            </FormGroup>
            <p>Notes:</p>
                <ShippingNotes data={data.clinicDetail.shipping[i].notes} id={id} shippingId={i} isEditable={isEditable}/>
        </Card>
        </div>
    ))


    // const handleChange = e => {
    //     props.onChange(e);
    // };

    // const handleChangeSelectState = e => {
    //     props.onChangeSelectState(e)
    // };

    // const handleChangeSelectArea = e => {
    //     props.onChangeSelectArea(e)
    // };

// return (
//     <Card>
//          <FormGroup
//                 label="Address:"
//                 labelFor="Clinic Address 1"
//                 labelInfo="(Line 1)"
//             >
//                 <InputGroup id="Clinic Address 1" placeholder="" onChange={e=>handleChange(e)} />
//         </FormGroup>
//         <FormGroup
//                 labelFor="Clinic Address 2"
//                 labelInfo="(Line 2)"
//         >
//                 <InputGroup id="Clinic Address 2" placeholder="" onChange={e=>handleChange(e)} />
//         </FormGroup>
//         <FormGroup
//                 label="City:"
//                 labelFor="Clinic City"
//         >
//                 <InputGroup id="Clinic City" placeholder="" onChange={e=>handleChange(e)}/>
//         </FormGroup>
//         <FormGroup
//             labelFor="Clinic State"
//             label="State or Province"
//         >
//                 <HTMLSelect
//                 id="Clinic State"
//                 className="stateSelect"
//                 value={props.state}
//                 onChange={e=>handleChangeSelectState(e)}
//             >
//                 {props.options}
//             </HTMLSelect>
//         </FormGroup>
//         {
//             props.state === 'Other' &&
//             <FormGroup
//                 label="Enter Province:"
//                 labelFor="province"
//             >
//                 <InputGroup id="province" placeholder="" onChange={e=>handleChange(e)}/>
//             </FormGroup>
//         }
//         <FormGroup
//             labelFor="Clinic Region"
//             label="Region"
//         >
//             <HTMLSelect
//                 onChange={e=>handleChangeSelectArea(e)}
//                 value={props.area}
//             >
//                 {props.areas}
//             </HTMLSelect>
//         </FormGroup>
//         <FormGroup
//             label="ZIP/Postal Code:"
//             labelFor="Clinic ZIP"
//         >
//             <InputGroup
//                 id="Clinic ZIP"
//                 placeholder=""
//                 onChange={e=>handleChange(e)}
//                     />
//         </FormGroup>
//     </Card>
// )
};
export default AddAddress;
/*
 <FormGroup
                    label="Address:"
                    labelFor="Clinic Address 1"
                    labelInfo="(Line 1)"
                >
                    <InputGroup id="Clinic Address 1" placeholder="" onChange={e=>handleChange(e)} />
                </FormGroup>
                <FormGroup
                    labelFor="Clinic Address 2"
                    labelInfo="(Line 2)"
                >
                    <InputGroup id="Clinic Address 2" placeholder="" onChange={e=>handleChange(e)} />
                </FormGroup>
                <FormGroup
                    label="City:"
                    labelFor="Clinic City"
                >
                    <InputGroup id="Clinic City" placeholder="" onChange={e=>handleChange(e)}/>
                </FormGroup>
                <RadioGroup
                    label="Is clinic located in the US?"
                    selectedValue={radio}
                    onChange={e=>setRadio(e.target.value)}
                    inline={true}
                >
                    <Radio label="Yes" value="Yes" />
                    <Radio label="No" value="No" />

                </RadioGroup>
                {
                radio === "Yes" &&
                <FormGroup
                    labelFor="Clinic State"
                    label="State"
                >
                     <HTMLSelect
                        className="stateSelect"
                        value="Choose a state"
                        elementRef={stateRef}
                        onChange={e=>handleChangeSelect(e)}
                    >
                        {options}
                    </HTMLSelect>
                </FormGroup>
                }
                <FormGroup
                    labelFor="Clinic Region"
                    label="Region"
                >
                    <HTMLSelect
                        value="Choose a region"
                        ref={regionRef}
                        onChange={e=>handleChangeSelect(e)}
                    >
                        {options}
                    </HTMLSelect>
                </FormGroup>
                <FormGroup
                    label="ZIP Code:"
                    labelFor="Clinic ZIP"
                >
                    <InputGroup
                        id="Clinic ZIP"
                        placeholder=""
                        onChange={e=>handleChange(e)}
                         />
*/
