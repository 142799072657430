import React from "react";
import {Image, View, Text} from "@react-pdf/renderer";
import logoPNG from "assets/images/gpcl_v2.png";
import moment from "moment";
import getAddressLines from "components/Report/services/getAddressLines";


const PersonName = ({value, partnerValue}) => {
  if (value) {
    const nameParts = value.split(' ')

    const longest = nameParts.reduce(
      function (a, b) {
        return a.length > b.length ? a : b;
      }
    )

    let fontSize = 18

    if (longest.length >= 12) {
      fontSize = 13
    }

    return (
      <View style={{fontSize: fontSize}}>
        {nameParts.map((item) => {
          return (
            <View key={item}><Text>{item}</Text></View>
          )
        })}

        {
          (!!partnerValue) && (
            <View>
              <Text style={{fontSize: 12}}>&</Text>
              <Text>{partnerValue}</Text>
            </View>
          )
        }
      </View>
    )
  }

  return <View/>
}


export default ({data, ...props}) => {
  const {
    patientName,
    partnerName,
    patient,
    partner,
    clinicLogo,
    blinded,
    testTypes,
    show_db,
    hidePartner,
    clientReference
  } = data ? data : {};

  let add = {};
  if (patient && patient.address) {
    add = getAddressLines(patient.address);
  }

  return (
    <View
      style={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 180,
        padding: 30,
        paddingTop: 40
      }}
      fixed={true}
    >
      <View
        style={{
          width: 4,
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 180,
          bottom: 0,
          borderWidth: 2,
          borderColor: '#f8f8f8'
        }}
        fixed={false}
      />

      <Image
        source={logoPNG}
        style={{
          width: 240 / 2.2,
          height: 79 / 2.2,
        }}
        fixed={true}
      />

      {
        clinicLogo && (
          <Image
            source={clinicLogo}
             style={{
              position: 'absolute',
              width: 120,
              top: 110,
              left: 30
              //height: 79 / 2.2,
            }}
            fixed={true}
          />
        )
      }

      {
        (!!data && data.blinded) ? (
          <View style={{fontSize: 18, fontStyle: 'normal', fontWeight: 'semibold'}}>
            <Text style={{fontSize: 12}}>Report for:</Text>
            {
              (testTypes.includes('M2') && !!clientReference) ? (
                <Text style={{fontSize: 8}}>{clientReference}</Text>
              ) : (
                <Text>-</Text>
              )
            }
          </View>
        ) : (
          <View style={{fontSize: 18, fontStyle: 'normal', fontWeight: 'semibold'}}>
            <Text style={{fontSize: 12}}>Report for:</Text>
            <View>
              <PersonName value={patientName} partnerValue={(!hidePartner && testTypes && !testTypes.includes('M2')) ? partnerName : null} />
            </View>
          </View>
        )
      }

      <View
        style={{
          width: 140,
        }}
        fixed={false}
      >
        {
          (testTypes && testTypes.includes('M2') && blinded) ? (
            <View>
              {
                (!!data && data.show_db && !!patient.dob) &&
                <View>
                  <Text>Patient</Text>
                  <Text>Date of birth: {moment(patient.dob).format("MM/DD/YYYY")}</Text>
                </View>
              }
            </View>
          ) : (
            <View>
              {
                !!patient && (
                  <View>
                    <Text>Patient</Text>
                    {
                      !blinded ? (
                        <View>
                          <Text
                            style={{
                              fontSize: 10,
                              fontStyle: 'normal',
                              fontWeight: 'semibold',
                              marginTop: 5,
                              marginBottom: 5
                            }}>{patientName}</Text>
                          {!!patient.dob && (<Text>Date of birth: {moment(patient.dob).format("MM/DD/YYYY")}</Text>)}
                          <Text>Email: {patient.email}</Text>
                          <Text>Phone: {patient.phone}</Text>
                          <Text>Address: {add.line1}</Text>
                          <Text>{add.line2}</Text>
                          <Text>{add.line3}</Text>
                        </View>
                      ) : (
                        <View>
                          {
                            !!show_db ? (
                              <View>{!!patient.dob && (<Text>Date of birth: {moment(patient.dob).format("MM/DD/YYYY")}</Text>)}</View>
                            ) : (
                              <Text>-</Text>
                            )
                          }
                        </View>
                      )
                    }
                  </View>
                )
              }

              {
                (!hidePartner && !blinded && !!partner && testTypes && !testTypes.includes('M2')) && (
                  <View
                    style={{
                      marginTop: 20
                    }}
                  >
                    <Text>Partner</Text>
                    <Text
                      style={{
                        fontSize: 10,
                        fontStyle: 'normal',
                        fontWeight: 'semibold',
                        marginTop: 5,
                        marginBottom: 5
                      }}>{partnerName}</Text>
                    {!!partner.dob && (<Text>Date of birth: {moment(partner.dob).format("MM/DD/YYYY")}</Text>)}
                    <Text>Email: {partner.email}</Text>
                    <Text>Phone: {partner.phone}</Text>
                  </View>
                )
              }
            </View>
          )
        }

      </View>


    </View>
  )
}
