import React from 'react';
import { FileInput } from '@blueprintjs/core';
import FilesList from './FilesList';
import { CLINIC_DETAIL, UPLOAD_ATTACHMENT } from './Helpers/gql';
import { useMutation } from '@apollo/client';

const Attachments = ({ id, data }) => {
    const [uploadAttachment, { loading, error }] = useMutation(UPLOAD_ATTACHMENT, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL,
                variables: {
                    id
                }
            }
        ]
    })
    //function cleans up unsafe characters before uploading to s3 
    //so that user can easily download file w/ proper extension.
    const handleChange = ({ target: {validity, files: [file]}}) => {
      if (validity.valid) {
        const name = file.name;
        let sanitized = '';
        for (let char in name) {

            if (name[char] === "." && ((Number(char) !== (name.length-4))) && (Number(char) !== (name.length-3))) {
                sanitized += '-'
            }
            else if (name[char] === " ") {
                sanitized+= '_'
            }
            else sanitized +=  name[char]
        }
        Object.defineProperty(file, 'name', {
            writable: true,
            value: sanitized
          });
          uploadAttachment({
              variables: {
                  clinicId: id,
                  file
              }
          })
      }
    };

    if (error) return <p>{`Error: ${error.message}`}</p>
    if (loading) return <p>Loading...</p>
    return (
        <div className="form-container">
            <h1 className="form-header">Attachments:</h1>
            <div className="file-input">
                <FileInput onInputChange={handleChange} required text="Choose file..." />
            </div>
            {data.clinicDetail.attachments ?
                <FilesList data={data.clinicDetail.attachments} />
            : null}
        </div>
    )
};

export default Attachments;