import React, {useState} from 'react';
import {Card, Elevation, H5, Classes, HTMLTable, Icon, Intent, Tag, Colors} from "@blueprintjs/core";
import {Row, Col} from "react-flexbox-grid";


export default (props) => {
  const {data} = props;
  const cases_due = data ? data.caseTatList : []

  const [hoveredRow, setHoveredRow] = useState(null);

  return (
    <div>
      <div style={{marginBottom: 20}}>
        <Row between="xs">
          <Col xs>
            <H5>
              Cases Due ({cases_due.length})
            </H5>
          </Col>

          <Col xs={1}>

          </Col>
        </Row>
      </div>

      <Card interactive={true} elevation={Elevation.TWO}>
        <div className="card-body">
          <div className="card-table">
            <HTMLTable striped interactive>
              <thead>
              <tr>
                <th/>
                <th>Case</th>
                <th></th>
                <th>Provider</th>
                <th>Samples Received</th>
                <th><Icon icon="dollar"/></th>
                <th>Due in</th>
              </tr>
              </thead>
              <tbody>
              {
                cases_due.map((item, index) => {
                  const samples_received = new Date(item.samplesReceivedAt);

                  return (
                    <tr
                      key={'case-row-' + item.id}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   window.open(`https://clinic.genomicprediction.com/patients/${item.id}?provider=${item.providerId}`, '_blank');
                      // }}
                      onMouseOver={() => {setHoveredRow(index)}}
                      onMouseLeave={() => {setHoveredRow(null)}}
                    >
                      <td style={{width: 40}}>
                        {
                          item.overDue ? <Icon icon="issue" intent={Intent.DANGER}/> : ''
                        }
                      </td>
                      <td>
                        <strong>{item.patientName}{item.partnerName ? ', ' + item.partnerName : ''}</strong><br/>
                        <span className={Classes.TEXT_SMALL}>Label: {item.caseId || '-'}</span><br/>
                        <span style={{visibility: hoveredRow === index ? 'visible' : 'hidden'}}>
                          <a href={`https://clinic.genomicprediction.com/patients/${item.id}?provider=${item.providerId}`} target="_blank" rel="noopener noreferrer">
                          Open in clinic portal
                        </a>
                        </span>
                      </td>
                      <td>{item.testTypes.join(', ')}</td>
                      <td style={{width: 200}}>
                        <span className={Classes.TEXT_SMALL}>{item.clinicName}</span>
                      </td>
                      <td>{samples_received.toDateString()}</td>
                      <td>
                        {item.paymentStatus === 'no_bill' && <span>-</span>}
                        {item.paymentStatus === 'awaiting' && <span><Icon icon="time" color={Colors.GOLD5}/></span>}
                        {item.paymentStatus === 'received' && <span><Icon icon="tick" color={Colors.FOREST4}/></span>}
                      </td>

                      <td>
                        <Tag
                          intent={item.overDue ? Intent.DANGER : Intent.PRIMARY}
                          minimal
                          round
                        >
                          {item.dueIn}
                        </Tag>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </HTMLTable>
          </div>
        </div>
      </Card>
    </div>
  )
}
