import getCountryNameFromCode from "components/Report/services/getCountryNameFromCode";

export default (value) => {
  let line1 = '';
  let line2 = '';
  let line3 = '';

  if (!!value) {
    try {
      const add = JSON.parse(value);
      line1 = (add.street || '') + (!!add.unit ? ', ' + add.unit : '')
      line2 = (add.city || '') + (!!add.state ? ', ' + add.state : '')
      line3 = (add.country ? getCountryNameFromCode(add.country) : '') + (add.postal ? ((!!add.country ? ', ' : '') + add.postal) : '')
    } catch (e) {
      console.log(e)
    }
  }

  return ({line1, line2, line3});
}
