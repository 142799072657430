import React, {useState, useEffect} from 'react';
import AccountDetails from '../../components/NewClinicForm/AccountDetails';
import {Icon} from '@blueprintjs/core';
import {ClinicContact} from '../../components/NewClinicForm/ClinicContact';
import Shipping from '../../components/NewClinicForm/Shipping';
import Workflows from '../../components/NewClinicForm/Workflows';
import Kits from '../../components/NewClinicForm/Kits';
import Billing from '../../components/NewClinicForm/Billing';
import Sales from '../../components/NewClinicForm/Sales';
import Attachments from '../../components/NewClinicForm/Attachments.jsx';
import  '../../components/NewClinicForm/styles.css';
import {useRouteMatch, useParams, Route, Switch, Link, NavLink, generatePath} from "react-router-dom";
import ClinicList from 'components/NewClinicForm/ClinicList';
import ClinicDetail from "components/NewClinicForm/ClinicDetail";
import styled from 'styled-components';


export const NewClinicFormContainer = () => {
  let {url} = useRouteMatch();

  return (
    <div>
      {/*Important to have Switch around the routes*/}
      <Switch>
        <Route exact path={`${url}/:id/:subPage/`}>
          <ClinicDetail/>
        </Route>
        <Route exact path={`${url}/:id/`}>
          <ClinicDetail/>
        </Route>
        <Route path={`${url}`}>
          <ClinicList/>
        </Route>
      </Switch>
    </div>
  )
};



export const ParentForm = ({data}) => {
  let {path} = useRouteMatch();
  const {id} = useParams();
  const [clinicName, setClinicName] = useState('');

  useEffect(() => {
    if (data) {
      setClinicName(data.clinicDetail.name)
    }
  }, [data])

  const NameHeader = styled.h1`
  text-align: center;
  color: #F19A60
  `;




  return (
    <div>
      <>
       
        {/*<div className="clinicSubNav">*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/account/`)}>Account*/}
        {/*    Details</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/contact/`)}>Contact</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/shipping/`)}>Shipping</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/billing`)}>Billing</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/kits`)}>Kits</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/workflows`)}>Workflows</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem" to={join(url, `/${id}/sales`)}>Sales</NavLink>*/}
        {/*  <NavLink activeClassName="active" className="navItem"*/}
        {/*           to={join(url, `/${id}/attachments`)}>Attachments</NavLink>*/}
        {/*</div>*/}
        <NavigateHome />
        <div className="clinicSubNav">
          
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'account'})}>Account Details</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'contact'})}>Contact</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'shipping'})}>Shipping</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'billing'})}>Billing</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'kits'})}>Kits</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'workflows'})}>Workflows</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'sales'})}>Sales</NavLink>
          <NavLink activeClassName="active" className="navItem" to={generatePath(path, {id: id, subPage: 'attachments'})}>Attachments</NavLink>
        </div>
        <NameHeader>{clinicName}</NameHeader>
      </>

      <Switch>
        <Route exact path={generatePath(path, {id: id, subPage: 'account'})}>
          <AccountDetails data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'contact'})}>
          <ClinicContact data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'shipping'})}>
          <Shipping data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'kits'})}>
          <Kits data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'workflows'})}>
          <Workflows data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'billing'})}>
          <Billing data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'sales'})}>
          <Sales data={data} id={id}/>
        </Route>

        <Route exact path={generatePath(path, {id: id, subPage: 'attachments'})}>
          <Attachments data={data} id={id}/>
        </Route>
      </Switch>
    </div>
  )
}

export const NavigateHome = () => 

  (
    <>
      <Link to={'/clinicform/'} className="navigateHome">
        <Icon icon="arrow-left" iconSize={20} />
        All clinics
      </Link>
    </>
  )
