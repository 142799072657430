import React, { useState, useEffect } from 'react';
// import { useMutation } from '@apollo/client';
// import { ADD_NOTE, CLINIC_DETAIL } from './Helpers/gql';
import { TextArea, Button, Intent } from '@blueprintjs/core';
import ConfirmDelete from './ConfirmDelete';
import './styles.css';



const removeTime = (datetime) => {
    const split = datetime.split(",")
    return split[0]
};

export const ContactNotes = ({ data, id, contactId, isEditable }) => {
    //NOTE related code will need to be added in once resolver function is working.

    // const [newNote, setNewNote] = useState({type: `contact_notes_${contactId}`, text: ""});
    const [isOpen, setIsOpen] = useState(false);
    const [notes, setNotes] = useState([]);

    // const [addNote, { loading: addLoading, error: addError }] = useMutation(ADD_NOTE, {
    //     refetchQueries: [
    //         {
    //             query: CLINIC_DETAIL,
    //             variables: {
    //                 id
    //             }
    //         },
    //     ],
    //     awaitRefetchQueries: true,
    //     onCompleted(noteData) {
    //         if (noteData) setNewNote({...newNote, text: ""})
    //     }
    // });

    useEffect(() => {
        if (data) {
           setNotes(data.filter(note => note.text && note.text.length))
        }
    }, [data]);

    console.log(notes);

    //this will need to be here in the future:
    // const handleClick = (e) => {
    //     console.log(newNote)
    //     addNote({
    //         variables: {
    //             clinicId: id,
    //             text: newNote.text,
    //             type: newNote.type
    //         }
    //     })
    // };

    
    return (
        <div>
            <p>Notes:</p>
            {notes.length ? notes.map((note, i) => (
                <div key={`note-${note.id}-container`}>
                    <p>{`created by ${note.author} at ${removeTime(note.createdAt)}:`}</p>
                    <TextArea 
                            readOnly={!isEditable}
                            key={note.id}
                            id={note.id}
                            value={note.text}
                    />
                        <div className="buttonContainer">
                            <Button id={note.id} disabled={!isEditable} key={`delete-note-${note.id}`} name={`contact_notes_${contactId}`} onClick={()=>setIsOpen(true)} intent={Intent.WARNING}>Delete Note</Button>
                            {isOpen && <ConfirmDelete noteType={`contact_notes_${contactId}`} noteId={note.id} clinicId={id} category="note" isOpen={isOpen} setIsOpen={setIsOpen}/>}

                        </div>
                    </div> 
            )).reverse() : <p>-</p>
            }
        </div>
    )
};


export const KitNotes = ({ name, type, data, id, isEditable }) => {
   
    const [notesByTest, setNotesByTest] = useState([]);


    useEffect(() => {
        if (data) {
            if (name === "biopsyKitNotes") {
                setNotesByTest(data.clinicDetail.biopsyKitNotes)
            }
            else if (name === "salivaKitNotes") {
                setNotesByTest(data.clinicDetail.salivaKitNotes)
            }
        }
    }, [data, name]);

    return notesByTest.map((el,i) => (
        <div key={`note-container-${el.id}`}>
        <h4>{name === "biopsyKitNotes" ? `Biopsy Kit Note` : `Saliva Kit Note`}</h4>
        <p>{`Created by ${el.author} at ${removeTime(el.createdAt)}`}</p>
        <TextArea 
            key={`${el.id}`}
            id={el}           
            growVertically={true}
            large={true}
            value={el.text} 
            readOnly={true}
        />
        </div>
    )).reverse();
};


export const ShippingNotes = ({ data, id, shippingId, isEditable }) => {
    const [notes, setNotes] = useState([]);
    // const [newNote, setNewNote] = useState({type: `shipping_notes_${shippingId}`, text: ""});
    const [isOpen, setIsOpen] = useState(false)
    // const [addNote, { loading: addLoading, error: addError }] = useMutation(ADD_NOTE);
    
    useEffect(() => {
        if (data) {
            setNotes(data.filter(note => note.text && note.text.length))
        }
    }, [data]);

    //will need to be here in the future:
    // const handleClick = (e) => {
    //     console.log(newNote)
    //     addNote({
    //         variables: {
    //             clinicId: id,
    //             text: newNote.text,
    //             type: newNote.type
    //         }
    //     })
    // };
    

    return (
        <div>
            <p>Notes:</p>
             {/* <TextArea 
                readOnly={!state.isEditing}
                id={newNote.type}
                value={newNote.text}
                onChange={e=>setNewNote({...newNote, text: e.target.value})}
            />
            <div className="buttonContainer">
                <Button intent={Intent.SUCCESS} onClick={handleClick}>Add Note</Button>
            </div>    */}
            {notes.length ? notes.map((note, i) => (
                <div key={`shipping_notes_container_${note.id}`}>
                    <p>{`created by ${note.author} at ${removeTime(note.createdAt)}:`}</p>
                    <TextArea 
                        value={note.text}
                        key={note.id}
                        readOnly={true}
                        name={`shipping_notes_${shippingId}`}
                        id={note.id}
                    />
                    <div className="buttonContainer">
                        <Button id={note.id} name={`shipping_notes_${shippingId}`} key={`delete-note-${note.id}`} onClick={()=>setIsOpen(true)} disabled={!isEditable} intent={Intent.WARNING}>Delete Note</Button>
                        {isOpen && <ConfirmDelete  noteType={`shipping_notes_${shippingId}`} noteId={note.id} clinicId={id} category="note" isOpen={isOpen} setIsOpen={setIsOpen}/>}
                    </div>
                </div>
            )) : <p>-</p> }
        </div>

       
    ) 
};
