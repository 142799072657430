import React, { useState, useEffect } from 'react';
import './styles.css';
import KPIRowBox from './KPIRowBox';





const MetricsKPI = (props) => {
    const [failureRate, setFailureRate] = useState(0);
    const [aneuploidRate, setAneuploidRate] = useState(0);
    const [dqc, setDqc] = useState(0);
    const [callrate, setCallrate] = useState(0);
    const [mapd, setMapd] = useState(0)

    const { loading, error, data } = props;
    
    useEffect(()=>{
        if (data) {
            console.log('in metrics componenet', data );
            setAneuploidRate(data.clinicKpi.current.aneuploidRate);
            setCallrate(data.clinicKpi.current.callrate);
            setDqc(data.clinicKpi.current.dqc);
            setFailureRate(data.clinicKpi.current.failureRate);
            setMapd(data.clinicKpi.current.mapd);
        }
    }, [data])

    
    if (loading || error) return null;
    return (
        <div className="metrics">
            <div className="metrics-topRow" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5%'}}>
                <KPIRowBox data={data} loading={loading} error={error} title="Failure Rate" name="failureRate"previous={data.clinicKpi.previous.failureRate}stat={failureRate} referenceRange={data.clinicKpi.avg.failureRate}>{props.children}</KPIRowBox>
                <KPIRowBox data={data} loading={loading} error={error} title="Aneuploid Rate" name="aneuploidRate" previous={data.clinicKpi.previous.aneuploidRate} stat={aneuploidRate} referenceRange={data ? data.clinicKpi.avg.aneuploidRate : null}>{props.children}</KPIRowBox>
            </div>
            <div className="metrics-bottomRow" style={{display: 'flex', alignItems: 'space-between', justifyContent: 'center',  marginTop: '5%'}}>
                <KPIRowBox data={data} loading={loading} error={error} title="Callrate" name="callrate" previous={data.clinicKpi.previous.callrate} stat={callrate} referenceRange={data.clinicKpi.avg.callrate || null}>{props.children}</KPIRowBox>
                <KPIRowBox data={data} loading={loading} error={error} title="DQC" name="dqc" previous={data.clinicKpi.previous.dqc} stat={dqc} referenceRange={data ? data.clinicKpi.avg.dqc : null}>{props.children}</KPIRowBox>
                <KPIRowBox data={data} loading={loading} error={error} title="MAPD" name="mapd" previous={data.clinicKpi.previous.mapd} stat={mapd} referenceRange={data ? data.clinicKpi.avg.mapd : null}>{props.children}</KPIRowBox>
            </div>
            <div>
                <h3 className="arrayqc">Array QC</h3>
            </div>
        </div>
    )
}

export default MetricsKPI;