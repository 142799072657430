import React, {useState, useEffect} from "react";
import ReactPaginate from 'react-paginate';
import './style.css';


export default (props) => {
  let {first, skip, totalItems, onSelect} = props;

  const [pageCount, setPageCount] = useState(1);

  if (!first) {
    first = 1;
  }

  useEffect(() => {
    const n = Math.ceil(totalItems / first);
    setPageCount(n);
  }, [first, skip, totalItems]);

  return (
    <div className="pagination">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={6}
        marginPagesDisplayed={3}
        previousLabel={'<'}
        nextLabel={'>'}
        onPageChange={(e) => {
          onSelect(e.selected)
        }}
      />
    </div>
  )
}
