import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  )
}
