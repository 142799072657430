import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { HTMLSelect, FormGroup } from "@blueprintjs/core";

const PROVIDERS = gql`
    query clinicList {
        clinicList {
            id
            name
            isDistributor
        }
    }
`;

export const Assign = ({ data, name, value, onChange }) => {

const { data: providerData, loading: providerLoading, error: providerError } = useQuery(PROVIDERS)
const handleChange = (e) => {
    onChange(e)
};


const distributors = providerData ? providerData.clinicList
.filter(item => item.isDistributor)
.map((dist, i) => {
    return <option value={JSON.stringify(dist)} label={dist.name} key={`dist-${i+1}`} id={dist.id}>{dist.name}</option>
})
: null;


if (providerLoading) return <div>...Loading</div>
if (providerError) return <div>Error: {providerError}</div>
    return  (
        <>
        {data.clinicDetail.distributor ?
            <p>Assigned to distributor: {data.clinicDetail.distributor.name}</p>
        :  null }
            <FormGroup labelFor="distributor-name" label="Select clinic's distributor account:">
                <HTMLSelect name={name} value={value[0]} id={value[1]} onChange={handleChange}>
                    {distributors}
                </HTMLSelect>
           </FormGroup>
        </>
    )
};

