import React, { useState, useContext, useEffect }from 'react';
import { RadioGroup, Button, Radio, FormGroup, InputGroup, TextArea, NumericInput, Card } from '@blueprintjs/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CLINIC_DETAIL, UPDATE_CLINIC } from './Helpers/gql';
import { FormContext } from './Context';
import EditSwitch from './EditSwitch';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { ClinicFormToaster } from './Toaster';
import './styles.css';


export const Billing = ({ id, data }) => {
    const [state, dispatch] = useContext(FormContext);
    const [billingContact, setBillingContact] = useState([]);
    const [submitting, setSubmitting] = useState(false)
    let currentLocation = useLocation();
    let history = useHistory();

    const [values, setValues] = useState({
        billingType: "",
        clientBillOther: "",
        aSetUp: "0",
        aPerEmbryo: "0",
        aNote: "",
        mSetUp: "0",
        mPerEmbryo: "0",
        mNote: "",
        pSetUp: "0",
        pPerEmbryo: "0",
        pNote: "",
        srSetUp: "0",
        srPerEmbryo: "0",
        srNote: "",
        m2PerEmbryo: "0",
        m2Note: "",
    });
    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
            refetchQueries: [
                {
                    query: CLINIC_DETAIL, 
                    variables: {
                        id: id
                    }
                }    
            ], 
            onCompleted(){
                showToast();
                resetFields();
                window.scrollTo(0,0);
            }
    });
  
    
    useEffect(() => {
        if (data) {
            const { clinicDetail } = data;
            const contacts = clinicDetail.contact.filter(contact => contact.role === 'Billing Recipient')
            setValues(prev => ({
                ...prev,
                billingType: (clinicDetail.billingType && clinicDetail.billingType.includes("other")) ? "Client: other" : clinicDetail.billingType ||  "",
                clientBillOther: (clinicDetail.billingType  && clinicDetail.billingType.includes("other")) ? clinicDetail.billingType.split(" ").slice(2).join(" ") : "",
                aSetUp: clinicDetail.billing.a.setUp || "0",
                aPerEmbryo: clinicDetail.billing.a.perEmbryo || "0",
                aNote: clinicDetail.billing.a.notes || "" ,
                mSetUp: clinicDetail.billing.m.setUp || "0",
                mPerEmbryo: clinicDetail.billing.m.perEmbryo || "0",
                mNote: clinicDetail.billing.m.notes || "",
                pSetUp: clinicDetail.billing.p.setUp || "0",
                pPerEmbryo: clinicDetail.billing.p.perEmbryo || "0",
                pNote: clinicDetail.billing.p.notes || "",
                srSetUp: clinicDetail.billing.sr.setUp || "0",
                srPerEmbryo: clinicDetail.billing.sr.perEmbryo || "0",
                srNote: clinicDetail.billing.sr.notes || "",
                m2PerEmbryo: clinicDetail.billing.m2.perEmbryo || "0",
                m2Note: clinicDetail.billing.m2.notes || ""
            }))
            setBillingContact([...contacts])
        }
    }, [data])
    
  
    const handleChange = (e) => {
        const { value, name } = e.target;
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        })
        console.log('value', value, 'name', name);
        setValues({...values, [name]: value})
    };
  
    const handleNumberInput = (name, str) => {
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        })
        console.log('value', str, 'name', name);
        setValues({...values, [name]: str})
    }

    const resetFields = () => {
        return new Promise((resolve, reject) => {
            setSubmitting(true);
            let wait = setTimeout(() => {
                dispatch({
                    type: 'RESET_DIRTY_FIELDS'
                })
                clearTimeout(wait);
                resolve((setSubmitting(false)))
            }, 500)    
        })
    };

    useEffect(() => {
        console.log(submitting)
    }, [submitting]);

    const handleSubmit = e =>{
        e.preventDefault();
        updateClinic({
            variables: {
                id: id,
                input: {
                  billingType: values.clientBillOther.length ? `Client: other ${values.clientBillOther}` : values.billingType,
                  billing: {
                      a: {
                          setUp: values.aSetUp,
                          perEmbryo: values.aPerEmbryo,
                          notes: values.aNote
                      },
                      m: {
                        setUp: values.mSetUp,
                        perEmbryo: values.mPerEmbryo,
                        notes: values.mNote
                      }, 
                      p: {
                        setUp: values.pSetUp,
                        perEmbryo: values.pPerEmbryo,
                        notes: values.pNote
                      }, 
                      sr: {
                        setUp: values.srSetUp,
                        perEmbryo: values.srPerEmbryo,
                        notes: values.srNote
                      }, 
                      m2: {
                        perEmbryo: values.m2PerEmbryo,
                        notes: values.m2Note
                      }
                  }
                }
            }
        })
    };

    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };
    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                {updateError && <p>Error: {updateError.message}</p>}
                <EditSwitch />
                <RouteLeavingGuard

                when={Object.keys(state.dirtyFields).length > 0}
                navigate={(path)=>{
                    history.push(path);
                    dispatch({type: "RESET_DIRTY_FIELDS"})
                }}
                shouldBlockNavigation={location=>{
                    if (Object.keys(state.dirtyFields).length > 0 || submitting) {
                        if (location.pathname !== currentLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />
                <h1 className="form-header">Billing:</h1>
                <h2>Contact:</h2>
                {billingContact.map((contact, i) => <p key={`billing-contact-${i}`}>{contact.email}</p>)}
                <h2>Preferences:</h2> 
                    <RadioGroup label="Select Billing Type:" name="billingType" selectedValue={values.billingType || ""} onChange={handleChange} disabled={!state.isEditing}>
                        <Radio label="Patient" value="Patient" />
                        <Radio label="Client"  value="Client" />
                        <Radio label="Client: bill Progyny" value="Client: bill Progyny" />
                        <Radio label="Client: bill monthly" value="Client: bill monthly" />
                        <Radio label="Client: other" value="Client: other" />
                        
                    </RadioGroup>
                {/* {values.billingType === "Client: " && 
                    <div style={{marginLeft: '2%'}}>
                        <RadioGroup style={{marginLeft: "2%"}} name="clientBillType" selectedValue={values.clientBillType || ""} onChange={handleChange}>
                            <Radio label="Client: bill monthly" name="clientBillType" value="client-bill-monthly"/>
                            <Radio label="Client: bill Progyny" name="clientBillType" value="client-bill-progyny"/>
                            <Radio label="Client: other" name="clientBillType" value="client-other" />
                        </RadioGroup> */}
                            {values.billingType === "Client: other" && 
                            <div>
                                <FormGroup
                                    labelFor="client-bill-other"
                                >
                                    <InputGroup readOnly={!state.isEditing} id="client-bill-other" name="clientBillOther" value={values.clientBillOther} onChange={handleChange} />
                                </FormGroup>
                            </div>
                            }
                <div style={{maxWidth: '50%'}}>
                    <h2>Pricing:</h2>        
                    <Card className="pricing-card" style={{margin: "0 0 3% 0"}}>
                    <FormGroup
                            labelFor={`setupFee-PGT-A`}
                            label={`Setup Fee: PGT-A`}
                            key={`formSetup-PGT-A`}
                        >
                            <NumericInput id={`setupFee-PGT-A`} name="aSetUp"  allowNumericCharactersOnly={false} value={values.aSetUp} min={0} onValueChange={(valNum, valStr) =>handleNumberInput('aSetUp', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <FormGroup
                            labelFor={`biopsyFee-PGT-A`}
                            label={`Per Embryo Fee: PGT-A`}
                        >
                            <NumericInput id={`biopsyFee-PGT-A`} name="aPerEmbryo" allowNumericCharactersOnly={false} value={values.aPerEmbryo} onValueChange={(valNum, valStr) =>handleNumberInput('aPerEmbryo', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                            <TextArea name="aNote" id="aNote" value={values.aNote} onChange={handleChange} growVertically={true} readOnly={!state.isEditing} />
                    </Card>
                    <Card style={{margin: "0 0 3% 0", minWidth: '400px'}}>
                    <FormGroup
                            labelFor={`setupFee-PGT-M`}
                            label={`Setup Fee: PGT-M`}
                        >
                            <NumericInput id={`setupFee-PGT-M`} allowNumericCharactersOnly={false} name="mSetUp" value={values.mSetUp} onValueChange={(valNum, valStr)=>handleNumberInput('mSetUp', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <FormGroup
                            labelFor={`biopsyFee-PGT-M`}
                            label={`Per Embryo Fee: PGT-M`}
                        >
                            <NumericInput id={`biopsyFee-PGT-M`}   name="mPerEmbryo" allowNumericCharactersOnly={false} value={values.mPerEmbryo} onValueChange={(valNum, valStr)=>handleNumberInput('mPerEmbryo', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <TextArea name="mNote" id="mNote" value={values.mNote} onChange={handleChange} growVertically={true} readOnly={!state.isEditing} />
                    </Card>
                    <Card style={{margin: "0 0 3% 0"}}>
                    <FormGroup
                            labelFor={`setupFee-PGT-P`}
                            label={`Setup Fee: PGT-P`}
                        >
                            <NumericInput id={`setupFee-PGT-P`} name="pSetUp" allowNumericCharactersOnly={false} value={values.pSetUp} onValueChange={(valNum, valStr)=>handleNumberInput('pSetUp', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <FormGroup
                            labelFor={`biopsyFee-PGT-P`}
                            label={`Per Embryo Fee: PGT-P`}
                        >
                            <NumericInput id={`biopsyFee-PGT-P`} name="pPerEmbryo" allowNumericCharactersOnly={false} value={values.pPerEmbryo} onValueChange={(valNum, valStr)=>handleNumberInput('pPerEmbryo', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <TextArea name="pNote" id="pNote" value={values.pNote} onChange={handleChange} growVertically={true} readOnly={!state.isEditing} />
                    </Card>
                    <Card style={{margin: "0 0 3% 0"}}>
                    <FormGroup
                            labelFor={`setupFee-PGT-SR`}
                            label={`Setup Fee: PGT-SR`}
                        >
                            <NumericInput id={`setupFee-PGT-SR`} name="srSetUp" allowNumericCharactersOnly={false} value={values.srSetUp} onValueChange={(valNum, valStr)=>handleNumberInput('srSetUp', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <FormGroup
                            labelFor={`biopsyFee-PGT-SR`}
                            label={`Per Embryo Fee: PGT-SR`}
                        >
                            <NumericInput id={`biopsyFee-PGT-SR`} name="srPerEmbryo" value={values.srPerEmbryo} allowNumericCharactersOnly={false} onValueChange={(valNum, valStr)=>handleNumberInput('srPerEmbryo', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <TextArea name="srNote" id="srNote" value={values.srNote} onChange={handleChange} growVertically={true} readOnly={!state.isEditing} />
                    </Card>
                    <Card style={{margin: "0 0 3% 0"}}>
                        <FormGroup
                            labelFor={`biopsyFee-M2`}
                            label={`Pricing: M2`}
                        >
                            <NumericInput id={`biopsyFee-M2`} name="m2PerEmbryo" value={values.m2PerEmbryo}  allowNumericCharactersOnly={false} onValueChange={(valNum, valStr)=>handleNumberInput('m2PerEmbryo', valStr)} readOnly={!state.isEditing} />
                        </FormGroup>
                        <TextArea name="m2Note" id="aNote" value={values.m2Note} onChange={handleChange} growVertically={true} readOnly={!state.isEditing} />
                    </Card>
                    <Button text="Save" intent="primary" onClick={handleSubmit} disabled={updateLoading}></Button>
                </div>
            </form>
        </div>
    )
}


export default Billing;