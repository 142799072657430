import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  p: {
    marginTop: 2,
    marginBottom: 1,
    fontFamily: 'Poppins',
    fontSize: 7,
    fontWeight: 'normal',
    fontStyle: 'normal'
  },
  strong: {
    fontFamily: 'Poppins',
    fontSize: 7,
    fontWeight: 'bold',
  },
  h3: {
    marginTop: 3,
    marginBottom: 2,
    fontFamily: 'Poppins',
    fontSize: 9,
    fontWeight: 'bold'
  },
  ul: {
  },
  li: {
    marginLeft: 10,
  },
});

/**
 * @typedef elementNode
 * @property {string} type - #text p h3 strong ul li
 * @property {boolean} isParent
 *           if true, content meaningless (or not set) and children array holds elementNodes
 *           if false, content holds text and children array is empty (or not set)
 * @property {string} content
 * @property {elementNode[]} children
 *
*/

/**
 * @typedef elementAndRemainder
 * @property {elementNode}
 * @property {string} remainder - remaining HTML content to parse
 */

/**
 * @typedef htmlThing
 * @property {string} type - 'open', 'close', 'text'
 * @property {string} tagName - name of tag (in lowercase) if open or close
 * @property {string} content - if 'text', the text content found
 * @property {string} remainder - remainder of html content to be parsed
 */

/**
 * @type {RegExp}
 * 0 - entire string
 * 1 - all text before tag
 * 2 - tag + all subsequent text
 * 3 - html tag.  e.g. "<p>"
 * 4 - html tag name.  e.g. "p"
 * 5 - any tag properties
 * 6 - all text after tag
 */
const htmlTagRegex = /^(.*?)((<([a-zA-Z0-9/]+)(.*?)\s*>)(.*))$/s;

/**
 * parse next open tag, close tag, or text from html string
 * @param {string} content - html string
 * @return {htmlThing|null}
 * */
const parseNextThing = (content) => {
  if (!content || !content.trim()) return null;

  const matchArray = content.match(htmlTagRegex);
  if (matchArray) {
    if (matchArray[1])
      return {type: 'text', tagName: '#text', content: matchArray[1], remainder: matchArray[2]};

    let tagName = matchArray[4].toLowerCase();
    if (tagName.startsWith('/'))
      return {type: 'close', tagName: tagName.substring(1), content: '', remainder: matchArray[6]};
    return {type: 'open', tagName: tagName, content: '', remainder: matchArray[6]};
  }

  return {type: 'text', tagName: '#text', content, remainder: ''};
}

/**
 * for given html tag (e.g. 'p'), parse its elementNode from given html content
 *
 * @param {string} tagName
 * @param {string} content - remaining html content to be parsed
 * @return {elementAndRemainder|null}
 *  */
const parseTagNode = (tagName, content) => {
  if (!content || !content.trim()) return null;

  const thisNode = {type: tagName, isParent: false, content: ''};

  let thing = parseNextThing(content);
  if (!thing) return null;

  // immediate close.  No content.  e.g. <p></p>
  if (thing.type === 'close') {
    if (thing.tagName !== tagName) {
      console.error('mismatched close', tagName, thing.tagName, thing.remainder);
      return null;
    }
    return {
      node: thisNode,
      remainder: thing.remainder
    };
  }

  // if begins with text, skip to what's after that
  let textNode = null;
  if (thing.type === 'text') {
    textNode = {type: '#text', isParent: false, content: thing.content};
    thing = parseNextThing(thing.remainder);
  }

  if (!thing) {
    console.error('missing close', tagName);
    return null;
  }

  if (thing.type === 'close') {
    if (thing.tagName !== tagName) {
      console.error('mismatched close [2]', tagName, thing.tagName, thing.remainder);
      return null;
    }
    // This is <tag>text</tag>
    if (textNode) thisNode.content = textNode.content;
    return {
      node: thisNode,
      remainder: thing.remainder
    };
  }

  // This is nested tag

  thisNode.isParent = true;
  thisNode.children = [];
  if (textNode) thisNode.children.push(textNode);
  while (thing.type !== 'close') {
    const x = parseTagNode(thing.tagName, thing.remainder);
    if (!x) return null;
    thisNode.children.push(x.node);
    thing = parseNextThing(x.remainder);
    if (!thing) return null;

    if (thing.type === 'text') {
      thisNode.children.push({type: '#text', isParent: false, content: thing.content});
      thing = parseNextThing(thing.remainder);
    }
  }

  if (thing.tagName !== tagName) {
    console.error('mismatched close [3]', tagName, thing.tagName, thing.remainder);
    return null;
  }
  return {
    node: thisNode,
    remainder: thing.remainder
  };
}

/**
 * Render given elementNode and its children
 * @param {elementNode}
 * @returns {*|JSX.Element}
 */
const RenderElementNode = ({node}) => {
  if (node.type === '#text') {
    return <Text>{node.content}</Text>;
  }

  const content = node.isParent ? node.children.map((child, index) => <RenderElementNode key={index} node={child} />) : node.content;

  switch (node.type) {
    case 'p':
      return <Text style={styles.p}>{content}</Text>;
    case 'strong':
      return <Text style={styles.strong}>{content}</Text>;
    case 'h3':
      return <Text style={styles.h3}>{content}</Text>;
    case 'ul':
      return <View style={styles.ul}>{content}</View>;
    case 'li':
      return <Text style={styles.li}> • {content}</Text>;
    // case 'img':
    //  return <Image style={styles.img} src={node.attrs.find(attr => attr.name === 'src').value} />;
    default:
      if (node.type !== 'html') console.error('Unknown render type', node.type);
      return content;
  }
};

/**
 * Convert HTML string to render of React-PDF/renderer components
 *
 * @param {string} html
 * @returns {JSX.Element|null} - React-PDF/renderer compatible rendering of Views and Text
 */
const ConvertHTMLToPDF = ({html}) => {
  const html2 = html
    .replace(/&lt;/g,'<')
    .replace(/&gt;/g,'>')
    .replace(/&nbsp;/g,' ')
    .replace(/&amp;/g,'&')
    .replace(/<br\s*\/?\s*>/ig, '\n');

  // Convert html into hierarchy of elementNodes
  const result = parseTagNode("html", html2 + "</html>");
  if (result)
    return (
        <View>
          <RenderElementNode node={result.node} />
        </View>
    );
  return null;
};

export default ConvertHTMLToPDF;