import React from 'react';
import './styles.css';

const FilesList = ({ data }) => {
   
    return (
        <div className="form-container">
            <ul className="attachments">
                {
                    data.map((attachment, i) => (
                        <li key={`attachment-${i+1}`}><a href={attachment.url}>{attachment.fileName}</a></li>
                    )).reverse()
                }
            </ul>
        </div>
    )
};

export default FilesList;
