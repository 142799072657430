import React from 'react';
import 'layouts/Empty/style/main.css';


export default (props) => {
  return (
    <div style={{position: 'relative', minHeight: '100%', height: '100%'}}>
      {props.children}
    </div>
  )
}
