import React, {FunctionComponent} from 'react'
import {Icon, Intent, Tag} from "@blueprintjs/core";


type ComponentProps = {
  stepType: string
}

const tagStyle= {
  paddingTop: 4, paddingBottom: 6, paddingLeft: 8, paddingRight: 8
}


const StepTypeIndicator: FunctionComponent<ComponentProps> = ({stepType}) => {
  if (stepType === 'SPOT') {
    return (
      <div>
        <Tag minimal round style={tagStyle} intent={Intent.PRIMARY}>
          <Icon icon="tick-circle" iconSize={12} intent={Intent.PRIMARY} />
        </Tag>
      </div>
    )
  } else if (stepType === 'RANGE') {
    return (
      <div>
        <Tag minimal round style={tagStyle} intent={Intent.PRIMARY}>
          <Icon icon="automatic-updates" iconSize={12} intent={Intent.PRIMARY} />
        </Tag>
      </div>
    )
  } else if (stepType === 'FLOW') {
    return (
      <div>
        <Tag minimal round style={tagStyle} intent={Intent.PRIMARY}>
          <Icon icon="diagram-tree" iconSize={12} intent={Intent.PRIMARY} />
        </Tag>
      </div>
    )
  }

  return null
}


export default StepTypeIndicator
