import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const LanguageSection = ({data, onChange}) => {
  const [value, setValue] = useState(data);

  //console.log(data.replace(/{' '}/g, ''))

  // useEffect(() => {
  //   onChange(value)
  // }, [value, onChange])

  const modules = {
    toolbar: [
    //  [{'header': [3, false]}],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['clean']
    ],
    clipboard: { matchVisual: false }
  }

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(v) => {setValue(v); onChange(v)}}
        modules={modules}
        style={{background: '#ffffff'}}
        //preserveWhitespace
      />
    </div>
  )
}

export default LanguageSection;
