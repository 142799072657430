import React, {FunctionComponent} from 'react';


type ComponentProps = {
    show: boolean
};


const LoadingOverlay: FunctionComponent<ComponentProps> = ({show}) => {
    return (
        <div
            style={{
                display: show ? 'block' : 'none',
                position: 'fixed',
                zIndex: 9990,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(255,255,255,0.82)'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div style={{fontSize: 24}}>Loading...</div>
            </div>
        </div>
    )
}


export default LoadingOverlay;
