import React, {useState} from "react";
import { Font } from "@react-pdf/renderer";
import axios from 'axios';
import ReportForm from 'components/Report/Form/Form';
import Preview from 'components/Report/Preview';
import LoadingOverlay from 'components/Common/Loading/LoadingOverlay';
import DownloadInBrowser from 'components/Report/DownloadInBrowser';
import DownloadPDFLink from 'components/Report/DownloadPDFLink';

import * as fontPoppinsRegular from 'assets/fonts/Poppins/Poppins-Regular.ttf';
import * as fontPoppinsLight from 'assets/fonts/Poppins/Poppins-Light.ttf';
import * as fontPoppinsMedium from 'assets/fonts/Poppins/Poppins-Medium.ttf';
import * as fontPoppinsBold from 'assets/fonts/Poppins/Poppins-Bold.ttf';
import * as fontPoppinsSemiBold from 'assets/fonts/Poppins/Poppins-SemiBold.ttf';

import * as fontRobotoMonoMedium from 'assets/fonts/Roboto_Mono/static/RobotoMono-Medium.ttf';
import * as fontRobotoMonoRegular from 'assets/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf';
import {Button, H5} from "@blueprintjs/core";
import {CONDITIONS_BY_PANEL} from "components/Report/index";
import camelCase from "components/Report/services/getStrToCamelCase";
import {gql, useMutation} from "@apollo/client";
export const registerFont = () => {
  Font.register({
    family: 'Poppins', fonts: [
      {src: fontPoppinsLight, fontStyle: 'normal', fontWeight: 'light'},
      {src: fontPoppinsRegular, fontStyle: 'normal', fontWeight: 'normal'},
      {src: fontPoppinsMedium, fontStyle: 'normal', fontWeight: 'medium'},
      {src: fontPoppinsSemiBold, fontStyle: 'normal', fontWeight: 'semibold'},
      {src: fontPoppinsBold, fontStyle: 'normal', fontWeight: 'bold'}
    ]
  });

  Font.register({
    family: 'RobotoMono', fonts: [
      {src: fontRobotoMonoRegular, fontStyle: 'normal', fontWeight: 'normal'},
      {src: fontRobotoMonoMedium, fontStyle: 'normal', fontWeight: 'medium'},
    ]
  });

  Font.registerHyphenationCallback(word => [word]);
}

registerFont();

const GQL_UPDATE_REPORT_VALUES = gql`
  mutation updateConfigReportValues($input: ConfigReportValuesInput!) {
    updateConfigReportValues(input: $input) {
        config {
          person {
            id
          },
          disclaimerLanguage,
          reportInfo,
          pgtpPanelEthnicity,
          conditions
        }
    }
  }
`;


const generateBlankPanel = (diseases = []) => {
  return '[{"name":"heart.attack","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Heart Attack"},{"name":"atrial.fibrillation","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Atrial Fibrillation"},{"name":"coronaryArteryDisease","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Coronary Artery Disease"},{"name":"breast","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Breast Cancer"},{"name":"basal.cell.carcinoma","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Basal Cell Carcinoma"},{"name":"testicular","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Testicular Cancer"},{"name":"prostate","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Prostate Cancer"}{"name":"malignant.melanoma","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Malignant Melanoma"},{"name":"diabetes.type1","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Type 1 Diabetes"},{"name":"diabetes.type2","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Type 2 Diabetes"},{"name":"asthma","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Asthma"},{"name":"inflammatory.bowel.disease","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Inflammatory Bowel Disease"},{"name":"schizophrenia","risk_percentile":0,"risk_value":0,"risk_avg":0,"risk_ratio":0,"risk":0,"index":0,"qaly_diff":0,"title":"Schizophrenia"}]';
}

/**
 * Body of report page (invoked with <ReportDocument ...>)
 *
 * @param {object} props - {data} - {patient/case data, selectPanel, diseases, language}
 *                      see CASES in components/Report/index.js for patient/case fields
 * @returns {JSX.Element}
 */
export default (props) => {
  const [link, setLink] = useState('');
  const [updateReportValues] = useMutation(GQL_UPDATE_REPORT_VALUES);

  /**
   * Format sample data for output, then sort into arrays: all, failed, aneuploid and euploid
   *
   * @param {object} samples - array of samples from case data
   *                           see CASES in components/Report/index.js for sample fields
   * @param {boolean} hideSexInKaryotype
   * @param selectedPanel
   * @param diseases
   * @returns {{all: *[], euploid: *[], aneuploid: *[], failed: *[]}}
   */
  const transformSampleData = (samples, hideSexInKaryotype = false, selectedPanel = null, diseases = []) => {
    const euploid = [];
    const aneuploid = [];
    const failed = [];
    const all = [];
  
    if (samples) {
      samples
        .forEach((sample) => {
          const parsedPanel = JSON.parse(sample.panel);
          let roundedPanelVals = Object.assign(parsedPanel, {});

          for (let disease in Object.keys(parsedPanel)) {
            if (Object.keys(parsedPanel[disease]).length){     
              roundedPanelVals[disease].risk_percentile = ((parsedPanel[disease].risk_percentile) || (parsedPanel[disease].risk_percentile === 0)) ? parseFloat((parsedPanel[disease].risk_percentile).toFixed(2)) : null;
              roundedPanelVals[disease].risk_ratio = ((parsedPanel[disease].risk_ratio) || (parsedPanel[disease].risk_ratio === 0)) ? parseFloat((parsedPanel[disease].risk_ratio).toFixed(2)) : null;
              roundedPanelVals[disease].risk_value = ((parsedPanel[disease].risk_value) || (parsedPanel[disease].risk_value === 0)) ? parseFloat((parsedPanel[disease].risk_value) * 100).toFixed(2) : null;
              roundedPanelVals[disease].risk_avg = ((parsedPanel[disease].risk_avg) || (parsedPanel[disease].risk_avg === 0)) ? parseFloat((parsedPanel[disease].risk_avg) * 100).toFixed(2) : null;
            }
          }
          sample = {...sample, panel: JSON.stringify(roundedPanelVals)}
          if (hideSexInKaryotype) {
            sample = {...sample, karyotype: sample.karyotype.replace(/XX/g, '--').replace(/XY/g, '--').replace(/X/g, '--').replace(/Y/g, '--')}
          }

          if (sample.complexAneuploid) {
            sample = {...sample, sex: '-'}
          }

          if (selectedPanel && sample.panel) {
            const allowedConditions = CONDITIONS_BY_PANEL[selectedPanel]
            sample = {...sample, allowedConditions: allowedConditions, diseases: data.diseases}
            //sample = {...sample, panel: sample.panel.filter((item) => allowedConditions.includes(item.name))}
          }

          if (sample.noAmp || sample.inconclusive || sample.noResult) {
            let aUpdated = sample.karyotype
            if (sample.noAmp) {
              aUpdated = 'No Amp'
            }

            if (sample.inconclusive) {
              aUpdated = 'Inconclusive'
            }
            sample = {...sample, sex: '-'}

            failed.push({...sample, pgt_a: '', sex: '', karyotype: aUpdated});
          } else if (sample.aneuploid || sample.complexAneuploid){
            aneuploid.push({...sample, panel: generateBlankPanel(), pgt_a: 'aneuploid'});
          } else {
            euploid.push({...sample, pgt_a: 'euploid'})
          }

          all.push(sample)
        });
    }

    return {euploid, aneuploid, failed, all}
  }

  const [data, setData] = useState({
    ...props.data,
    analysisVersion: props.data.analysisVersion, //'v22.6 06/05/2021',
    reportVersion: props.data.reportVersion, //'v2.2.0 05/03/2021',
    showPTableForAneuploid: true,
    isAmended: props.data.reportInfo.includes('amended'),
    layout: 1
  });

  const [documentLoading, setDocumentLoading] = useState(false)
  const [generating, setGenerating] = useState(false);

  /** Preserve report settings so that they can be used as default
   *  for subsequent reports for same patient
   */
  const saveSettings = (values) => {
    if (values) {
      updateReportValues({
        variables: {
          input: {
            "person": {
              "id": values.patient.id
            },
            "disclaimerLanguage": data.testTypes.slice().sort().join(',') + '||||' + values.language,
            "reportInfo": values.reportInfo,
            "pgtpPanelEthnicity": values.selectedPanel,
            "conditions": values.diseases && values.diseases.length > 0 ? values.diseases : []
          }
        }
      }).then(r => r);
    }
  }

  const onChange =  (newData) => {
    setDocumentLoading(true);
    setData(prev =>  {
      saveSettings(newData);
      return Object.assign({}, prev, newData)
    });
    setTimeout(() => {
     setDocumentLoading(false)
    }, [500])
  };

  const sampleData = transformSampleData(data.samples, data.hideSexInKaryotype, data.selectedPanel, data.diseases ? data.diseases : [])

  /** Title is case-id+'.pdf' or 'report.pdf' */
  const title = (caseId) => {
    let res = 'report'

    if (!!caseId) {
      res = caseId
    }

    return res + '.pdf'
  }

  const splitLanguageForValidity = (language, testType) => {
    if (testType !== "M2") {
      const sectionArr = language.split("<h3>");
      let formatted = {};
      sectionArr.forEach(section => {
        let value = "<h3>"
        let key = ""
        const regexp = /<h3>(.*?)<\/h3>/g

        if (section.length) {
          value += section;
          key += value.match(regexp)
          const header = key.replace(/<\/?h3>/g,'');
          if (header !== "Genetic counseling is recommended."){
            const camelcased = camelCase(header.slice(0, -1))
            formatted[camelcased] = value
          }
          else {
            formatted["pgtMDisclaimer"] = value
          }
        }
      })
      return formatted
    }
    else { // testType is M2
      return {
        resultInterpretation: '<p><strong>Result Interpretation:</strong></p><p>Genotyping for the Annexin A5 (ANXA5) M2 haplotype was performed. "Negative" indicates that the M2 haplotype was not detected. "Heterozygous Positive" indicates that one copy of the M2 haplotype was detected. "Homozygous Positive" indicates that two copies of the M2 haplotype were detected. Previous studies suggest that individuals who are positive for at least one copy of the M2 haplotype may benefit from antithrombotic intervention with low molecular weight heparin in order to achieve similar success rates as non-. carrier individuals (Fishel et al. Precision Medicine in Assisted Conception: A Multicenter Observational Treatment Cohort Study of the Annexin AS M2 Haplotype as a Biomarker for Antithrombotic Treatment to Improve Pregnancy Outcome. EBioMedicine, 2016).</p><p><br></p>',
        methodsAndLimitations: '<p><strong>Methods and Limitations:</strong></p><p>This test determines the status of carrying the ANXA5 M2 haplotype in an individual using quantitative real-time PCR. When used on positive controls the test performs with &gt;99% accuracy. Results are in reference to the normal human genome (hg19; NG 032042.1:g.[4937G;4956A;4982T;5031G]). Positive and negative controls performed as expected. This service is an adjunct to the evaluation of the referring clinician and is designed to contribute to his or her overall assessment. Results must be interpreted in the context of clinical findings. This test was developed. and its performance characteristics determined by Genomic Prediction Clinical Laboratory. It has not been cleared or approved by the U.S. Food and Drug Administration (FDA).</p>'
      }
    }
  }

  /** Get the CPV1 backend auth token passed to us by cpv1 */
  const getToken = () => {
    try {
        const obj = JSON.parse(window.localStorage.getItem('persist:clinic-portal'))
        const { auth } = obj
        const t = JSON.parse(auth)
        return t.token
    }
    catch(e){
      console.log('error obtaining token:', e)
      return false
    }
  }

  /**
   * Using CPv1 backend, validate data, then generate a PDF using Server-Side-Reporting
   */
  const mapDataToSchema = (event, data) => {
    try {
      event.preventDefault();
      saveSettings(data);
      setGenerating(true);
      let testTypeParam = '';
      let validate;
      const token = getToken() ? getToken() : window.localStorage.getItem('persist:clinic-portal');
      if (data.testTypes.includes("A") || data.testTypes.includes('A+')) {    // If PGT report ...
        validate = {
            testTypes: [...data.testTypes],
            caseId: data.caseId,
            analysisVersion: data.analysisVersion,
            reportVersion: data.reportVersion,
            reportSigned: data.reportSigned,
            sampleReceived: data.samplesReceivedAt,
            sampleType: data.sampleType,
            patientName: data.patientName,
            partnerName: data.partnerName || "",
            patientDOB: data.patient.dob,
            partnerDOB: data.partner.dob || "",
            patientEmail: data.patient.email,
            partnerEmail: data.partner.email || "",
            patientTelephone: data.patient.phone,
            partnerTelephone: data.partner.phone || "",
            patientAddress: data.patient.address ? JSON.parse(data.patient.addressForSsr) : {},
            partnerAddress: data.partner.address ? JSON.parse(data.partner.addressForSsr) : {},
            diseaseList: data.diseases,
            patientEthnicity: data.patient.ethnicity,
            partnerEthnicity: data.partner.ethnicity,
            referringClinician: data.clinicianName || "",
            referringClinicianTelephone: data.clinicianPhone || "",
            referringClinicianAddress: JSON.parse(data.clinicAddress) || {},
            referringClinic: data.clinicName,
            reportCycleNumber: data.reportCycleNumber,
            cycleNumber: data.cycleNumber,
            reportEmbryoGrade: data.reportEmbryoGrade,
            reportEmbryoId: data.reportEmbryoId,
            reportBiopsyDay: data.reportBiopsyDay,
            info: data.reportInfo,
            pgtpPanel: data.selectedPanel,
            results: [...data.samples],
            layout: data.layout,
            isAmended: data.isAmended,
            language: splitLanguageForValidity(data.language, "PGT"),
            selectedListOfEmbryos: ["1", "2", "3"],
            //maybe handle in FE? not clear
            logic: {
                hideSexKaryotype: data.hideSexInKaryotype || false,
                reportSexEmbryo: data.hideSexInKaryotype || true
            }
          } 
        testTypeParam += 'pgt'
        const optionalFields = ['patientEmail', 'partnerEmail', 'patientTelephone', 'partnerTelephone', 'patientAddress', 'partnerAddress', 'partnerDOB', 'clinicLogo', 'referringClinicianTelephone', 'referringClinicianAddress', 'info']
        optionalFields.forEach(field => {
          if (typeof validate[field] === 'string' && !validate[field].length) {
            delete validate[field] 
          }
          if (typeof validate[field] === 'object' && !Object.values(validate[field]).length) {
            delete validate[field]
          }
        })
      } else {  // ... is M2 report
        validate = {
          testTypes: [...data.testTypes],
          caseId: data.caseId,
          analysisVersion: data.analysisVersion,
          reportVersion: data.reportVersion,
          reportSigned: data.reportSigned,
          sampleReceived: data.samplesReceivedAt || '01/01/1999',
          sampleType: data.sampleType,
          patientName: data.patientName,
          patientDOB: data.patient.dob,
          patientEmail: data.patient.email,
          clientReference: data.clientReference,
          patientTelephone: data.patient.phone || "",
          patientAddress: data.patient.address ? JSON.parse(data.patient.addressForSsr) : {},
          referringClinician: data.clinicianName || "",
          referringClinicianTelephone: data.clinicianPhone || "",
          referringClinicianAddress: JSON.parse(data.clinicAddress) || {},
          referringClinic: data.clinicName,
          info: data.reportInfo,
          results: [...data.samples],
          salivaSamples: [...data.salivaSamples],
          layout: data.layout,
          isAmended: data.isAmended,
          test_performed_by: data.testPerformedBy || "",
          language: splitLanguageForValidity(data.language, "M2"),
          // results: data.testTypes.includes("M2") ? getResults(data.salivaSamples) : getResults(data.samples),
          //maybe handle in FE? not clear
          logic: {
              blinded: data.blinded,
              showDOBIfBlinded: data.show_db || false
          }
        }
        testTypeParam += 'm2';
        const optionalFields = ['patientEmail', 'patientTelephone', 'patientAddress', 'clinicLogo', 'referringClinicianTelephone', 'referringClinicianAddress', 'info']
                optionalFields.forEach(field => {
          if (typeof validate[field] === 'string' && !validate[field].length) {
            delete validate[field] 
          }
          if (typeof validate[field] === 'object' && !Object.values(validate[field]).length) {
            delete validate[field]
          }
        })
      }
        
      const resp = axios({
        method: 'POST',
        contentType: 'application/json',
        baseURL: process.env.REACT_APP_API_HOST,
        data: {"schema_v": testTypeParam, case: validate},
        url: `validate-generate-pdf/`,
        headers: {
          "Authorization": `Token ${token}`
        }
      }).then(res=>{
        const tuple = res.data.body.Payload
        const s3signed = JSON.parse(tuple[0] + tuple[1])
        setLink(s3signed);
      })
      return resp
      .catch(function(error) {
        if (error.response) {
          throw new Error(error.response.data)
        }
      })
    }
    catch(error) {
      throw new Error(error)
   }
  }
  
  const downloadFile = (link) => {
    window.location.href = link;
    window.alert("PDF Downloaded successfully.")
    // setTimeout(() => {
    //   setGenerating(false);
    //   setLink("");
    //  }, [1000]);
    document.location.reload() 
  };
  
  
  return (
    <div style={{paddingRight: 800}}>
      <ReportForm
        documentLoading={documentLoading}
        initialData={{...data}}
        onChange={onChange}
      />
      <div
        style={{
          position: 'fixed',
          right: 0,
          top: 50,
          bottom: 0,
          border: 'none',
          width: 800
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 50,
            width: 800,
            right: 0,
            background: '#212121',
            height: 60
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              height: '100%'
            }}
          >
            <div>
              <H5 style={{color: '#ffffff', margin: 0}}>{title(data.caseId)}</H5>
            </div>
            <div style={{color: '#ffffff'}}>
            <Button intent="primary" onClick={(e)=>{mapDataToSchema(e, {...data})}}>{documentLoading ? 'Loading...' : 'Generate PDF'}</Button>
            <DownloadPDFLink data={data} sampleData={sampleData} title={title(data.caseId)} />
            </div>
          </div>
        </div>
        <LoadingOverlay show={generating} />
        {
        link.length ?
          <DownloadInBrowser link={link} downloadFile={downloadFile} />
          // <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: '100%',
          // height: '100%'}}>
          //   <p>Click <Link to="/" onClick={e=>downloadFile(e)}>here</Link> to download PDF </p>
          // </div>
          :
          <Preview data={data} sampleData={sampleData} title={title(data.caseId)} />
        }
      </div>
    </div>
  )
}
