import React, {FunctionComponent} from 'react'


type ComponentProps = {
  started?: boolean,
  completed?: boolean,
  canceled?: boolean
}


const COLORS = {
  canceled: '#000000',
  started: '#de811f',
  completed: '#1cc86f',
  pending: '#b7b7b7'
}


const StatusStripe: FunctionComponent<ComponentProps> = ({started = false, completed = false, canceled = false}) => {
  const getColor = () => {
    if (canceled) return COLORS.canceled
    if (completed) return COLORS.completed
    if (started) return COLORS.started

    return COLORS.pending
  }

  return (
    <div
      style={{
        width: 3,
        position: 'absolute',
        left: -1,
        top: -1,
        bottom: -1,
        background: getColor(),
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
      }}
    />
  )
}


export default StatusStripe
