import React, {useState, useEffect} from 'react';
import {Card, Checkbox, H2} from "@blueprintjs/core";
import ClinicList from "components/Clinics/ClinicList";
import ClinicPlot from "components/Clinics/ClinicPlot";
import {useQuery, gql} from "@apollo/client";
import LoadingOverlay from "components/Common/LoadingOverlay";
import {DateRangeInput} from "@blueprintjs/datetime";
import moment from "moment";
import {Col, Row} from "react-flexbox-grid";


const DATA = gql`
  query clinicSampleList($period: String!, $completeOnly: Boolean, $timeFrame: [DateTime]) {
    clinicSampleList(period: $period, completeOnly: $completeOnly, timeFrame: $timeFrame){
      id
      companyName
      samplesTotalCount
      samplesWithResultsCount
      samplesWithNoResultsCount
      samplesAneuploidCount
      samplesFailedCount
      samplesEuploidCount
    }
  }
`;


export default (props) => {
  const [completeOnly, setCompleteOnly] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [summary, setSummary] = useState({
    total: 0,
    totalFailed: 0,
    totalAneuploid: 0,
    failedRate: 0,
    aneuploidyRate: 0
  })

  const period = 'all';
  
  const {loading, error, data} = useQuery(DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      period: period,
      completeOnly: completeOnly,
      timeFrame: dateRange
    }
  });

  useEffect(() => {
    if (data) {
      const clinics = data ? data.clinicSampleList.slice() : [];

      if (clinics.length > 0) {
        const totalFailed = clinics.reduce((prev, cur) => {
          return prev + cur.samplesFailedCount
        }, 0);
        const totalAneuploid = clinics.reduce((prev, cur) => {
          return prev + cur.samplesAneuploidCount
        }, 0);
        const total = clinics.reduce((prev, cur) => {
          return prev + cur.samplesWithResultsCount
        }, 0);
        const failedRate = Math.round(((totalFailed / total * 100) + Number.EPSILON) * 100) / 100
        const aneuploidyRate = Math.round(((totalAneuploid / total * 100) + Number.EPSILON) * 100) / 100

        setSummary({total, totalFailed, failedRate, totalAneuploid, aneuploidyRate});
      }
    }
  }, [data])

  return (
    <div className="page">
      <LoadingOverlay isOpen={loading}/>

      <div style={{padding: '30px 0'}}>
        <H2>Clinics</H2>
      </div>

      <div>
        <div>
          <Row start="xs" middle="xs">
            <Col xs={5}>
              <DateRangeInput
                formatDate={date => moment(date).format('MM/DD/YYYY')}
                onChange={(range) => setDateRange(range)}
                parseDate={str => moment(str, 'MM/DD/YYYY').toDate()}
                value={dateRange}
                timePickerProps={undefined}
                format={'MM/DD/YYYY'}
              />

              <Checkbox
                checked={dateRange[0] === null && dateRange[1] === null}
                disabled={dateRange[0] === null && dateRange[1] === null}
                label={'All Time'}
                inline={true}
                onChange={(e) => setDateRange([null, null])}
                style={{margin: '0 0 0 12px'}}
              />
            </Col>
            <Col>
              <Checkbox
                inline
                checked={completeOnly}
                label="Completed Only"
                onChange={() => setCompleteOnly(!completeOnly)}
                style={{margin: '0 0 0 12px'}}
              />
            </Col>
          </Row>

          {/*<div style={{padding: '20px 0'}}>*/}
          {/*  <RadioGroup*/}
          {/*    label="Time Period"*/}
          {/*    onChange={(e) => setPeriod(e.target.value)}*/}
          {/*    selectedValue={period}*/}
          {/*    inline*/}
          {/*  >*/}
          {/*    <Radio label="All" value="all" />*/}
          {/*    <Radio label="Before April 1st" value="before" />*/}
          {/*    <Radio label="After April 1st" value="after" />*/}
          {/*  </RadioGroup>*/}

          {/*  <Checkbox*/}
          {/*    inline*/}
          {/*    checked={completeOnly}*/}
          {/*    label="Completed Only"*/}
          {/*    onChange={() => setCompleteOnly(!completeOnly)}*/}
          {/*  />*/}
          {/*</div>*/}

          <div style={{margin: '40px 0'}}>
            <Row>
              <Col xs>
                <Card elevation={0}>
                  <div style={{textAlign: 'center'}}>
                    <H2>{summary.total}</H2>
                    <div>Total Samples</div>
                  </div>
                </Card>
              </Col>
              <Col xs>
                <Card elevation={0}>
                <div style={{textAlign: 'center'}}>
                  <H2>{summary.totalFailed}</H2>
                  <div>Failed</div>
                </div>
                </Card>
              </Col>
              <Col xs>
                <Card elevation={0}>
                <div style={{textAlign: 'center'}}>
                  <H2>{summary.failedRate}%</H2>
                  <div>Fail Rate</div>
                </div>
                </Card>
              </Col>
              <Col xs>
                <Card elevation={0}>
                <div style={{textAlign: 'center'}}>
                  <H2>{summary.totalAneuploid}</H2>
                  <div>Aneuploid</div>
                </div>
                </Card>
              </Col>
              <Col xs>
                <Card elevation={0}>
                <div style={{textAlign: 'center'}}>
                  <H2>{summary.aneuploidyRate}%</H2>
                  <div>Aneuploidy Rate</div>
                </div>
                </Card>
              </Col>
            </Row>
          </div>

          <div style={{marginBottom: 40}}>
            {/*<ClinicPiePlots data={data} loading={loading} error={error}/>*/}
            <ClinicPlot data={data} loading={loading} error={error}/>
          </div>

          <div style={{marginBottom: 40}}>
            <ClinicList data={data} loading={loading} error={error}/>
          </div>
        </div>
      </div>
    </div>
  )
}
