import React, {useState} from 'react';
import {Colors, H2, Radio, RadioGroup} from "@blueprintjs/core";
import ClinicList from "components/Clinics/ClinicList";
import {useQuery, gql} from "@apollo/client";
import LoadingOverlay from "components/Common/LoadingOverlay";


const DATA = gql`
  query clinicSampleList($period: String!) {
    clinicSampleList(period: $period){
      id
      companyName
      samplesTotalCount
      samplesWithResultsCount
      samplesWithNoResultsCount
      samplesAneuploidCount
      samplesFailedCount
      samplesEuploidCount
    }
  }
`;


export default (props) => {
  const [period, setPeriod] = useState('all');
  const {loading, error, data} = useQuery(DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      period: period
    }
  });

  return (
    <div className="page">
      <LoadingOverlay isOpen={loading}/>

      <div className="page-header" style={{background: '#dff5e5'}}>
        <div className="container container-fluid">
          <H2 style={{color: Colors.FOREST4}}>Clinics</H2>
        </div>
      </div>

      <div className="page-body">
        <div className="container">
          <div style={{padding: '20px 0'}}>
            <RadioGroup
              label="Time Period"
              onChange={(e) => setPeriod(e.target.value)}
              selectedValue={period}
              inline
            >
              <Radio label="All" value="all" />
              <Radio label="Before April 1st" value="before" />
              <Radio label="After April 1st" value="after" />
            </RadioGroup>
          </div>

          <ClinicList data={data} loading={loading} error={error}/>
        </div>
      </div>
    </div>
  )
}
