import React, {useMemo} from 'react';
import {View, StyleSheet} from "@react-pdf/renderer";


const styles = StyleSheet.create({
  tableRow: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 0.5,
    borderBottomColor: '#000000',
    display: 'flex',
    flexDirection: 'row'
  }
});


export default ({...props}) => {
  const cells = useMemo(() => {
    const nChildren = React.Children.toArray(props.children).filter(item => !item.props.width).length;
    let predefinedWidthTotal = 0;

    React.Children.forEach(props.children, element => {
      if (element.props.width) {
        predefinedWidthTotal += element.props.width;
      }
    });

    return React.Children.map(props.children, element => {
      if (React.isValidElement(element)) {
        const predefinedWidth = element.props.width;
        const width = predefinedWidth ? predefinedWidth :
          Math.floor(((100 - predefinedWidthTotal) / nChildren) * 100) / 100;
        return React.cloneElement(element, {width: `${width}%`});
      }
      
      return element;
    })
  }, [props.children])

  return (
    <View style={styles.tableRow}>
      {cells}
    </View>
  )
}
