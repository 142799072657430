import React from 'react';
import {useParams} from "react-router-dom";
import Report from 'components/Report';
import Settings from './Settings';



export default (props) => {
  const {case_id} = useParams();

  if (!case_id) return <p>Case ID was not provided</p>
  if (case_id === 'settings') return <Settings data={{}}/>
  return <Report caseId={case_id} />
}
