import React from "react";
import {gql, useQuery} from "@apollo/client";
import ReportDocument from "components/Report/Document";
import {H2} from "@blueprintjs/core";
import composeReportLanguage from "../../pages/Report/composeReportLanguage";

const CASES = gql`
    query Case($caseId: ID!) {
        caseDetail(caseId: $caseId){
            id,
            caseId,
            patientName,
            partnerName,
            caseStatus,
            testTypes,
            clinicName,
            sampleCount,
            salivaSampleCount,
            clinicianName,
            sampleCount,
            salivaSampleCount,
            samplesReceivedAt,
            reportSigned,
            reportInfo,
            isAmended,
            blinded,
            panel,
            clinicianPhone,
            clinicianEmail,
            clinicAddress,
            testPerformedBy,
            clientReference,
            diseases,
            reportEmbryoId,
            reportEmbryoGrade,
            reportBiopsyDay,
            reportCycleNumber,
            cycleNumber,
            sampleType,
            patient {
                id,
                firstName,
                lastName,
                email,
                phone,
                address,
                addressForSsr,
                ethnicity,
                sex,
                dob,
                configReportValues
            },
            partner {
                id,
                firstName,
                lastName,
                addressForSsr,
                email,
                phone,
                address,
                ethnicity,
                sex,
                dob
            },
            samples {
                id,
                embryoNumber,
                embryoId,
                embryoGrade, 
                biopsyDay,
                sex,
                karyotype,
                aneuploid,
                complexAneuploid,
                inconclusive,
                noAmp,
                noResult,
                panel,
                tissueType,
                srStatus,
                srStatusFinal,
                mStatus,
                index,
                rebiopsyOfEmbryoNumber
            },
            salivaSamples {
                id,
                label,
                status,
                collectionDate,
                sampleType
            }
        }
        configReportBase {
            reportBase,
            analysisVersion,
            reportVersion
        }
    }
`;

export const ETHNICITIES_ALL = {
  1: 'American Indian or Alaska Native',
  2: 'Asian',
  3: 'African American',
  4: 'Native Hawaiian or Other Pacific Islander',
  5: 'Caucasian(Non-Hispanic)',
  6: 'Caucasian(Hispanic)',
  7: 'East Asian',
  8: 'South Asian',
}

export const PANEL_OPTIONS = {
  'EUR': 'Caucasian',
  'SAS': 'South Asian',
  'EAS': 'East Asian',
  'AFR': 'African',
  'AMR': 'Hispanic/Native American'
}


export const CONDITIONS = {
  'diabetes.type1': 'Type 1 Diabetes',
  'diabetes.type2': 'Type 2 Diabetes',
  'testicular': 'Testicular Cancer',
  'prostate': 'Prostate Cancer',
  'breast': 'Breast Cancer',
  'basal.cell.carcinoma': 'Basal Cell Carcinoma',
  'malignant.melanoma': 'Malignant Melanoma',
  'heart.attack': 'Heart Attack',
  'atrial.fibrillation': 'Atrial Fibrillation',
  'coronaryArteryDisease': 'Coronary Artery Disease',
  'schizophrenia': 'Schizophrenia',
  'inflammatory.bowel.disease': 'Inflammatory Bowel Disease',
  'asthma': 'Asthma'
}


export const CONDITIONS_BY_PANEL = {
  'EUR': [
    'diabetes.type1',
    'diabetes.type2',
    'testicular',
    'prostate',
    'breast',
    'basal.cell.carcinoma',
    'malignant.melanoma',
    'heart.attack',
    'coronaryArteryDisease',
    'atrial.fibrillation',
    'schizophrenia',
    'inflammatory.bowel.disease',
    'asthma'
  ],
  'SAS': [
    'diabetes.type1',
    'diabetes.type2',
    'prostate',
    'breast',
    'heart.attack',
    'coronaryArteryDisease',
    'atrial.fibrillation',
    'schizophrenia',
    'inflammatory.bowel.disease',
    'asthma'
  ],
  'EAS': [
    'diabetes.type2',
    'breast',
    'coronaryArteryDisease',
    'atrial.fibrillation',
    'inflammatory.bowel.disease',
    'asthma'
  ],
  'AFR': [
    'diabetes.type2',
    'prostate',
    'breast',
    'heart.attack',
    'coronaryArteryDisease',
    'atrial.fibrillation',
    'inflammatory.bowel.disease',
    'asthma'
  ],
  'AMR': [
    'diabetes.type2',
    'asthma'
  ]
}

/**
 * <Report> component
 *
 * @param caseId
 * @returns {JSX.Element}
 */
export default ({ caseId }) => {
  const {loading, error, data} = useQuery(CASES, {
    variables: {
      caseId: caseId,
    }
  });

  /**
   * Translate patient ethnicity enum value (e.g. 3) to three-letter value (e.g. 'AFR')
   * @param patient - patient record read via CASES gql
   * @returns {string|null} - three-letter CAPS value for patient's ethnicity enum.  NULL if enum value not found/recognized.
   */
  const calculatePanel = (patient) => {
    let res = null
    if (patient && patient.ethnicity) {
      const eth = parseInt(patient.ethnicity)

      if (eth === 3) {
        res = 'AFR'
      } else if (eth === 7) {
        res = 'EAS'
      } else if (eth === 8) {
        res = 'SAS'
      } else if (eth === 5 || eth === 6) {
        res = 'EUR'
      } else if (eth === 1) {
        res = 'AMR'
      }
    }

    return res;
  }

  /**
   * Add disclaimer language, ethnicity and disease data to case data
   *
   * disclaimer language = either:
   *    saved value from previous report
   *    otherwise, if M2, hardcoded value
   *    otherwise, base template (from configReportBase) blended with snippets specific to test type(s)
   *
   * @param {object} d - {caseDetail, configReportBase}
   *             caseDetail       = patient/case data from clinic-portal
   *             configReportBase = disclaimer base template
   * @returns {object} - {all caseDetail fields, all configReportBase fields, selectedPanel, diseases, language}
   *             selectedPanel = 3-letter ethnicity panel.  E.g. 'AFR'
   *             diseases = string array of diseases
   *             language = disclaimer language to include in report
   */
  const addDocumentData = (d) => {
    if (d.caseDetail && d.configReportBase) {
      let res = {...d.caseDetail, ...d.configReportBase};
      let savedDisclaimerLanguage = null;
      res.selectedPanel = calculatePanel(res.patient);  // 3-letter ethnicity.  E.g. 'EUR'
      res.diseases = res.selectedPanel ? CONDITIONS_BY_PANEL[res.selectedPanel].slice() : null;

      // If there are saved settings for this report ...
      if (d.caseDetail.patient.configReportValues) {
        try {
          const configReportValues = JSON.parse(d.caseDetail.patient.configReportValues);

          // [1] Do we have usable saved disclaimer language?

          if (configReportValues.disclaimerLanguage && configReportValues.disclaimerLanguage.trim()) {
            // We don't use saved disclaimer language unless test-types are same as when saved.
            // (If tests different, it's more important to start over with correct report language than preserve prior user tweaks)

            // saved disclaimer language is in format:  TEST-TYPE,TEST-TYPE,...||||DISCLAIMER-LANGUAGE
            // (but older non-production saves don't have test types included)

            // The test-type string that must match
            const targetTestTypes = d.caseDetail.testTypes.slice().sort().join(',');

            const p = configReportValues.disclaimerLanguage.indexOf('||||');
            if (p === -1) {
              // this is an older non-production save, so just assume it's ok
              savedDisclaimerLanguage = configReportValues.disclaimerLanguage;
            } else if (configReportValues.disclaimerLanguage.substring(0, p) === targetTestTypes) {
              // Case has same test types as when disclaimer last saved.  Use same disclaimer again (preserves any user tweaks).
              savedDisclaimerLanguage = configReportValues.disclaimerLanguage.substring(p + 4).trim();
            }
          }

          // [2] Saved ethnicity panel?

          res.selectedPanel = configReportValues.pgtpPanelEthnicity ? configReportValues.pgtpPanelEthnicity : null;

          // [3] Saved ReportInfo?  Pre-pend "This report is amended..." if necessary

          if (!res.reportInfo) {
            // This report not amended (i.e. not a rebiopsy).  Take any saved reportInfo as-is.
            res.reportInfo = configReportValues.reportInfo;
          } else {
            // This is an AMENDED report.  Pre-pend reportInfo with 'This report is amended ...' if it's not there already
            if (res.reportInfo.startsWith('This report is amended to include') && !configReportValues.reportInfo.startsWith('This report is amended to include'))
              res.reportInfo += '\n\n' + configReportValues.reportInfo;
            else
              res.reportInfo = configReportValues.reportInfo;
          }

          // [4] Saved diseases

          if (configReportValues.conditions) {
            const diseases = JSON.parse(configReportValues.conditions.replace(/'/g, '"'));
            if (diseases.length > 0)
              res.diseases = diseases;
          }
        } catch (e) {
          console.error('cannot parse configReportValues', e);
        }
      }

      // Set the disclaimer language

      if (savedDisclaimerLanguage) {
        // Use disclaimer language previously saved for patient (see above)
        res.language = savedDisclaimerLanguage;
      } else if (d.caseDetail.testTypes[0] === 'M2')
        res.language = '<p><strong>Result Interpretation:</strong></p><p>Genotyping for the Annexin A5 (ANXA5) M2 haplotype was performed. "Negative" indicates that the M2 haplotype was not detected. "Heterozygous Positive" indicates that one copy of the M2 haplotype was detected. "Homozygous Positive" indicates that two copies of the M2 haplotype were detected. Previous studies suggest that individuals who are positive for at least one copy of the M2 haplotype may benefit from antithrombotic intervention with low molecular weight heparin in order to achieve similar success rates as non-. carrier individuals (Fishel et al. Precision Medicine in Assisted Conception: A Multicenter Observational Treatment Cohort Study of the Annexin AS M2 Haplotype as a Biomarker for Antithrombotic Treatment to Improve Pregnancy Outcome. EBioMedicine, 2016).</p><p><br></p><p><strong>Methods and Limitations:</strong></p><p>This test determines the status of carrying the ANXA5 M2 haplotype in an individual using quantitative real-time PCR. When used on positive controls the test performs with &gt;99% accuracy. Results are in reference to the normal human genome (hg19; NG 032042.1:g.[4937G;4956A;4982T;5031G]). Positive and negative controls performed as expected. This service is an adjunct to the evaluation of the referring clinician and is designed to contribute to his or her overall assessment. Results must be interpreted in the context of clinical findings. This test was developed. and its performance characteristics determined by Genomic Prediction Clinical Laboratory. It has not been cleared or approved by the U.S. Food and Drug Administration (FDA).</p>';
      else {
        // Compose disclaimer language for this case.  Start with common base, then blend in language based on test types
        res.language = composeReportLanguage(d.configReportBase.reportBase, d.caseDetail.testTypes);
      }

      return res
    }

    return {}  // data hasn't loaded (yet?)
  }

  const errorMsgs = (data) => {
    const isM2 = data.caseDetail.testTypes.includes("M2");
    const msg = {}
    if (data.caseDetail.reportEmbryoGrade && data.caseDetail.samples.length) {
      const every = data.caseDetail.samples.every(sample => sample.embryoGrade && sample.embryoGrade.length)
      if (!every) {
        msg.reportEmbryoGrade = 'Report Embryo Grade is enabled for case. To proceed with report generation, please enter embryo grades for all samples associated with this case.'
      }
    }
    if (data.caseDetail.reportBiopsyDay && data.caseDetail.samples.length) {
      const every = data.caseDetail.samples.every(sample => sample.biopsyDay);
      if (!every) {
        msg.reportBiopsyDay = 'Report Biopsy Day is enabled for case. To proceed with report generation, please enter biopsy day for all samples associated with this case.'
      }
    }
    if (data.caseDetail.reportCycleNumber && !data.caseDetail.cycleNumber.length) {
      if (!isM2) {
        msg.reportEmbryoId = 'Show cycle number is enabled for case. To proceed with report generation, please enter cycle number in the Clinic Portal.'
      }
    }
    return msg
  };

  return (
    <div>
      <H2>Report</H2>
      {
        loading && <p>Loading...</p>
      }

      {
        error && <p>Error</p>
      }

      {
        data && !Object.keys(errorMsgs(data)).length ? (
          <div>
            <ReportDocument data={addDocumentData(data)} />
          </div>
        )
      : null}
      {
        data && Object.values(errorMsgs(data)).length ? (
          <div>
           {
             Object.values(errorMsgs(data)).map((err, i) => (
               <p key={`blockReport-${i}`}>{err}</p>
             ))
           }
          </div>
        )
      : null}
    </div>
  )
}
