import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, Classes , Button, Intent } from '@blueprintjs/core';
import { Prompt } from 'react-router';

export const RouteLeavingGuard = ({ when, shouldBlockNavigation, navigate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    
    const showDialog = (location) => {
        setIsOpen(true);
        setLastLocation(location)
    };
    const closeDialog = useCallback((cb) => {
        setIsOpen(false);
        cb()
    }, []);

    const handleBlockedNavigation = (nextLocation) =>{
        if (!confirm && shouldBlockNavigation(nextLocation)) {
            showDialog(nextLocation);
            return false;
        }
        return true;
    };
   
    useEffect(() => {
        if (lastLocation && confirm) {
            closeDialog(()=>
                navigate(lastLocation.pathname)
            );
        } 
    }, [confirm, lastLocation, closeDialog, navigate])
    
    const handleConfirmedNavigation = () => { 
        if (lastLocation) {
            setConfirm(true);
        }
    };

    return (
        <div>
            <Prompt
                when={when}
                message={handleBlockedNavigation} />
            <Dialog 
            isOpen={isOpen} 
            onClose={()=>closeDialog()}
            title="Warning: Unsaved Changes"
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>Please confirm navigation</p>
                    <p>Are you sure you want to leave the page?</p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Button intent={Intent.WARNING} style={{marginRight: '1em'}}onClick={()=>handleConfirmedNavigation()}>Yes, leave page.</Button>
                    <Button intent={Intent.NONE} style={{marginLeft: '1em'}}onClick={()=>setIsOpen(false)}>Cancel</Button>
                </div>
            </Dialog>
        </div>
    )
}

