import React from "react";
import {Image, Page, StyleSheet} from "@react-pdf/renderer";
import stripePNG from "assets/images/stripe.png";
import Sample from "components/Report/Sample";
import CaseFooter from "components/Report/CaseFooter";


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: 8,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60
  },
});


export default ({data, sample,  ...props}) => {
  return (
    <Page size="LETTER" orientation={data.layout === 1 ? 'portrait':'landscape'} style={styles.page} ruler={false} wrap>
      <CaseFooter {...data}/>

      <Image
        source={stripePNG}
        style={{
          width: 6,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0
        }}
        fixed
      />

      <Sample data={sample} showPTableForAneuploid={data.showPTableForAneuploid}/>
    </Page>
  )
}
