import React, { useMemo } from "react";
import { View, Text } from "@react-pdf/renderer";
import { PDFTable } from "components/ReactPdf";
import usePDFTable from "components/ReactPdf/Table/hooks/usePDFTable";

const GRAY_COLOR = '#ededed';

/** In header, split text if it has a colon in it (or a | in it) */
const HeaderFormat = ({value}) => {
  const res = value.split(':');
  if (res.length > 1) {
    return (
      <View>
        <Text>{res[0]}:</Text>
        <Text>{res[1]}</Text>
      </View>
    );
  }
  const splt = value.split('|');
  if (splt.length > 1) {
    return (
      <View>
        <Text>{splt[0]}</Text>
        <Text>{splt[1]}</Text>
      </View>
    );
  }
  return <Text>{value}</Text>;
}

/**
 * Cell should get gray background if sample is whole-embryo or column is one of the "optional" fields
 * @param {object} rowData - sample data for row.  Null if header row.
 * @param {string} accessor
 * @returns {boolean} - true if cell background should be gray
 */
const isGrayCell = (rowData, accessor) => {
  if (accessor === 'embryoId' || accessor === 'embryoGrade' || accessor === 'biopsyDay') return true;
  return (rowData && ('tissueType' in rowData) && rowData.tissueType === 'arrested');
}

/**
 * wrap given text string to fit given width
 *
 * Wraps on spaces, commas and end of parenthetical expressions ")"
 * e.g. show this:
 *       46,X,X,inv(7)
 *       (q22.t;q3 6.1)
 *
 * instead of this:
 *       46,X,X,inv(7)(q22
 *       .t;q3 6.1)
 *
 * @param {string} t - text string to wrap
 * @param {number} threshold
 * @returns {string}
 */
const wrapText = (t, threshold) => {
  if (!t) return '-';
  if (threshold < 2) return t;
  let result = '';
  let remainingString = t;
  while (remainingString.length > threshold - 1) {
    let p = threshold - 1;
    while (p >= 0 && remainingString[p] !== ')' && remainingString[p] !== ',' && remainingString[p] !== ' ') p--;
    if (p === -1) {
      p = threshold - 1;
      result += remainingString.substring(0, p) + '\n';
      remainingString = remainingString.substring(p);
    } else if (remainingString[p] !== ' ') {
      result += remainingString.substring(0, p+1) + '\n';
      remainingString = remainingString.substring(p+1);
    } else {
      result += remainingString.substring(0, p) + '\n';
      remainingString = remainingString.substring(p+1);
    }
  }
  result += remainingString;
  return result;
}

/** Comparison function for sorting samples by descending Embryo Health Score (EHS) */
function compareIndexes(a, b) {
  if (a.index === null || b.index === null) {
    if (a.embryoNumber < b.embryoNumber) return -1;
    if (a.embryoNumber > b.embryoNumber) return 1;
    return 0;
  }
  if (a.index > b.index) return -1;
  if (a.index < b.index) return 1;
  return 0;
}

/** Render a table of embryo samples for Report
 *
 * @param {string[]} testTypes - array of case's test types
 * @param {object[]} data - array of sample objects (either all, or euploid samples or aneuploid samples or ...)
 *                          see CASES in components/Report/index.js for sample fields
 * @param {boolean} hasCustomId - is there a sample with a custom embryo ID ?  adds column for it if true
 * @param {number} layout - 1 if portrait, 2 if landscape  [unused]
 * @param {object} reportingPreferences - Object with values for optional params {embryoId, embryoGrade, biopsyDay}
 * @param {number} cycleNumber
 * @param {any} props - other props
 * @returns {JSX.Element}
 * */
export default ({ testTypes, data, hasCustomId, layout, reportingPreferences, cycleNumber, ...props }) => {
  // If A/A+ & P exclusively (no M nor SR), sort by Embryo Health Score (EHS) descending.
  if (testTypes.includes('P') && !testTypes.includes('M') && !testTypes.includes('SR'))
    data.sort(compareIndexes);

  // optional columns
  const embryoGradeCol = {
    header: 'Grade *',
    accessor: 'embryoGrade',
    relativeWidth: 3
  }
  const biopsyDayCol = {
    header: 'Biopsy|Day *',
    accessor: 'biopsyDay',
    relativeWidth: 2
  }
  const embryoIdCol = {
    header: 'Embryo ID *',
    accessor: 'embryoId',
    relativeWidth: 3
  }
  const cycleNumberCol = {
    header: 'Cycle|Number',
    accessor: '',
    relativeWidth: 2
  }

  /** SR interpretation = srStatusFinal or srStatus or -  */
  if (testTypes.includes('SR')) {
    data = data.map((item) => {
      return {
        ...item,
        srStatus: !!item.srStatusFinal ? item.srStatusFinal : (item.srStatus || '-')
      }
    });
  }

  /** Figure out which table columns to include based on test types, etc. and lay them out */
  const tableData = useMemo(() => {
    // c = array of table columns
    //   header = text they display in header
    //   accessor = key you can use to access the column's data from sample row.
    //   relativeWidth = how wide the column is compared to other columns

    // Columns 1 and 2 are embryo# and karyotype
    const c = [
      {
        header: '#',
        accessor: 'embryoNumber',
        relativeWidth: 1
      },
      {
        header: testTypes.includes('SR') ? 'Karyotype' : 'PGT-A',
        accessor: 'karyotype',
        relativeWidth: 4
      }
    ];

    if (testTypes.includes('SR')) {
      c.push({
        header: 'Interpretation',
        accessor: 'srStatus',
        relativeWidth: 4
      });
    }

    /** Add mutation columns if M */
    const samples = [];
    if (testTypes.includes('M')) {
      data.slice().forEach((sample, sampleIndex) => {
        const mStatus = JSON.parse(sample.mStatus);
        const s = {...sample};

        if (mStatus) {
          Object.keys(mStatus).forEach((geneName, geneIndex) => {
            const geneResult = mStatus[geneName];
            Object.keys(geneResult).forEach((mutationName, mutationIndex) => {
              const mn = mutationName.replace('result', 'Interpretation');
              const accessor = `result-${geneIndex}-${mutationIndex}${mn === 'Interpretation' ? '-Interpretation' : ''}`
              if (sampleIndex === 0)
                c.push({
                  header: `${geneName}:${mn}`,
                  accessor,
                  relativeWidth: 4
                });
              s[accessor] = geneResult[mutationName];
            });
          });
        }

        samples.push(s);
      });
    }

    if (testTypes.includes('P')) {
      c.push({
        header: 'Embryo Health Score',
        accessor: 'index',
        relativeWidth: 3
      });
    }

    c.push({
      header: 'Sex',
      accessor: 'sex',
      relativeWidth: 2
    });

    /** Add columns for optional fields based on case/provider preferences */
    if (reportingPreferences) {
      const { embryoId, embryoGrade, biopsyDay } = reportingPreferences
      if (hasCustomId && embryoId && !embryoGrade) {
        c.splice(1, 0, embryoIdCol)
        if (biopsyDay) {
          c.splice(2, 0, biopsyDayCol)
        }
      }
      else if (embryoGrade && (!hasCustomId || !embryoId)) {
        c.splice(1, 0, embryoGradeCol)
        if (biopsyDay) {
          c.splice(2, 0, biopsyDayCol)
        }
      }
      else if (hasCustomId && embryoId && embryoGrade) {
        c.splice(1, 0, embryoIdCol)
        c.splice(2, 0, embryoGradeCol)
        if (biopsyDay) {
          c.splice(3, 0, biopsyDayCol)
        }
      }
      else if (biopsyDay) {
        c.splice(1, 0, biopsyDayCol)
      }
    }
    if (cycleNumber) {
      c.splice(1, 0, cycleNumberCol);
    }

    // compute how wide each column should be based on its relative width in relation to available total width
    const totalRelativeWidths = c.reduce((total, col) => {return total + col['relativeWidth']}, 0);
    const TOTAL_WIDTH = 104;
    c.forEach(col => col.width = Math.floor((col.relativeWidth * TOTAL_WIDTH)/totalRelativeWidths));

    if (testTypes.includes('M'))
      return { samples, columns: c };
    else
      return { samples: data, columns: c };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testTypes, hasCustomId, reportingPreferences, embryoGradeCol, embryoIdCol, biopsyDayCol, cycleNumberCol, cycleNumber]);

  // Transform sample data and column defs into rows of cells with values
  //    headerRows = Array of HeaderRows.  Each headerRow contains {data: {}, cell: array of cells}.
  //    rows = Array of rows - Each row contains {data: row's sample data, cell: array of cells}.
  //    Each cell is object {value, width, style, index, accessor}
  const { rows, headerRows } = usePDFTable({ data: tableData.samples, columns: tableData.columns, cycleNumber: cycleNumber });
  if (!rows) return null;

  return (
    <View>
      <PDFTable.Table>
        <PDFTable.Head>
          {
            headerRows.map((headerRow, rowNum) => (
              <PDFTable.Row key={`header-row${rowNum}`}>
                {
                  headerRow.cells.map((cell, colNum) => {
                    const keyValue = `header-row${rowNum}cel${colNum}`;
                    const cellStyle = isGrayCell(null, cell.accessor) ? { backgroundColor: GRAY_COLOR, fontWeight: 'semibold', fontSize: 6 } : {fontWeight: 'semibold', fontSize: 6};
                    return (
                      <PDFTable.Cell key={keyValue} style={cellStyle} width={cell.width}>
                        <HeaderFormat value={cell.value} />
                      </PDFTable.Cell>
                    )
                  })
                }
              </PDFTable.Row>
            ))
          }
        </PDFTable.Head>

        <PDFTable.Body>
          {
            rows.map((row, rowNum) => {
              return (
                <PDFTable.Row key={`row${rowNum}`}>
                  {
                    row.cells.map((cell, colNum) => {
                      const keyValue = `row${rowNum}cel${colNum}`;
                      const cellStyle = isGrayCell(row.data, cell.accessor) ? { backgroundColor: GRAY_COLOR } : {};
                      const textStyle = {};

                      let cellText = cell.accessor === 'karyotype' ? wrapText(cell.value, cell.width ) : cell.value;
                      if (cellText === undefined || cellText === null || cellText === '') cellText = '-';

                      if (cell.accessor === 'srStatus') {
                        if (cell.value === 'unbalanced' || cell.value === 'aneuploid' || cell.value === 'complex aneuploid')
                          textStyle.color = 'red';
                        else if (cell.value === 'normal' || cell.value === 'euploid')
                          textStyle.color = 'green';
                      }

                      if (testTypes.includes('M')) {
                        cellStyle.fontSize = 6;
                        textStyle.fontSize = 6;

                        if (cell.accessor.endsWith('-Interpretation')) {
                          textStyle.fontWeight = 'bold';
                          if (cellText.toLowerCase() === 'unaffected')
                            textStyle.color = 'green';
                          else if (cellText.toLowerCase() === 'affected')
                            textStyle.color = 'red';
                        }

                        if (cell.accessor.startsWith('result-'))
                          cellText = wrapText(cellText, cell.width);
                      }

                      return (
                        <PDFTable.Cell key={keyValue} style={cellStyle} width={cell.width}>
                          <Text style={textStyle}>{cellText}</Text>
                        </PDFTable.Cell>
                      );
                    })
                  }
                </PDFTable.Row>
              )
            })
          }
        </PDFTable.Body>
      </PDFTable.Table>
    </View>
  )
}
