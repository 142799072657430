import React, {useState} from "react";
import {Button, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import * as axios from "axios";
import {gql, useQuery} from "@apollo/client";


const DATA = gql`
    query accountToken {
        accountToken {
            value,
            expires
        }
    }
`;


export default ({type}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const {data: tokenData} = useQuery(DATA);

  const submitForm = (send_to) => {
    const getToken = () => {
      if (tokenData && tokenData.accountToken && tokenData.accountToken.value) {
        return tokenData.accountToken.value
      }

      return null
    }

    let endpoint = ''

    if (type === 'cases') {
      endpoint = `cases-csv/?send_to=${send_to}`
    }else if (type === 'kits') {
      endpoint = `kits-csv/?send_to=${send_to}`
    }

    if (getToken()) {
      axios({
        method: 'GET',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        url: endpoint,
        headers: {
          "Authorization": `Token ${getToken()}`
        }
      })
        .then((resp) => {
          // all good. do nothing
          setSuccess(true)
          setError(false)
        })
        .catch((e) => {
          setError(true)
          setSuccess(false)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div>
      {
        loading ? (
          <p>Please wait...</p>
        ) : (
          <div>
            {
              success ? (
                <div>
                  Request submitted. Wait for the email at {email}.
                </div>
              ) : (
                error ? (
                  <div>
                    Error
                  </div>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!!email) {
                        submitForm(email)
                      }
                    }}
                  >
                    <FormGroup
                      helperText="Enter your email. The file will be sent out as soon as it's ready"
                      label="Email"
                      labelFor="email"
                      labelInfo="(required)"
                    >
                      <InputGroup
                        id="email"
                        placeholder="example@genomicprediction.com"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <Button
                      onClick={() => {}}
                      intent={Intent.PRIMARY}
                      type="submit"
                    >
                      Start Export
                    </Button>
                  </form>
                )
              )
            }
          </div>
        )
      }
    </div>
  )
}
