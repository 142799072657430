/** View/Edit Report Disclaimer language
 *
 * On this page, users can view report disclaimer template for all different combinations of test types.
 * E.g. The report disclaimer language when tests include both PGT-A and PGT-SR.  Embedded is an HTML editor,
 * exposed by checking off "show editor (tech only)" that allows IT staff to configure the common base HTML that
 * shows for all types, and the locations where specialized content for a given test type (a "snippet") is inserted.
 *
 *  Base Editor - report disclaimer template html text embedded with snippet-tag placeholders.
 *         Base editor is read/writable and shows the report HTML with embedded snippet tags.
 *  Snippet - Disclaimer text (html) specific to a given test-type, which will be inserted into the base template
 *            whenever case report includes that test-type.
 *            Snippets for given type show in the Preview if selected.
 *  Snippet-tag - a plain-text tag (see below for format) which uniquely identifies where a given snippet goes
 *                in the base template.  Often just referred to as 'tag' in the code
 *  Preview - Shows what report text will look like with snippet-tags replaced with snippet content.
 *            Users use checkboxes to control which test-type snippets are showing.
 *
 *  App consists of two windows
 *       template editor - where tech user views/edits the template/snippet text.
 *                         This window is hidden by default, and is used only by tech users.
 *       preview - read-only window where user views combination of base+snippets.
 *                 This is the window all users can view or play with.
 *                 It has checkboxes controlling which test-type snippets appear.
 *                 E.g. If only PGT-M is checked, all PGT-M snippets will be added to base,
 *                 and snippet tags for PGT-SR and PGT-P won't be shown
 *
 *  See SnippetTagLib for details on test-expression logic and other snippet tag details
 * */
import React, {useEffect, useState} from 'react'
import {Button, Classes, FormGroup, H2, InputGroup} from "@blueprintjs/core";
import {gql, useMutation, useQuery} from "@apollo/client";

import "./Editor/Editor.css";
import {composeReportLanguage} from "./composeReportLanguage";


const GQL_QUERY_BASE_AND_SNIPPETS = gql`
    query configReport {
        configReportBase {
            reportBase,
            analysisVersion,
            reportVersion
        }
    }
`;

const GQL_UPDATE_REPORT_BASE = gql`
    mutation updateConfigReportBase($input: ConfigReportBaseInput!) {
        updateConfigReportBase(input: $input) {
            config {
                reportBase,
                analysisVersion,
                reportVersion
            }
        }
    }
`;


const Settings = () => {
  const [showEditor, setShowEditor] = useState(false);  // show/hide the template editor
  const [editorContent, setEditorContent] = useState(""); // html content of template editor
  const [previewContent, setPreviewContent] = useState(""); // content shown in read-only preview

  const [analysisVersion, setAnalysisVersion] = useState('')
  const [reportVersion, setReportVersion] = useState('')

  /**  Has current analysisVersion/reportVersion/HtmlContent been saved? */
  const [saved, setSaved] = useState(false)

  /** Checkboxes/radiobuttons for snippets settings in Preview window */
  const [aPlusPreview, setAPlusPreview] = useState(false); // true: show PGT-A+,  false: show PGT-A
  const [mPreview, setMPreview] = useState(false); // true: show PGT-M
  const [srPreview, setSRPreview] = useState(false); // true: show PGT-SR
  const [pPreview, setPPreview] = useState(false); // true, show PGT-P

  /**
  * Add newlines after HTML end-tags in editor content.
  * Newlines are added to template content to improve readability.
  * The newlines don't affect preview display (HTML parsing).
  * They are removed with removeNewlinesFromTemplate before saving.
  *
  * @param templateText {string} html base content of template editor
  * @return {string}  content with aforementioned formatting inserted
  */
  const addNewlinesToTemplate = (templateText) => {
    let resultString = '';
    let s = templateText;
    while (s) {
      const matchArray = s.match(/^(.*?)(<\/([a-z0-9]+)>)(.*)$/);
      if (matchArray) {
        resultString += matchArray[1];
        const endTagName = matchArray[3].toLowerCase();
        if (endTagName === 'p' || endTagName === 'h3' || endTagName === 'li') {
          resultString += matchArray[2] + "\n";
        } else {
          resultString += matchArray[2];
        }
        s = matchArray[4];
      } else {
        resultString += s;
        s = '';
      }
    }
    return resultString;
  }

  /**
   * Remove newlines from template editor text (collapse content)
   * See addNewlinesToTemplate, above.
   *
   * @param templateText {string} html base content
   * @return {string}  content with aforementioned formatting removed
   */
  const removeNewlinesFromTemplate = templateText => templateText.replaceAll("\n", "");


  /** Read data from configReportBase table */
  const {loading, error, data} = useQuery(GQL_QUERY_BASE_AND_SNIPPETS, {
    fetchPolicy: "network-only"   // don't check cache
  });

  /** When updateReportBase called, write back data to configReportBase table */
  const [updateReportBase, {loading: updateReportTemplateLoading, error: updateReportTemplateError}] =
    useMutation(GQL_UPDATE_REPORT_BASE, {
      refetchQueries: [{
        query: GQL_QUERY_BASE_AND_SNIPPETS
      }]
    });

  /** Load template editor after data loaded from db */
  useEffect(() => {
    if (!loading && data && data.configReportBase) {
      setEditorContent(addNewlinesToTemplate(data.configReportBase.reportBase));
      setAnalysisVersion(data.configReportBase.analysisVersion)
      setReportVersion(data.configReportBase.reportVersion)
    }
  }, [data, loading]);


  /**
   * Update Preview content when editor content changes or user clicks a Preview radiobutton/checkbox
   * */
  useEffect(() => {
    let previewTestTypes = [aPlusPreview ? 'A+' : 'A'];
    if (mPreview) previewTestTypes.push('M');
    if (srPreview) previewTestTypes.push('SR');
    if (pPreview) previewTestTypes.push('P');

    setPreviewContent(composeReportLanguage(removeNewlinesFromTemplate(editorContent), previewTestTypes));
  }, [editorContent, aPlusPreview, mPreview, srPreview, pPreview]);  // eslint-disable-line

  /**
   * Save (and log) when user clicks Save button
   */
  const saveData = () => {
    setSaved(false)

    const reportBase = removeNewlinesFromTemplate(editorContent);
    if (reportBase !== data.configReportBase.reportBase
        || analysisVersion !== data.analysisVersion
        || reportVersion !== data.reportVersion)
    updateReportBase({
      variables: {
        input: {
          reportBase,
          analysisVersion,
          reportVersion
        }
    }})
    .then(() => {
      setSaved(true)
      setTimeout(() => {
        setSaved(false)
      }, 5000)
    })
  }

  if (loading) return <p>Loading...</p>      // configReport tables still loading
  if (error) return <p>Error: {error}</p>    // error loading configReport tables
  if (!data) return null                     // configReport tables loaded, but empty.  What??

  /** Handlers for preview-window checkboxes */
  const toggleAPlusPreview = () => setAPlusPreview(v => !v);
  const toggleMPreview = () => setMPreview(v => !v);
  const toggleSRPreview = () => setSRPreview(v => !v);
  const togglePPreview = () => setPPreview(v => !v);

  //
  // Content change handlers
  //

  const handleTemplateChange = (event) => {
    setEditorContent(event.target.value);
  }

  // configReport tables loaded.  Let's render.
  return (
    <>
      <div style={{
        maxWidth: 640,
        margin: '30px auto 0 auto'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div>
            <H2>Settings</H2>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}>
            <div style={{paddingLeft: 12}}>
              <Button onClick={() => saveData()} loading={updateReportTemplateLoading}
                      intent={'success'}>Save</Button>
            </div>
            <div style={{paddingLeft: 12}}>
              {saved && <span className={Classes.TEXT_MUTED}>Saved</span>}
              {updateReportTemplateError && <p>Error saving settings: {updateReportTemplateError.message}</p>}
            </div>
          </div>
        </div>
        <div>
          <FormGroup
            label="Analysis Version"
            labelFor="analysisVersion"
            inline
          >
            <InputGroup
              id="analysisVersion"
              value={analysisVersion}
              onChange={(e) => setAnalysisVersion(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Report Version"
            labelFor="reportVersion"
            inline
          >
            <InputGroup
              id="reportVersion"
              value={reportVersion}
              onChange={(e) => setReportVersion(e.target.value)}
            />
          </FormGroup>
        </div>
        <div>
          <label><input type="checkbox" onClick={()=>setShowEditor(v => !v)} /> Show Editor (tech only)</label>
        </div>
      </div>

      <div style={{backgroundColor: "#f5f8fa", display: "flex"}}>
        {showEditor ?
        <div style={{flexGrow: 1, marginTop: "30px", }}>
          <div style={{paddingTop: "10px", margin: "0 5px 0 5px"}}>
            HTML Template Editor (advanced users only)
          </div>
          <textarea value={editorContent} style={{width: "99%", marginTop: "4px", height: "3000px"}}
                    onChange={handleTemplateChange}/>
        </div>
          : null
        }
        <div style={{width: "980px", marginTop: "30px", marginLeft: "auto", marginRight: "auto"}}>
          <div style={{width: "900px", margin: "10px auto 0 auto", textAlign: "left"}}>
            Preview
            <div style={{display: "inline-block", marginLeft: "70px"}} onChange={toggleAPlusPreview}>
              Show with Tests:&nbsp;&nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  name="apluspreview"
                  checked={aPlusPreview === false}
                />
                {' '}
                Pgt-A
              </label>
              <label style={{marginLeft: "8px"}}>
                <input
                  type="radio"
                  name="apluspreview"
                  checked={aPlusPreview === true}
                />
                {' '}
                Pgt-A+
              </label>
            </div>
            <div style={{display: "inline-block", marginLeft: "30px"}}>
              <label>
                <input
                  type="checkbox"
                  checked={mPreview === true}
                  onChange={toggleMPreview}
                />
                {' '}
                Pgt-M
              </label>
              <label style={{marginLeft: "8px"}}>
                <input
                  type="checkbox"
                  checked={srPreview === true}
                  onChange={toggleSRPreview}
                />
                {' '}
                Pgt-SR
              </label>
              <label style={{marginLeft: "8px"}}>
                <input
                  type="checkbox"
                  checked={pPreview === true}
                  onChange={togglePPreview}
                />
                {' '}
                Pgt-P
              </label>
            </div>
          </div>
          <div className="preview-container" style={{height: "3000px"}}>
            <div dangerouslySetInnerHTML={{__html: previewContent}} style={{textAlign: "left"}}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings;
