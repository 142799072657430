import React, {useMemo} from 'react';
import {View, Text} from "@react-pdf/renderer";
import CircosIndexContent from "components/Report/Sample/CircosIndexContent";
import PanelTable from "components/Report/Sample/PanelTable";
import CircosPlot from "components/Report/Sample/CircosPlot";



export default ({data, showPTableForAneuploid, ...props}) => {
  data = useMemo(() => {
    let panel = [];

    if (showPTableForAneuploid) {
      panel = []
    }

    try{
      panel = JSON.parse(data.panel);

      if (data.allowedConditions) {
        panel = panel.filter((item) => data.allowedConditions.includes(item.name))
      }

      if (data.diseases) {
        panel = panel.filter((item) => data.diseases.includes(item.name))
      }
    } catch (e) {
      console.log('data panel', data.panel)
      console.log(e)
    }

    return {
      ...data,
      panel: panel
    }
  }, [data, showPTableForAneuploid]);

  const {
    embryoNumber,
    sex,
    pgt_a
  } = data

  return (
    <View>
      <Text break/>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          marginBottom: 20,
          marginRight: 60,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            textTransform: 'capitalize'
          }}
        >
          <Text style={{fontSize: 12, fontStyle: 'normal', fontWeight: 'semibold'}}>Embryo #{embryoNumber}</Text>
          <View style={{marginTop: 10}}>
            <Text style={{fontSize: 24, fontStyle: 'normal', fontWeight: 'semibold'}}>{data.pgt_a}</Text>
            <Text style={{fontSize: 24, fontStyle: 'normal', fontWeight: 'semibold'}}>{sex}</Text>
          </View>
        </View>

        <View
          style={{
            width: 190,
            height: 190,
            borderRadius: 999,
            position: 'relative'
          }}
        >
          <CircosPlot data={data} width={190} height={190} />
          <CircosIndexContent data={data} width={190} height={190} />
        </View>
      </View>

      {
        (pgt_a === 'euploid' || showPTableForAneuploid) && (
          <View style={{marginTop: 20}}>
            <PanelTable data={data} hideData={pgt_a !== 'euploid'} />
          </View>
        )
      }
    </View>
  )
}
