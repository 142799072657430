import React from 'react';
import CaseListItem from "components/Home/CaseListItem";
import {gql, useQuery} from "@apollo/client";

const GET_FLOWS = gql`
    query flowList {
        flows {
            id
            title
            description
            isTemplate
            createdAt
            isSubFlow
            template {
                id
            }
        }
    }
`;

export default ({items, flows}) => {
  const {loading: flowsLoading, error: flowsError, data: flowsData} = useQuery(GET_FLOWS);

  if (flowsError) return <div>Error: {flowsError}</div>
  return (
    <div>
      {flowsLoading && <p>Loading...</p>}
      {
        items.map((item, i) => {
          return (
            <CaseListItem flows={flowsData ? flowsData.flows : []} key={'case-' + i} {...item} />
          )
        })
      }
    </div>
  )
}
