import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import AddAddress from './AddAdress';
import { HTMLSelect, RadioGroup, Button, Radio, FormGroup, InputGroup } from '@blueprintjs/core';
import { UPDATE_CLINIC, ADD_NOTE } from './Helpers/gql';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { ClinicFormToaster } from './Toaster';
import EditSwitch from './EditSwitch';
import { FormContext } from './Context';
import { countries } from './CountryList';
import './styles.css';

const states = ['Select state', 'Alabama','Alaska', 'Arizona', 'Arkansas', 
'California', 'Colorado', 'Connecticut', 'Delaware', 
'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 
'Indiana','Iowa', 'Kansas', 'Kentucky', 'Louisiana', 
'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
'Minnesota', 'Mississippi', 'Missouri', 'Montana', 
'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 
'New Mexico', 'New York', 'North Carolina', 'North Dakota', 
'Ohio', 'Oklahoma','Oregon', 'Pennsylvania', 'Rhode Island', 
'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 
'Utah', 'Vermont', 'Virginia', 'Washington', 
'West Virginia', 'Wisconsin', 'Wyoming', 'Other'];


const Shipping = ({ id, data }) => {
    let currentLocation = useLocation();
    let history = useHistory();

    const [state, dispatch] = useContext(FormContext);
    const [values, setValues] = useState({
        only: "No",
        preference: "",
        urgency: "",
        notes: "",
        address1: "",
        address2: "",
        city: "",
        stateUs: "",
        provinceOther: "",
        postal: "",
        country: ""
    });

    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
        // refetchQueries: [
        //     {
        //         query: CLINIC_DETAIL, 
        //         variables: {
        //             id: id
        //         }
        //     }    
        // ],
        onCompleted(clinicData){
            const currentShipping = data.clinicDetail.shipping
            const updatedShipping = clinicData.updateClinic.clinic.shipping
  
              console.log(currentShipping, updatedShipping)
  
              if ((updatedShipping.length - 1) === currentShipping.length && values.notes.length) {
                  console.log('true')
                addNote({
                  variables: {
                    clinicId: id,
                    type: `shipping_notes_${(updatedShipping.length - 1)}`,
                    text: values.notes
                  }
                })
              }
            showToast();
            dispatch({
                 type: "RESET_DIRTY_FIELDS"
             });
             window.scrollTo(0, 0);
          }
    });
    const [addNote, { loading: addLoading, error: addError }] = useMutation(ADD_NOTE, {
        onCompleted(noteData) {
            if (noteData)  {
                showToast();
                dispatch({
                    type: "RESET_DIRTY_FIELDS"
                });
                window.scrollTo(0, 0);
             }
            }
    });

    useEffect(() => {
        if (data) {
            setValues({
                only: "No",
                preference: "",
                urgency: "",
                notes: "",
                address1: "",
                address2: "",
                city: "",
                stateUs: "",
                provinceOther: "",
                postal: "",
                country: ""
            })
        }
    }, [data])
    const options = states.map((state, index)=> {
        return <option value={state} key={`state-${index+1}`}>{state}</option>
    });
        
    const handleChange = (e) => {
        const { value, name } = e.target;
        console.log('value', value, 'name', name);
        setValues({...values, [name]: value});
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        let newShippingData = data.clinicDetail.shipping.map((address) => {
            const res = {}
            Object.keys(address).filter(key => key !== 'notes').forEach((key) => {
                res[key] = address[key]
            })
            return res
        })
        console.log(newShippingData)

        updateClinic({
            variables: {
                id: id, 
                input: {
                    shipping: [
                        ...newShippingData,
                        {
                        preference: values.preference,
                        urgency: values.urgency,
                        address: {
                            address1: values.address1,
                            address2: values.address2,
                            city: values.city,
                            stateUs: values.stateUs,
                            provinceOther: values.provinceOther,
                            postal: values.postal,
                            country: values.country
                        }
                    }]
                }
            }
        });
    };


    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };
   
    
    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <>
                {updateError && <p>Error updating clinic: {updateError.message} </p>}
                {addError && <p>Error adding note: {addError.message}</p>}
                    <EditSwitch />
                    <RouteLeavingGuard 
                        when={Object.keys(state.dirtyFields).length > 0}
                        navigate={path=>history.push(path)}
                        shouldBlockNavigation={location=>{
                            if (Object.keys(state.dirtyFields).length > 0) {
                                if (location.pathname !== currentLocation.pathname) {
                                    return true
                                }
                            }
                            return false
                        }}
                    />
                </>
                <h1 className="form-header">Shipping:</h1>
                    <RadioGroup
                    disabled={!state.isEditing}
                    label="Is shipping address the same as clinic main address?"
                    id="single-location"
                    name="only"
                    selectedValue={values.only}
                    onChange={handleChange}
                    
                    >
                        <Radio label="Yes" value="Yes" />
                        <Radio label="No" value="No" />
                    </RadioGroup>
                    {
                        values.only === "Yes" &&
                        <>
                            <FormGroup 
                                labelFor="Shipping"
                                label="Indicate Shipping Preference:"
                            >
                                <InputGroup readOnly={!state.isEditing} id="Shipping" type="text" name="preference" value={values.preference} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup
                                labelFor="Priority"
                                label="Indicate Urgency:"
                            >
                                <InputGroup readOnly={!state.isEditing} id="urgency" type="text" name="urgency" value={values.urgency} onChange={handleChange}   />
                            </FormGroup>
                            {/* <HTMLSelect
                                label="Carrier:"
                                id="carrier"
                                name="carrier"
                                value={values.carrier}
                                onChange={handleChange}
                            >
                                {carriers}
                            </HTMLSelect>
                            {values.carrier === "Other" && 
                            <FormGroup 
                                label="Other carrier:"
                                labelFor="carrier-other"
                            >
                                <InputGroup id="carrier-other" type="text" name="carrierOther" value={values.carrierOther} onChange={handleChange} />
                            </FormGroup>
                            } */}
                            <div className="buttonContainer">
                                <Button type="submit">Save Preferences</Button>
                            </div>
                        </>
                    }
                {(data.clinicDetail.shipping.length || values.only === "No") && 
                <div>
                    <FormGroup 
                    labelFor="Shipping"
                    label="Indicate Shipping Preference:"
                    >
                        <InputGroup readOnly={!state.isEditing} id="Shipping" type="text" name="preference" value={values.preference} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup
                        labelFor="Priority"
                        label="Indicate Urgency:"
                    >
                        <InputGroup readOnly={!state.isEditing} id="urgency" type="text" name="urgency" value={values.urgency} onChange={handleChange} />
                    </FormGroup>
                    {/* <HTMLSelect
                        label="Carrier:"
                        id="carrier"
                        name="carrier"
                        value={values.carrier}
                        onChange={handleChange}
                    >
                        {carriers}
                    </HTMLSelect>
                    {values.carrier === "Other" && 
                    <FormGroup 
                        label="Other carrier:"
                        labelFor="carrierOther"
                    >
                        <InputGroup id="carrierOther" name="carrierOther" type="text" value={values.carrierOther} onChange={handleChange} />
                    </FormGroup>
                    } */}
                    <FormGroup
                        label="Address:"
                        labelFor="Clinic Address 1"
                        labelInfo="(Line 1)"
                    >
                        <InputGroup readOnly={!state.isEditing} id="Clinic Address 1" name="address1" type="text" value={values.address1} onChange={handleChange}  />
                    </FormGroup>
                    <FormGroup
                            labelFor="Clinic Address 2"
                            labelInfo="(Line 2)"
                    >
                            <InputGroup readOnly={!state.isEditing} id="Clinic Address 2" type="text" name="address2" value={values.address2} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup
                            label="City:"
                            labelFor="Clinic City"
                    >
                            <InputGroup readOnly={!state.isEditing} id="Clinic City" type="text" name="city" value={values.city} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup
                        labelFor="Clinic State"
                        label="State or Province"
                    >
                            <HTMLSelect 
                            id="stateUs"
                            disabled={!state.isEditing}
                            className="stateSelect"
                            name="stateUs"
                            value={values.stateUs}
                            onChange={handleChange}
                            
                        >
                            {options}
                        </HTMLSelect>
                    </FormGroup>
                    {
                        values.stateUs === 'Other' && 
                        <FormGroup
                            label="Enter Province:"
                            labelFor="provinceOther"
                        >
                            <InputGroup readOnly={!state.isEditing} id="provinceOther" name="provinceOther" type="text" value={values.provinceOther} onChange={handleChange} />
                        </FormGroup>
                    }
                    <FormGroup 
                    labelFor="country"
                    label="Country:"
                    >
                        <HTMLSelect
                            disabled={!state.isEditing}
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                        >
                            {countries}
                        </HTMLSelect>
                    </FormGroup> 
                    <FormGroup
                        label="ZIP/Postal Code:"
                        labelFor="Clinic ZIP"
                    >
                        <InputGroup 
                            readOnly={!state.isEditing}
                            id="Clinic ZIP" 
                            type="text"
                            name="postal"
                            value={values.postal}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    {/* <p>Notes:</p>
                    <TextArea
                        readOnly={!state.isEditing}
                        id="notes"
                        name="notes"
                        value={values.notes}
                        growVertically={true}
                        onChange={handleChange}
                    /> */}
                    <div className="buttonContainer">
                        <Button intent="primary" type="submit" text="Save Address" disabled={updateLoading || addLoading}></Button>
                    </div>
                    {
                        (data && data.clinicDetail.shipping.length) ?
                        <AddAddress options={options} countries={countries} data={data} id={id} isEditable={state.isEditing}/>
                    : null }
                    
                </div>
                }
            </form>
        </div>
    )
};

export default Shipping;
