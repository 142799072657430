import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import usePDFTable from "components/ReactPdf/Table/hooks/usePDFTable";
import {PDFTable} from "components/ReactPdf";


const PanelTable = ({data, hideData}) => {
  const {panel} = data;

  // console.log('DATA PANEL ====', data)

  // risk: "normal"
  // risk_avg: 0.4
  // risk_percentile: 39
  // risk_ratio: 0.7
  // risk_value: 0.28

  const columns = [
    {
      header: '',
      accessor: 'title',
      width: 34
    },
    {
      header: 'Risk',
      accessor: 'risk_value',
    },
    {
      header: 'Avg Risk',
      accessor: 'risk_avg',
    },
    {
      header: 'Ratio',
      accessor: 'risk_ratio',
    },
    {
      header: 'Risk Percentile',
      accessor: 'risk_percentile',
    }
  ]

  const {rows, headerRows} = usePDFTable({data: panel, columns: columns});

const renderCellValue = (value, accessor) => {
    let res = value
      if (accessor === 'risk_percentile') {
        if (value === 0) {
          res = '<1'
        }
        if (value === 100) {
          res = '>99'
        }
      }
    return res
  }


  return (
    <View style={{
      fontSize: 10
    }}>
      <PDFTable.Table>
        <PDFTable.Head>
          {
            headerRows.map((headerRow, i) => (
              <PDFTable.Row key={'header-row'+i}>
                {
                  headerRow.cells.map((cell, j) => {
                    return (
                      <PDFTable.Cell key={'header-row'+i+'cel'+j} width={cell.width}>
                        <Text
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 9,
                            paddingVertical: 3,
                            textAlign: cell.accessor === 'risk_percentile' ? 'right' : 'left',
                            //textAlign: 'right'
                          }}
                        >
                          {cell.value}
                        </Text>
                      </PDFTable.Cell>
                    )
                  })
                }
              </PDFTable.Row>
            ))
          }
        </PDFTable.Head>

        <PDFTable.Body>
          {
            rows.map((row, i) => {
              return (
                <PDFTable.Row key={'row'+i}>
                  {
                    row.cells.map((cell, j) => {
                      return (
                        <PDFTable.Cell key={'row'+i+'cel'+j} width={cell.width}>
                          {
                            (hideData && cell.accessor !== 'title') ? (
                              <Text>-</Text>
                            ) : (
                              <Text
                                style={{
                                  paddingVertical: 4,
                                  //fontStyle: cell.index === 0 ? 'bold' : 'normal',
                                  fontFamily: cell.index !== 0 ? 'RobotoMono' : 'Poppins',
                                  textAlign: cell.accessor === 'risk_percentile' ? 'right' : 'left',
                                  //textAlign: 'right'
                                }}
                              >
                                {(cell.value !== null) ? renderCellValue(cell.value, cell.accessor) : '-'}
                                {cell.accessor === 'risk_ratio' && 'x'}
                                {cell.accessor === 'risk_value' && '%'}
                                {cell.accessor === 'risk_avg' && '%'}
                              </Text>
                            )
                          }
                        </PDFTable.Cell>
                      )
                    })
                  }
                </PDFTable.Row>
              )
            })
          }
        </PDFTable.Body>
      </PDFTable.Table>
    </View>
  )
}

export default PanelTable;
