import React, {useState, useEffect} from 'react';
import {Card, Elevation, Button, Classes, Checkbox, Dialog} from "@blueprintjs/core";
import {Row, Col} from "react-flexbox-grid";
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import {useQuery, gql} from "@apollo/client";
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import Avarage from './Avarage';


const PLOT_COLORS_TESTS = {
  'A': 'hsl(192,82%,85%)',
  'M': 'hsl(263,71%,78%)',
  'P': 'hsl(334,75%,73%)',
  'SR': 'hsl(32,100%,81%)',
  'M2': 'hsl(107,74%,79%)'
}


const CASES_SUMMARY = gql`
  query CasesSummary($filterCommand: String, $timeFrame: [DateTime]) {
    caseTatSummary(filterCommand: $filterCommand, timeFrame: $timeFrame) {
      a,
      m,
      p,
      sr,
      m2,
      timeline{
        id,
        data {
          x,
          y,
          caseId,
          portalUrl
        }
      }
    }
  }
`;


export default (props) => {
  const [filterByType, setFilterByType] = useState(['A', 'M', 'P', 'SR', 'M2']);
  //const [filterByType, setFilterByType] = useState(['M']);
  const [initialTimeline, setInitialTimeline] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [selectedCases, setSelectedCases] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('Cases');
  const [filterCommand, setFilterCommand] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [toolTipData, setToolTipData] = useState({});

  const {loading, error, data} = useQuery(CASES_SUMMARY, {
    //fetchPolicy: 'no-cache',
    variables: {
      filterCommand: filterCommand,
      timeFrame: dateRange
    }
  });

  useEffect(() => {
    if (data) {
      setTimeline(data.caseTatSummary.timeline);
      setInitialTimeline(data.caseTatSummary.timeline);
    }
  }, [data]);

  useEffect(() => {
    const res = {}

    timeline.forEach((item) => {
      item.data.forEach((dataItem) => {
        const key = dataItem.y + '-' + dataItem.x

        if (!(key in res)) {
          res[key] = []
        }

        res[key].push({
          title: dataItem.caseId,
          link: dataItem.portalUrl,
          type: item.id
        })
      })
    })

    setToolTipData(res)
  }, [timeline])

  useEffect(() => {
    setTimeline((prevState) => {
      return initialTimeline.filter((item) => filterByType.includes(item.id))
    })
  }, [filterByType, initialTimeline])

  const commonProps = {
    // margin: { top: 10, right: 12, bottom: 80, left: 30 },
    nodeSize: 12,
    blendMode: 'multiply',
    xFormat: d => `${d}`,
    //yFormat: d => `${d}`,
    data: [],
    legends: [
      {
        anchor: 'bottom-left',
        direction: 'row',
        translateY: 50,
        itemWidth: 130,
        itemHeight: 12,
        symbolSize: 12,
        symbolShape: 'circle',
      },
    ],
  }

  const updateFilter = (type, checked) => {
    const arr = filterByType.slice()
    const index = arr.indexOf(type)
    if (checked && index < 0) {
      arr.push(type)
    } else if (!checked && index >= 0) {
      arr.splice(index, 1);
    }

    if (arr.length > 0) {
      setFilterByType(arr);
    }
  }


  const generateTooltip = ({node}) => {

    if (node && node.data) {
      const key = node.data.formattedY + '-' + node.data.formattedX
      const sameItems = toolTipData[key]

      if (!!sameItems && sameItems.length > 0) {
        return (
          <div
            style={{
              color: '#ffffff',
              background: '#333333',
              padding: '12px 16px',
            }}
          >
            <div style={{marginBottom: 12}}>
              {`Date: ${node.data.formattedX}`}
              <br />
              {`TAT: ${node.data.formattedY}`}
            </div>
            <div>
              {
                sameItems.map((item, index) => {
                  return (
                    <div key={'tooltip-case-' + index}>
                      {item.title} ({item.type})
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    }

    return null;
  }
  if (error) return <div>Error: {error}</div>
  return (
    <div style={{marginBottom: 40}}>

      <Row>
        <Col xs={12}>
          <Card elevation={Elevation.TWO}>
            <div style={{margin: '12px 0'}}>
              <Row start="xs" middle="xs">
                <Col xs={5}>
                  <DateRangeInput
                    formatDate={date => moment(date).format('MM/DD/YYYY')}
                    onChange={(range) => setDateRange(range)}
                    parseDate={str => moment(str, 'MM/DD/YYYY').toDate()}
                    value={dateRange}
                    timePickerProps={undefined}
                    format={'MM/DD/YYYY'}
                  />

                  <Checkbox
                    checked={dateRange[0] === null && dateRange[1] === null}
                    disabled={dateRange[0] === null && dateRange[1] === null}
                    label={'All Time'}
                    inline={true}
                    onChange={(e) => setDateRange([null, null])}
                    style={{margin: '0 0 0 12px'}}
                  />
                </Col>

                <Col xs={2}>
                  <Checkbox
                    checked={filterCommand === 'exclude_awaiting_billing'}
                    label={'Payed Only'}
                    inline={true}
                    onChange={(e) => setFilterCommand(e.target.checked ? 'exclude_awaiting_billing' : null)}
                    style={{margin: 0}}
                  />
                </Col>

                <Col xs>
                  <Checkbox
                    checked={filterByType.includes('A')}
                    label="A"
                    inline={true}
                    onChange={(e) => updateFilter('A', e.target.checked)}
                    style={{margin: '0 12px 0 0'}}
                  />
                  <Checkbox
                    checked={filterByType.includes('M')}
                    label="M"
                    inline={true}
                    onChange={(e) => updateFilter('M', e.target.checked)}
                    style={{margin: '0 12px 0 0'}}
                  />
                  <Checkbox
                    checked={filterByType.includes('P')}
                    label="P"
                    inline={true}
                    onChange={(e) => updateFilter('P', e.target.checked)}
                    style={{margin: '0 12px 0 0'}}
                  />
                  <Checkbox
                    checked={filterByType.includes('SR')}
                    label="SR"
                    inline={true}
                    onChange={(e) => updateFilter('SR', e.target.checked)}
                    style={{margin: '0 12px 0 0'}}
                  />
                  <Checkbox
                    checked={filterByType.includes('M2')}
                    label="M2"
                    inline={true}
                    onChange={(e) => updateFilter('M2', e.target.checked)}
                    style={{margin: '0 12px 0 0'}}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col xs={12}>
                  <div style={{height: 400}}>
                    {
                      loading ? (
                        <div>Loading ...</div>
                      ) : (
                        <ResponsiveScatterPlot
                          {...commonProps}
                          margin={{ top: 10, right: 12, bottom: 80, left: 30 }}
                          data={timeline}
                          xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            precision: 'day',
                          }}
                          xFormat="time:%Y-%m-%d"
                          gridYValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                          axisLeft={{
                            tickValues: [7, 14, 21]
                          }}
                          axisBottom={{
                            format: '%b %Y',
                            tickValues: 'every 360 days',
                          }}

                          tooltip={generateTooltip}
                          onClick={(node, e) => {
                            const key = node.data.formattedY + '-' + node.data.formattedX
                            const sameItems = toolTipData[key]
                            let title = 'Cases';
                            const month_parts = node.data.formattedX.split('/');
                            month_parts.splice(0, 1)

                            title = `Cases for: ${node.data.formattedX} (TAT: ${node.data.formattedY})`;

                            setDialogTitle(title)
                            setSelectedCases(sameItems);
                          }}

                          colors={node => {
                            if (!filterByType.includes(node.serieId)) {
                              return 'transparent';
                            }

                            return PLOT_COLORS_TESTS[node.serieId]
                          }}
                        />
                      )
                    }
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Avarage data={data} loading={loading} />
            </div>

          </Card>
        </Col>
      </Row>

      <Dialog
        icon="info-sign"
        onClose={() => setSelectedCases(null)}
        title={dialogTitle}
        isOpen={!!selectedCases}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            !!selectedCases && selectedCases.map((item, index) => {
              return (
                <p key={'case-item-' + index}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">{item.title} ({item.type})</a>
                </p>
              )
            })
          }
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setSelectedCases(null)}>Close</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
