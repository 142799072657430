import React, {FunctionComponent} from 'react'
import {Icon, Intent} from "@blueprintjs/core";


type ComponentProps = {
  isRequired: boolean
}


const RequiredIndicator: FunctionComponent<ComponentProps> = ({isRequired}) => {
  return isRequired ? (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 6,
        opacity: 0.4
      }}
    >
      <Icon icon="asterisk" intent={Intent.NONE} iconSize={10}/>
    </div>
  ) : null
}


export default RequiredIndicator
