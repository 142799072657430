import React, {FunctionComponent} from 'react'
import {gql, useQuery, useMutation } from "@apollo/client";
import {Button, HTMLSelect, HTMLTable, Intent} from "@blueprintjs/core";
import {Link, useHistory } from "react-router-dom";
import ValidateStatusChange from './ValidateStatusChange';
import { FormContext } from './Context';
import { PROVIDERS } from './Helpers/gql';
import SendCSV from '../../components/NewClinicForm/SendCSV';
import './styles.css';


const CREATE_CLINIC = gql`
    mutation createClinic($input: ClinicData!) {
        createClinic(input: $input) {
            clinic {
                id,
                name,
                isDistributor
            }
        }
    }
`;

const statuses = ['Select Status', 'In Progress', 'Converted', 'In Review', 'Archived'];
const options = statuses.map((status, i) => {
  return <option value={status} label={status} key={`status-${i + 1}`}>{status}</option>
});


const ClinicList: FunctionComponent<{ initialExportOpen: boolean, initialOpen: boolean, updatedStatus: string, initialClinicId: number }> = ({ initialOpen=false, initialExportOpen=false,  updatedStatus="", initialClinicId=null}) => {
  const {loading, error, data} = useQuery(PROVIDERS);
  const history = useHistory<any>();
  const [isOpen, setIsOpen] = React.useState(initialOpen);
  const [exportIsOpen, setExportIsOpen] = React.useState(initialExportOpen)
  const [newStatus, setNewStatus] = React.useState(updatedStatus);
  const [clinicId, setClinicId] = React.useState(initialClinicId);
  const [state, dispatch] = React.useContext(FormContext);


  const [createClinic, {loading: createClinicLoading, error: createClinicError }] = useMutation(CREATE_CLINIC, {
    onCompleted: (data) => {
      if (data) {
        if (!state.isEditing) {
          dispatch({
            type: "SET_EDITING"
          })
        }
        history.push(`/clinicform/${data.createClinic.clinic.id}/account`)
      }
    } 
  });

  const confirmStatusChange = (value: string, id: number) => {
    setNewStatus(value);
    setClinicId(id);
    setIsOpen(!isOpen);
  };
  
  if (error || createClinicError) return <p>Error: {error || createClinicError}</p>
  if (loading) return <p>Loading...</p>

  return (
    <div>
       {isOpen && <ValidateStatusChange isOpen={isOpen} setIsOpen={setIsOpen} newStatus={newStatus} clinicId={clinicId}/> }
        <div className="clinicList-header-btn">
          <Button disabled={createClinicLoading} onClick={()=>createClinic({variables: {input: {name: ""}}})} intent={Intent.SUCCESS}>+ New Clinic</Button>
          <Button onClick={()=>{setExportIsOpen(true)}} intent={Intent.PRIMARY}>Export Clinic Form Data</Button>
            {exportIsOpen && <SendCSV isOpen={exportIsOpen} setIsOpen={setExportIsOpen} />}
        </div>  
      <div className="clinic-table-container">
        <HTMLTable className="test">
          <thead>
          <tr>
            <th className="clinicPageTH">Clinic</th>
            <th className="clinicPageTH">Status</th>
            <th className="clinicPageTH">Type</th>
            <th className="clinicPageTH">Assigned to Distributor:</th>
          </tr>
          </thead>
          <tbody>
          {data && data.clinicList.map((item: any, index: number) => (
            <tr className="clinicPageTR" key={`tablerow-${index + 1}`}>
              <td
                className="clinic-table-name"
                id={item.id}
              >
                <Link to={`/clinicform/${item.id}/account/`}>{item.name}</Link>
              </td>
              <td
                className="clinic-table-status"
              >
                <HTMLSelect
                value={item.accountStatus.currentStatus}
                onChange={e => confirmStatusChange(e.currentTarget.value, item.id)}
                >
                  {options}
                </HTMLSelect>
              </td>
              <td className="clinic-table-type">{item.isDistributor ? "Distributor" : "Standalone Clinic"}</td>
              <td className="clinic-table-assign">{item.distributor ? <p>{item.distributor}</p> : null} </td>
            </tr>
          ))}
          </tbody>
        </HTMLTable>
      </div>
    </div>
  )
}


export default ClinicList
