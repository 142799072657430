import React from "react";
import {Button} from "@blueprintjs/core";
import FlowListItem from "components/SampleTracking/FlowListItem";
import {
  Switch,
  Route,
  Link,
  useRouteMatch, Redirect, useLocation
} from "react-router-dom";
import FlowDetail from "components/SampleTracking/FlowDetail";
import FlowPreview from "components/SampleTracking/FlowPreview";


const SampleTracking = ({items, onCreateNewFlow, onDeleteFlow, onUpdateFlow, ...props}) => {
  const {path, url} = useRouteMatch();
  const {pathname} = useLocation();

  return (
    <div style={{paddingLeft: 240}}>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: 240,
        background: '#ffffff',
        paddingTop: 120,
        paddingBottom: 120,
        overflow: 'scroll'
      }}>
        <div
          style={{
            padding: 20,
            width: 240,
            background: '#ffffff',
            position: 'fixed',
            top: 50,
            borderBottom: '2px solid #eeeeee'
          }}
        >
          <Button
            icon={'plus'}
            onClick={() => onCreateNewFlow()}
            //minimal
            intent={'success'}
          >
            New Flow
          </Button>
        </div>

        <div>
          <ul style={{listStyle: 'none', padding: 0}}>
            {
              items.map((item, index) => {
                return (
                  <li
                    key={'list-item-' + item.id}
                    style={{margin: 0}}
                  >
                    <Link
                      to={`${url}/flow/${item.id}/`}
                      style={{
                        display: 'block',
                        textDecoration: 'none',
                      }}
                    >
                      <FlowListItem
                        title={item.title}
                        id={item.id}
                        active={pathname === `${url}/flow/${item.id}/`}
                      />
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>

      <div>
        <Switch>
          <Route exact path={path}>
            {
              items.length > 0 ? (
                <Redirect to={`${url}flow/${items[0].id}`}/>
              ) : (
                <div>Create a flow</div>
              )
            }
          </Route>
          <Route exact path={`${path}/flow/:flowId/preview`}>
            <FlowPreview/>
          </Route>
          <Route exact path={`${path}/flow/:flowId`}>
            <FlowDetail
              onDeleteFlow={onDeleteFlow}
              onUpdateFlow={onUpdateFlow}
            />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default SampleTracking;
