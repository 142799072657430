import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';

export default (props) => {

    const [currentMoment, setCurrentMoment] = useState(null);

    const { loading, data, dateRange } = props;

    useEffect(() => {
        if (!loading && data) {
           const filtered = data.clinicKpi.timeline.filter(item => item.isCurrent);
           setCurrentMoment(new Date(filtered[0].timeFrame[0]).getTime())
        }
    }, [data, loading, dateRange]);

    


   const plotData = useMemo(()=> {
       const sortByDate = () => {
           if (data) {
                const before = [];
                const after = [];
                const equal = [];
                data.clinicKpi.timeline
                .sort(function(a,b) {
                    return b.timeFrame[0] - a.timeFrame[0]
                })
                .forEach((item, index) => {

                    const itemStartTime = new Date(item.timeFrame[0]).getTime() < new Date(item.timeFrame[1]).getTime() ? new Date(item.timeFrame[0]).getTime() : new Date(item.timeFrame[1]).getTime();
                    const itemEndTime = new Date(item.timeFrame[0]).getTime() < new Date(item.timeFrame[1]).getTime() ? new Date(item.timeFrame[1]).getTime() : new Date(item.timeFrame[0]).getTime();

                    if (item.isCurrent) {
                        item.plotIndex = String(0);
                        equal.push(item);
                    }
                    else if (itemStartTime >= currentMoment && itemEndTime > currentMoment) {

                        item.plotIndex = String(index + 1);
                        after.push(item)
                    }
                    else if ((itemStartTime < currentMoment && itemEndTime <= currentMoment) || (itemStartTime < currentMoment && moment(itemEndTime).isSame(currentMoment, 'day'))) {
                        item.plotIndex = String((index - after.length) * -1);
                        before.push(item)
                    }
                    else {
                        console.log('error:' , itemStartTime, itemEndTime, 'currentMoment:' , currentMoment, 'item:', item)
                        // console.log('error in calculation:', item, 'start:',  itemStartTime < currentMoment, 'end:', itemEndTime <= currentMoment, currentMoment, itemEndTime)
                    }
                })
                if (!before.length) {
                    data.clinicKpi.timeline.forEach((item, i) => item.plotIndex = String(i))
                }

            return before
            .concat(equal)
            .concat(after)
            .sort((a, b) => parseInt(a.plotIndex) - parseInt(b.plotIndex))
            }
        }
       if (data) {
           // eslint-disable-next-line 
           return sortByDate().map((item, index) => {
                 if (props.cyclesView) {
                     return  (
                        {
                            ...item,
                            plotTooltip: {
                            // samplesTotalCount: "total samples: " + moment(item.timeFrame[0]).format("MM/DD/YYYY") + " - " + moment(item.timeFrame[1]).format("MM/DD/YYYY"),
                               cyclesTotalCount: "total cycles: " + moment(item.timeFrame[0]).format("MM/DD/YYYY") + " - " + moment(item.timeFrame[1]).format("MM/DD/YYYY"),
                            },
                            colors: {
                            // samplesTotalCount: item.isCurrent ? '#f8bd0f' : '#2F80ED',
                              cyclesTotalCount: item.isCurrent? '#f8bd0f' : '#2F80ED',
                            }
                        }
                    )
                 } else if (props.samplesView) {
                     return (
                        {
                            ...item,
                            plotTooltip: {
                               samplesTotalCount: "total samples: " + moment(item.timeFrame[0]).format("MM/DD/YYYY") + " - " + moment(item.timeFrame[1]).format("MM/DD/YYYY"),
                            },
                            colors: {
                              samplesTotalCount: item.isCurrent ? '#f8bd0f' : '#2F80ED',
                            }
                        }
                     )
                 }
           })
       }
   }, [data, props.samplesView, props.cyclesView, currentMoment]);

   const keys = props.cyclesView ? ['cyclesTotalCount'] : ['samplesTotalCount']

   if (loading) return <div>...loading</div>

    return (
        <div className="stats-body" style={{height: 300, width: '100%', paddingLeft: '10%', paddingRight: '10%'}}>
                <ResponsiveBar
                    data={plotData}
                    keys={keys}
                    indexBy='plotIndex'
                    labelTextColor='white'
                    minValue={0}
                    valueScale={{type: 'linear'}}
                    margin={{ top: 50, right: 0, bottom: 50, left: 0}}
                    padding={0.2}
                    colors={({ id, data }) => data.colors[id]}
                    tooltip={({ id, data, value, color }) => (
                        <strong style={{ color }}>
                        {data.plotTooltip[id]}: {value}
                        </strong>
                    )}
                    axisBottom={{
                        tickSize: 2,
                        tickPadding: 2,
                        tickRotation: 0,
                        legend: 'Time frame',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    />
        </div>

    )

}
// export default KPIChart;
