/**
 * Compose report disclaimer language from HTML with snippets of content embedded for given test types.
 *
 * The base report HTML is embedded with snippet tags.
 * Snippet tags are placeholders for test-specific report language that gets embedded
 * into the base language document.
 *
 * snippet tag syntax:  {{{test-type-expression:::content}}}
 * where:
 *    test-type-expression = the test type(s) this snippet contains language for.
 *                   - single test type  A
 *                                       A+
 *                                       M
 *                                       SR
 *                                       P
 *                                       A-only (A without any others)
 *                                       A+-only (A+ without any others)
 *                   - OR multiple test types separated by a comma.  E.g. M,SR = when tests include M or SR
 *                   - AND combination with &    E.g  A+&SR = when tests include both A+ and SR
 *                   - NOT with ~    E.g. ~A = when tests do not include A
 *                   - any combination of above   E.g. A,A+&P = when tests include A or (A+ and P)
 *    content = html text content to include for matching test-type-expression
 *
 *  Example: {{{A+:::some polyploidy undetected}}}
 *
 *  There is also one special tag:
 *                    {{{PGT-A/A+:::}}}  -- inserts "PGT-A" when base is "PGT-A"
 *                                          inserts "PGT-A+" when base is "PGT-A+"
 */


/**
 * Regular expression to isolate first snippet tag in string and its components
 *
 * @type {RegExp}
 * if matches, returns array:
 *  0 - entire string
 *  1 - pre-tag; all content before string's first snippet tag
 *  2 - entire first snippet in string
 *  3 - test-types-expression component of first snippet
 *  4 - html content component of first snippet
 *  5 - post-tag; all content after string's first snippet tag (may contain other embedded snippets)
 **/
const snippetTagRegex = /^(.*?)(\{\{\{(.*?):::(.*?)\}\}\})(.*)$/m;

/** Regular expression to isolate first atom in an expression
 *
 * @type {RegExp}
 * if matches, returns array:
 *  0 - entire string
 *  1 - first atom in string - e.g. 'A', 'A+-only', '~SR'
 *  2 - operator following first atom - AND &     OR ,   '' when end-of-string
 *  3 - remainder of string following operator
 * */
const atomMatchRegex = /^([A-Za-z-~+]+)([&,]?)(.*)$/m;

/**
 * @typedef SnippetParse
 * @property {boolean} tagFound - true, a tag was found;
 *                                false, no tags found in given text object
 *                                only contains tagFound and allText when tagFound is false
 * @property {string} allText - the entire text parameter passed to this function
 * @property {string} allTextBeforeTag - portion of text occurring before the first found tag, if tagFound
 * @property {string} snippetTag - the entire first snippet tag found, if tagFound
 * @property {string} testTypesString - test type expression component of snippet tag found
 * @property {string} content - html content component of snippet
 * @property {string} allTextAfterTag - portion of text occurring after the first found tag, if tagFound
 *                                      (may contain additional tags)
 * */

 /**
 * Get first (leftmost) instance of snippet tag within given text
 *
 * This function called to iterate through all snippet tags in base report text.
 *
 * @param text {string} html text
 * @return {SnippetParse} - the found, first snippets components and surrounding text
 */
const parseFirstSnippetTag = text => {
    const matchArray = text.match(snippetTagRegex);
    if (matchArray) {
        return {
          tagFound: true,
          allText: matchArray[0],
          allTextBeforeTag: matchArray[1],
          snippetTag: matchArray[2],
          testTypesString: matchArray[3],
          content: matchArray[4],
          allTextAfterTag: matchArray[5]
        }
    }

    return {
        tagFound: false,
        allText: text
    }
}

/**
 * For evaluation of test-expressions in snippet tag,
 * Is this atom (e.g. "A", "SR", "~P") true or false for given testTypes array
 * I.e. should snippet with this atom be shown in report for given case tests
 *
 * @param atom - e.g. "A", "P", "~SR", "A+-only" ...
 * @param testTypesArray - array of case's tests.  E.g. ["A","M"]
 * @returns {boolean} - true if atom applies (e.g. "A" when case includes "A")
 */
const evalExprAtom = (atom, testTypesArray) => {
  if (atom === 'A-only') return (testTypesArray.length === 1 && testTypesArray[0] === 'A');
  if (atom === 'A+-only') return (testTypesArray.length === 1 && testTypesArray[0] === 'A+');

  // atom not present in testTypesArray.   true/false
  if (atom.startsWith('~')) return (testTypesArray.indexOf(atom.substring(1)) === -1);

  // atom is present in testTypesArray.    true/false
  return (testTypesArray.indexOf(atom) > -1);
}


/**
 * For evaluation of test-expressions in snippet tag,
 * Parse first atom off of given snippet test-type strings expression, and evaluate it
 * (see evalTestStringsExpr below)
 *
 * @param {string} testTypesExpr - valid snippet test-type expression
 * @param {string} goalTestTypes - desired case or preview test types
 * @returns {object} - see below
 */
const parseFirstExprAtom = (testTypesExpr, goalTestTypes) => {
  const matchArray = testTypesExpr.match(atomMatchRegex);
  if (matchArray) {
    return {
      atom: matchArray[1],   // the first atom in expr.  e.g. "A", "P", "~SR", "A+-only" ...
      isAtomTrue: evalExprAtom(matchArray[1], goalTestTypes),  // whether atom evaluates to true
      operator: matchArray[2], // character following the atom - comma, ampersand or ""
      theRest: matchArray[3]  // the remainder of the expression after the operator
    }
  }
  return { atom: '' };
}

/**
 * Evaluate whether a snippet's test-type-expression matches desired test-type array
 * Valid test-type expressions are:
 *     single-test      A A+ M SR P        e.g. if "M", show this snippet when case include "M"
 *     "only"           A-only  A+-only    e.g. if "A-only", show this snippet when case only includes "A"
 *     or               expr,expr,....     e.g. if "M,P", show this snippet when case includes "M" or "P"
 *     and              expr&expr          e.g. if "A&SR", show this snippet only if case includes "A" and "SR"
 *     not              ~expr              e.g. if "~A+", show this snippet only when case does not include "A+"
 *     combo                               e.g. if "A&~SR", show this snippet only when case includes "A" but not "SR"
 *
 * @param snippetTestTypesExpr - test-type expression from snippet
 * @param goalTestTypes - Array of test types that we want to show in preview or case report.  e.g. ['A','SR']
 * @returns {boolean} - true if test-type-expression evaluates to true.  false if not, or expression invalid.
 */
const evalTestTypesExprMatch = (snippetTestTypesExpr, goalTestTypes) => {
  let result = parseFirstExprAtom(snippetTestTypesExpr, goalTestTypes);

  // If this is last atom in expression, its truth is expression's truth.
  if (result.operator === '') return result.isAtomTrue;

  // If expression is "atom OR ..." e.g. ("A,B"), it's true if this atom is true.  Otherwise, look further on.
  if (result.operator === ',')  {
    if (result.isAtomTrue) return true;
    return evalTestTypesExprMatch(result.theRest, goalTestTypes);
  }

  // If expression is "atom AND ...", parse until we're done with the AND'ing (A&B, A&B&C, C&B&A ...)
  if (result.operator === '&') {
    let isExprTrue = result.isAtomTrue;
    do {
      result = parseFirstExprAtom(result.theRest, goalTestTypes);
      if (!result.isAtomTrue) isExprTrue = false;
    } while (result.operator === '&');

    // If all atoms in the ANDs were true ...  (first was true, and no false were encountered)
    if (isExprTrue) return true;

    // If the AND expression was false, but was followed by a comma (OR), keep looking
    if (result.operator === ',')
      return evalTestTypesExprMatch(result.theRest, goalTestTypes);

    // AND expression was false, and there's no more expr to evaluate, ...
    return false;
  }

  // Shouldn't get here.  Probably invalid expression.
  return false;
}

/**
 * Given HTML+snippet content, create report disclaimer for given test types
 *
 * @param {string} content - HTML content with embedded snippets
 * @param {string[]} testTypesArray - array of given test types (in case, or for preview)
 * @returns {string} reportDisclaimer - report disclaimer HTML string
 */
export const composeReportLanguage = (content, testTypesArray) => {
  let reportDisclaimerContent = '';

  let snippetParse = parseFirstSnippetTag(content);
  while (snippetParse.tagFound) {
    reportDisclaimerContent += snippetParse.allTextBeforeTag;

    if (snippetParse.testTypesString === 'PGT-A/A+')
      reportDisclaimerContent += (testTypesArray.indexOf('A+') > -1 ? 'PGT-A+' : 'PGT-A');
    else if (evalTestTypesExprMatch(snippetParse.testTypesString, testTypesArray))
      reportDisclaimerContent += snippetParse.content;

    snippetParse = parseFirstSnippetTag(snippetParse.allTextAfterTag);
  }

  // content + all text remaining after last tag
  return reportDisclaimerContent + snippetParse.allText;
}

export default composeReportLanguage;
