import React, {FunctionComponent, useState} from 'react'
import {gql, useMutation} from "@apollo/client";

const UPLOAD_ATTACHMENT = gql`
    mutation uploadAttachment($clinicId: ID!, $file: Upload!) {
        uploadAttachment(clinicId: $clinicId, file: $file) {
            attachment {
                id,
                fileName,
                fileSize,
                fileType,
                url,
                createdAt,
                updatedAt,
                notes {
                    id,
                    text
                }
            }
        }
    }
`;

type ComponentProps = {
  clinicId: any | null
}

const Attachment: FunctionComponent<ComponentProps> = ({clinicId}) => {
  const [items, setItems] = useState<any[]>([])
  const [uploadAttachment, {loading, error}] = useMutation(UPLOAD_ATTACHMENT, {
    update: (cache, resp) => {
      // can do something with the resp.data when the call has received response here
      console.log(resp.data)
      setItems([resp.data.uploadAttachment.attachment].concat(items))
    }
  });


  const onChange = ({target: {validity, files: [file]}}: any) => {
    if (validity.valid) {
      console.log(file)
      uploadAttachment({
        variables: {
          file,
          clinicId
        }
      })
    }
  };

  if (error) {
    console.log(error)
  }

  return (
    <div>
      <input type="file" required onChange={onChange}/>

      <div style={{marginTop: 20}}>
        {
          (!loading && !error) && (<span>Select file to upload</span>)
        }
        {
          loading && (<span>Uploading...</span>)
        }
        {
          error && (<span>Error! Check console.</span>)
        }
      </div>

      <div style={{marginTop: 20}}>
        {
          items.map((item) => {
            return (
              <p key={item.id}>
                <span>{item.fileName}</span>{' '}
                <span>{item.fileType}</span>{' '}
                <span>{Math.round(item.fileSize / 1000)} KB</span>{' '}
                <a href={item.url} target="_blank" rel="noopener noreferrer">Open</a>
              </p>
            )
          })
        }
      </div>
    </div>
  )
}


export default Attachment
