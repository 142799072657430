import React, {FunctionComponent} from 'react'
import {Card, Text} from "@blueprintjs/core";


type ComponentProps = {
  data: any | null
}


const CaseDetail: FunctionComponent<ComponentProps> = ({data}) => {
  if (data === null) return <p>No data.</p>

  return (
    <div>
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <p>Patient / Partner</p>
            <p><strong>{data.patientName} {data.partnerName ? `<br/> ${data.partnerName}` : ''}</strong></p>
          </div>
          <div>
            <p>Case ID</p>
            <p><strong>{data.caseId}</strong></p>
          </div>
          <div>
            <p>Samples</p>
            <p><strong>{data.samples ? data.samples.length : 0}</strong></p>
          </div>
          <div>
            <p>Linked Cases</p>
            <p><strong>-</strong></p>
          </div>
          <div style={{width: 200}}>
            <p>Clinic</p>
            <p><strong><Text ellipsize>{data.clinicName}</Text></strong></p>
            <p><a href={`https://clinic.genomicprediction.com/providers/${data.providerId}?provider=${data.providerId}`}>Open in Clinic Portal</a></p>
            <p><a href={`/clinicform/${data.clinicInfoId}/account/`}>Open in Dashboard</a></p>
          </div>
        </div>
      </Card>
    </div>
  )
}


export default CaseDetail
