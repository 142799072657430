import React from 'react';

import { NewClinicFormContainer } from '../../containers/NewClinicFormContainer';
import { FormContextProvider } from '../../components/NewClinicForm/Context';


const NewClinicForm = () => {

    return (
        <FormContextProvider>
            <NewClinicFormContainer />
        </FormContextProvider>  
    )
}

export default NewClinicForm;