import React, {useState, useEffect} from 'react';
import {useQuery, gql} from "@apollo/client";
import {ResponsiveScatterPlot} from "@nivo/scatterplot";
import {Card, Checkbox, H2, HTMLTable} from "@blueprintjs/core";
import LoadingOverlay from 'components/Common/Loading/LoadingOverlay';

const DATA = gql`
    query SampleSummary {
        samplePlotData{
            aneuploid{
                x,
                y
            },
            failed{
                x,
                y
            },
            noResult{
                x,
                y
            },
            karyotypeCustomCount{
                karyotype,
                count
            }
        }
    }
`;


export default (props) => {
  const {loading, error, data} = useQuery(DATA);
  const [plotData, setPlotData] = useState([]);
  const [showAneuploid, setShowAneuploid] = useState(true);
  const [showFailed, setShowFailed] = useState(true);
  const [karyotypeData, setKaryotypeData] = useState([]);

  useEffect(() => {
    if (data) {
      const samplePlotData = data.samplePlotData;
      if (samplePlotData) {
        setPlotData([
          {
            id: 'Aneuploid',
            data: samplePlotData.aneuploid
          },
          {
            id: 'Failed',
            data: samplePlotData.failed
          },
          // {
          //   id: 'No Result',
          //   data: samplePlotData.noResult
          // }
        ]);

        setKaryotypeData(samplePlotData.karyotypeCustomCount);
      }
    }
  }, [data])

  return (
    <div>
      <LoadingOverlay show={loading} />
      <div style={{padding: '30px 0'}}>
        <H2>Samples</H2>
      </div>
      <div style={{padding: '20px 0'}}>
        <Checkbox
          checked={showAneuploid}
          label={'Aneuploid'}
          inline={true}
          onChange={(e) => setShowAneuploid(e.target.checked)}
        />
        <Checkbox
          checked={showFailed}
          label={'Failed'}
          inline={true}
          onChange={(e) => setShowFailed(e.target.checked)}
        />
      </div>
      <Card>
        <div style={{height: 400}}>
          {error ? <p>Error: {error.message}</p>
          :
          <ResponsiveScatterPlot
            data={plotData}
            margin={{ top: 10, right: 12, bottom: 80, left: 40 }}
            nodeSize={16}
            blendMode={'multiply'}
            legends={[
              {
                anchor: 'bottom-left',
                direction: 'row',
                translateY: 50,
                itemWidth: 130,
                itemHeight: 12,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'day',
              // tickValues: 'every week'
            }}
            xFormat="time:%m/%d/%Y"
            // gridYValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]}
            // axisLeft={{
            //   tickValues: [7, 14, 21]
            // }}
            axisBottom={{
              format: '%b %Y',
              tickValues: 'every 360 days',
            }}
            tooltip={(d) => {
              const {node} = d;

              return (
                <div
                  style={{
                    color: node.style.color,
                    background: '#333333',
                    padding: '12px 16px',
                  }}
                >
                  <strong>
                    {node.data.serieId}
                  </strong>
                  <br/>
                  {`Date: ${node.data.formattedX}`}
                  <br />
                  {`Rate: ${node.data.formattedY}`}
                </div>
              )
            }}

            colors={node => {
              if (node.serieId === 'Aneuploid' && showAneuploid) {
                return 'hsl(192,78%,75%)'
              }

              if (node.serieId === 'Failed' && showFailed) {
                return 'hsl(271,66%,70%)'
              }

              if (node.serieId === 'No Result' && showFailed) {
                return 'hsl(0,0%,57%)'
              }

              return 'transparent'
            }}
          />
          }
        </div>
      </Card>

      <div style={{padding: '30px 0'}}>
        <H2>Karyotypes</H2>
      </div>

      <Card>
        <HTMLTable interactive striped>
          {
            karyotypeData.map((item, index) => {
              return item.count > 2 ? (
                <tr key={'k-' + index}>
                  <td style={{width: 30}}>{index + 1}</td>
                  <td>{item.karyotype}</td>
                  <td>{item.count}</td>
                </tr>
              ) : null
            })
          }
        </HTMLTable>
      </Card>
    </div>
  )
}
