import React, {useState, useRef} from 'react';
import {Button, Tab, Tabs} from "@blueprintjs/core";
import MainSection from "components/Report/Form/MainSection";
import LanguageSection from "components/Report/Form/LanguageSection";
import AppearanceSection from "components/Report/Form/Appearance";
import 'components/Report/style/form.css';


export default ({initialData, onChange, documentLoading}) => {
  const [values, setValues] = useState({...initialData});
  const buttonRef = useRef(null)


  const manualUpdate = () => {
    onChange({...values})
  }

  const onLanguageChange = (language) => {
    if (language !== values.language) {
      setValues({
        ...values,
        language: language
      })
    }
  }

  return (!!values && !documentLoading) ? (
    <div>
      <Tabs
        animate={true}
        id="reportForm"
      >
        <Tab id="main" title="Main" panel={<MainSection values={values} setValues={setValues}/>} />
        <Tab id="language" title="Language" panel={
          <LanguageSection data={values.language} onChange={(v) => onLanguageChange(v)}/>
        } />
        <Tab id="appearance" title="Appearance" panel={<AppearanceSection values={values} setValues={setValues}/>} />
        <Tabs.Expander />
        <Button ref={buttonRef} onClick={() => manualUpdate()} intent="success" icon="refresh">Save / Update PDF View</Button>
      </Tabs>
    </div>
  ) : 'Loading...'
}
