import React, { useState } from 'react';
import { FormGroup, InputGroup, Checkbox, Card, Button, Intent, Tag, Alignment } from '@blueprintjs/core';
import { ContactNotes } from './Notes.jsx';
import ConfirmDelete from './ConfirmDelete';
import './styles.css';

export const ContactsList = ({ data, id, isEditable }) => {
    const [toDelete, setToDelete] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    
    
    const confirmDialog = (name) => {
        setToDelete(name);
        setIsOpen(true)
    };
    
    return data.clinicDetail.contact.map((contact, i) => (
        <div key={`contact-${i}`}>
        <Card>
            <FormGroup
                key={`contact-${i}-nameInput`}
                labelFor={contact.name}
                label="Name:"
            >
                <InputGroup id={contact.name} value={contact.name} key={`contact-${i}-name`} readOnly={true}/>
                
            </FormGroup>
            <FormGroup
                key={`contact-${i}-emailInput`}
                labelFor={contact.email}
                label="Email:"
            >
                <InputGroup id={contact.email} value={contact.email} key={`contact-${i}-email`} readOnly={true}/>
            </FormGroup>
            <FormGroup
                labelFor="role"
                label="Role:"
            >
                <InputGroup id="role" value={contact.role} key={`contact-${i}-role`} readOnly={true}/>
            </FormGroup>
            {contact.report ? 
                <div className="reporting-container">
                    <Checkbox id="report" checked={contact.report} label="Send Report:" alignIndicator={Alignment.RIGHT} readOnly={true} />
                </div> : null
            }
             <ContactNotes data={data.clinicDetail.contact[i].notes} id={id} contactId={i} isEditable={isEditable} /> 
             {contact.tags && contact.tags.length ? 
                <>
                    <p>Tags:</p>
                    <div className="contact-tags">
                    {contact.tags.map((tag, i) => (
                        <Tag className="contact" key={`tag-${tag.index}`}>{tag.tagName}</Tag>
                    ))}
                    </div>
                </>
            : null}
             <Button onClick={()=>confirmDialog(contact.name)} style={{position: 'relative', float: 'right', bottom: '30px'}} intent={Intent.DANGER} disabled={!isEditable}>Delete Contact</Button>
             {isOpen && <ConfirmDelete toDelete={toDelete} clinicId={id} category="contact" isOpen={isOpen} setIsOpen={setIsOpen}/>}
            {/* <FormGroup 
                key={`contact-note-${i}`}
                labelFor={`contact_notes_${i}`}
                >
                <TextArea
                    growVertically={true}
                    id={`contact_notes_${i}`}
                    value={data.clinicDetail.contact[i].notes || newNote.text}
                    onChange={e=>setNote({type: e.target.id, text: e.target.value})}
                />
            </FormGroup> */}
           
            {/* <Button onClick={handleClick}>Add Note</Button> */}
            {/* {data.clinicDetail.contact[i].notes.length ? <ContactNotes data={data.clinicDetail.contact[i].notes} id={id} /> : null} */}
            </Card>
        </div>
    ))
};