import React from 'react';
import {View} from "@react-pdf/renderer";


export default ({width, ...props}) => {
  return (
    <View style={props.style ? {...props.style, padding: 4, width: width} : {padding: 4, width: width}}>
      {props.children}
    </View>
  )
}
