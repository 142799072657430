import React from 'react';
import {View, Text} from "@react-pdf/renderer";

const containerStyle = {
  position: 'absolute',
  bottom: 20,
  right: 20,
  left: 0,
  height: 20,
  textAlign: 'right',
  zIndex: 9999,
}

const caseDataStyle = {
  position: 'fixed',
  top: 14,
  left: 40,
  width: '100%',
  textAlign: 'left',
  color: '#cccccc',
  fontSize: 7
}

export default ({hideCaseData, hidePatientData, caseId, patientName, partnerName, blinded, ...props}) => {
  return (
    <View
      style={containerStyle}
      fixed
      render={({pageNumber: pn}) => {
        return (
          <View>
            {
              (!hideCaseData && pn > 1) && (
                <View
                  style={caseDataStyle}
                >
                  <Text>
                    Case ID: {caseId} Patient: {patientName} & {partnerName}
                  </Text>
                </View>
              )
            }

            <Text render={({pageNumber, totalPages}) => (
              `${pageNumber} / ${totalPages}`
            )}/>
          </View>
        )
      }}
    />
  )
}
