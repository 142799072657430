import React, {useState, useEffect, useCallback} from 'react';
import {
  Card,
  Button,
  Elevation,
  Collapse,
  FormGroup,
  InputGroup,
  ButtonGroup,
  Intent, Divider, Icon, Tag, NumericInput
} from '@blueprintjs/core';

import NotificationForm from "components/SampleTracking/Step/NotificationForm";
import {gql, useMutation} from '@apollo/client';
import './style.css';
import SubFlowForm from "components/SampleTracking/Step/SubFlowForm";
import LoadingOverlay from "components/Common/Loading/LoadingOverlay";

export const TYPE_ICONS = {
  'spot': 'time',
  'range': 'array-timestamp',
  'flow': 'diagram-tree'
}

const UPDATE_STEP = gql`
    mutation updateFlowStep($input: StepInput!) {
        updateFlowStep(input: $input) {
            flowStep {
                id,
                title,
                order
            }
        }
    }
`;


const Step = ({index, data, isOpenDefault, onStepDelete, ...props}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault)
  const [type, setType] = useState(data.type)  // spot | range
  const [required, setRequired] = useState(data.required)  // true | false
  const [title, setTitle] = useState(data.title)
  const [durationDays, setDurationDays] = useState(data.durationDays)

  const [updateFlowStep, {loading: updateFlowStepLoading}] = useMutation(UPDATE_STEP);


  const saveData = useCallback((inputData) => {
    updateFlowStep({
      variables: {
        input: {
          id: data.id,
          patch: {
            ...inputData
          }
        }
      }
    })
  }, [data.id, updateFlowStep]);

  useEffect(() => {
    const updateDurationDays = (value) => {
      saveData({durationDays: value})
    };
    if (data && data.durationDays !== durationDays) {
      updateDurationDays(durationDays)
    }
  }, [data, saveData, durationDays])


  const onNotificationFormChange = (formData) => {
    updateFlowStep({
      variables: {
        input: {
          id: data.id,
          patch: {
            alertOnStart: formData.alertOnStart,
            alertOnComplete: formData.alertOnComplete,
            alertBeforeDeadline: formData.alertBeforeDeadline,
            alertOnDeadline: formData.alertOnDeadline,
            alertAfterDeadline: formData.alertAfterDeadline,
            alertOnNote: formData.alertOnNote
          }
        }
      }
    })
  }

  const hasNotifications = () => {
    let result = false

    if (data) {
      result = (!!data.alertOnStart && data.alertOnStart.length > 0) ||
        (!!data.alertOnComplete && data.alertOnComplete.length > 0) ||
        (!!data.alertBeforeDeadline && data.alertBeforeDeadline.length > 0) ||
        (!!data.alertOnDeadline && data.alertOnDeadline.length > 0) ||
        (!!data.alertAfterDeadline && data.alertAfterDeadline.length > 0) ||
        (!!data.alertOnNote && data.alertOnNote.length > 0)
    }

    return result;
  }

  return (
    <Card
      interactive={true}
      elevation={Elevation.ZERO}
      //onClick={e => e.stopPropagation()}
    >
      <LoadingOverlay show={updateFlowStepLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: -20,
          padding: 20
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <div
          style={{
            flexGrow: 3,
            paddingRight: 40
          }}
        >
          {
            isOpen ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (title !== data.title) {
                    saveData({title: title})
                  }
                }}
                onBlur={() => {
                  if (title !== data.title) {
                    saveData({title: title})
                  }
                }}
              >
                <InputGroup
                  placeholder={'Step #' + (index + 1)}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fill
                />
              </form>
            ) : (
              <strong>
                {title ? title : <span style={{color: '#cccccc'}}>Step #{index + 1}</span>}
              </strong>
            )
          }
        </div>

        <div
          style={{
            flexGrow: 1
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
            {
              (data && data.type === 'FLOW' && data.subFlow) && (
                <div style={{paddingRight: 20}}>
                  <Tag
                    minimal
                    round
                    icon={'flows'}
                    intent={'primary'}
                  >
                    {data.subFlow.title ? data.subFlow.title : ('Flow #' + data.subFlow.id)}
                  </Tag>
                </div>
              )
            }

            {
              (data && data.type === 'RANGE') && (
                <div style={{paddingRight: 20}}>
                  <Tag
                    minimal
                    round
                    icon={'array-timestamp'}
                    intent={'success'}
                  >
                    {data.durationDays}
                  </Tag>
                </div>
              )
            }

            <div>
              <Button icon={TYPE_ICONS[type.toLowerCase()]} minimal style={{opacity: 1}}/>
              <Button
                icon="asterisk"
                minimal
                style={{opacity: required ? 1 : 0.3}}
              />
              <Button
                icon="notifications"
                minimal
                style={{opacity: hasNotifications() ? 1 : 0.3}}
              />
            </div>
            <Divider/>
            <div>
              <Button
                icon="trash"
                minimal
                intent={Intent.DANGER}
                onClick={(e) => {
                  e.stopPropagation()
                  if (window.confirm('Are you sure?')){
                    onStepDelete(data.id)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Collapse isOpen={isOpen} keepChildrenMounted={true}>
        <div style={{padding: '20px 0 60px 0'}}>
          <div style={{marginTop: 20, marginBottom: 20}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div style={{paddingRight: 20}}>
                Types:
              </div>
              <div>
                <ButtonGroup minimal>
                  <Button
                    icon={TYPE_ICONS.spot}
                    active={type === 'SPOT'}
                    intent={type === 'SPOT' ? Intent.PRIMARY : Intent.NONE}
                    onClick={() => {
                      setType('SPOT');
                      saveData({type: 'SPOT'})
                    }}
                    text={'Spot'}
                  />
                  <Button
                    icon={TYPE_ICONS.range}
                    active={type === 'RANGE'}
                    intent={type === 'RANGE' ? Intent.PRIMARY : Intent.NONE}
                    onClick={() => {
                      setType('RANGE');
                      saveData({type: 'RANGE'})
                    }}
                    text={'Range'}
                  />
                  <Button
                    icon={TYPE_ICONS.flow}
                    active={type === 'FLOW'}
                    intent={type === 'FLOW' ? Intent.PRIMARY : Intent.NONE}
                    onClick={() => {
                      setType('FLOW');
                      saveData({type: 'FLOW'})
                    }}
                    text={'Sub Flow'}
                  />

                  <Divider/>

                  <Button
                    active={required}
                    intent={required ? Intent.SUCCESS : Intent.NONE}
                    icon={'asterisk'}
                    text={'Required'}
                    onClick={() => {
                      const val = !required;
                      setRequired(val);
                      saveData({required: val})
                    }}
                  />
                </ButtonGroup>
              </div>
            </div>
          </div>

          {/*<div style={{marginBottom: 20}}>*/}
          {/*  <Checkbox checked={true} label="Required" onChange={() => {}} />*/}
          {/*</div>*/}

          {
            (type && type === 'FLOW') && (
              <div style={{marginTop: 30, marginBottom: 30}}>
                <SubFlowForm
                  parentFlowID={data.id}
                  initialValue={(!!data && data.subFlow) ? data.subFlow.id : null}
                  onSelect={(id) => {
                    saveData({subFlowId: id})
                  }}
                />
              </div>
            )
          }

          {
            (type && type === 'RANGE') && (
              <div style={{marginTop: 30, marginBottom: 30}}>
                <FormGroup
                  label="Duration (days)"
                  labelFor="durationDays"
                  inline
                >
                  {/*<InputGroup id="durationDays" placeholder="Placeholder text" />*/}
                  <NumericInput
                    value={durationDays}
                    onValueChange={(value) => setDurationDays(value)}
                    // onBlur={() => {
                    //   if (data && data.durationDays !== durationDays) {
                    //     saveData({durationDays: durationDays})
                    //   }
                    // }}
                  />
                </FormGroup>
              </div>
            )
          }

          <div>
            <NotificationForm
              initialData={data}
              onChange={onNotificationFormChange}
            />
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 40,
              margin: '0 -20px -20px -20px',
              textAlign: 'center',
              paddingTop: 12,
              background: 'rgba(227,246,248,0.48)'
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            <Icon icon={'double-chevron-up'}/>
          </div>
        </div>
      </Collapse>
    </Card>
  )
}


export default Step
