import React from 'react';
import {Card, H4, Icon, Intent, Tag} from "@blueprintjs/core";
import {Col, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import Tracking from "components/Home/Tracking";


export default (props) => {
  const {id, caseId, patientName, partnerName, caseStatus, notes, clinicName, testTypes,
  sampleCount, salivaSampleCount, clinicianName, flows} = props;

  return (
    <div className="case-list-item">
      <Card elevation={1} interactive>
        <div>
          <Row>
            <Col xs={3}>
              <H4>
                {patientName} {partnerName}
                {(!patientName && !partnerName) && <span>No Name</span>}
              </H4>

                <Tag minimal>{caseId || 'No Label'}</Tag>{' '}
                <div style={{marginTop: 4}}>
                  {
                    testTypes.map((item) => {
                      return (
                        <Tag
                          key={'test-type-' + id + '-' + item}
                          minimal
                          round
                          intent={Intent.PRIMARY}
                          style={{marginRight: 2}}
                        >
                          {item}
                        </Tag>
                      )
                    })
                  }
                </div>

              <p>
                {caseStatus === 'complete' && <Icon icon="tick-circle" intent="success"/>}
                {caseStatus === 'pending' && <Icon icon="time"/>}
                {' '}{caseStatus}
              </p>
            </Col>

            <Col xs={9}>
              <Row>
                <Col xs={3}>
                  <span>Clinic:<strong>{clinicName}</strong></span><br/>
                  <span>Referring clinician:{clinicianName}</span><br/>
                  <span>Samples: {sampleCount}</span><br/>
                  <span>Saliva Samples: {salivaSampleCount}</span>
                </Col>

                <Col xs={3}>
                  {/*<span>Type of Bill: Client</span><br/>*/}
                  {/*Set up fee: -<br/>*/}
                  {/*Biopsy: fee: -<br/>*/}
                  {/*Total Biopsy Charge: -<br/>*/}
                </Col>

                <Col xs={3}>
                  <Link to={`/report/${id}`}>Open Report</Link>
                </Col>
                <Col xs={3}>
                  <Link to={`/case/${id}`}>View Details</Link>
                </Col>
              </Row>
              {
                notes && (
                  <Row>
                    <Col xs={12} style={{marginTop: 12}}>
                      <p>Notes:</p>
                      <div style={{fontSize: '13px'}}>
                        {notes}
                      </div>
                    </Col>
                  </Row>
                )
              }
            </Col>
          </Row>

          <div>
            <div style={{paddingTop: 10, paddingBottom: 10}}>

            </div>


          </div>
        </div>

        <div style={{width: '100%'}}>
          <Tracking caseId={id} allFlows={flows} />
        </div>
      </Card>
    </div>
  )
}
