import React, {FunctionComponent} from 'react'
import {useQuery} from "@apollo/client"
import {Redirect, useParams, useRouteMatch} from "react-router-dom"
import {ParentForm} from "containers/NewClinicFormContainer";
import { CLINIC_DETAIL } from "./Helpers/gql";

const ClinicDetail: FunctionComponent = () => {
  const {id, subPage} = useParams<any>()
  const {url} = useRouteMatch<any>();

  const {loading, error, data} = useQuery(CLINIC_DETAIL, {
    variables: {
      id: id
    }
  });


  if (error) return <p>Error {error.message}</p>
  if (loading) return <p>Loading...</p>

  if (data) return (
    <div>
      {!subPage && <Redirect to={`${url}account/`}/>}
      <ParentForm data={data} />
    </div>
  )

  return null
}


export default ClinicDetail
