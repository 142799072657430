import React from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { Button, Tag, Intent, InputGroup } from '@blueprintjs/core';
import './styles.css'
const RoleMultiSelect = ({ itemdata, tags, setTags, query, setQuery, onActiveItemChange, activeItem, isEditable }) => {
    const handleActiveItemChange = item => onActiveItemChange(item);

    return (
       <div className="multiSearch">
           <p>Select Tag(s) for this contact:</p>
           {isEditable ? 
           <MultiSelect
                className="multiSelect"
                items={itemdata.items}
                activeItem={activeItem}
                itemPredicate={itemdata.itemPredicate}
                itemRenderer={itemdata.itemRenderer}
                onItemSelect={handleActiveItemChange}
                itemDisabled={item=>tags.indexOf(item) >= 0}
                query={query}
                onQueryChange={(text) => setQuery(text)}
                tagRenderer={itemdata.itemRenderer}

            >
                <Button text={activeItem ? activeItem.tagName : 'Select tags'} rightIcon="caret-down" />
            </MultiSelect>
            : <InputGroup style={{maxWidth: '150px', marginLeft: '4px'}} readOnly={true} placeholder="Search..."></InputGroup>}
            <div className="tagContainer">
                {tags && tags.map((tag, i) => (
                    <Tag style={{margin: '1%'}} onRemove={()=>setTags(tags.filter(el => el !== tag))} key={`tag-${i}`} interactive={true} intent={Intent.SUCCESS}>{tag.tagName}</Tag>
                ))}
            </div>
       </div>
    )
};

export default RoleMultiSelect;