import React, {FunctionComponent, useState} from 'react';
import {Button, ButtonGroup} from '@blueprintjs/core';


type ComponentProps = {
    values: {[k: string]: any},
    setValues: <T = unknown, R = unknown>(args?: T) => R;
};


const AppearanceSection: FunctionComponent<ComponentProps> = ({values, setValues}) => {
    const [imgSrc, setImgSrc] = useState('');

    const processImage = (e: any) => {
        const fileURL = URL.createObjectURL(e.target.files[0]);
        setImgSrc(fileURL);
        setValues({...values, clinicLogo: fileURL});
    }
    return (
        <div>
            <div>
                <div>
                    <p>Clinic Logo</p>
                    <input type="file" onChange={processImage}/>
                </div>
                <div style={{paddingTop: 10, paddingBottom: 10}}>
                    <img alt={''} src={imgSrc} style={{width: 120}} />
                </div>
            </div>

            <div style={{marginTop: 15}}>
                <div>
                    <p>Layout</p>

                </div>
                <div style={{paddingTop: 10, paddingBottom: 10}}>
                    <ButtonGroup style={{ minWidth: 200 }}>
                        <Button
                            icon="database"
                            active={values.layout === 1}
                            onClick={() => {
                                setValues({...values, layout: 1});
                            }}
                        >
                            Layout 1
                        </Button>
                        <Button
                            icon="database"
                            active={values.layout === 2}
                            onClick={() => {
                                setValues({...values, layout: 2});
                            }}
                        >
                            Layout 2
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
}

export default AppearanceSection;

