import React, {useState, useEffect, useRef} from 'react';
import {
  ControlGroup,
  InputGroup,
  Button,
  Tag,
  HTMLTable,
  Checkbox,
  Intent,
  H6,
  Classes
} from "@blueprintjs/core";
import {isEqual} from "services/helpers/helpers";

const notificationTypes = [
  {
    name: 'Start',
    code: 'alertOnStart'
  },
  {
    name: 'Complete',
    code: 'alertOnComplete'
  },
  {
    name: 'Before Deadline',
    code: 'alertBeforeDeadline'
  },
  {
    name: 'On Deadline',
    code: 'alertOnDeadline'
  },
  {
    name: 'After Deadline',
    code: 'alertAfterDeadline'
  },
  {
    name: 'Note',
    code: 'alertOnNote'
  }
]

function usePrevious(data, default_value = null) {
  const ref = useRef(default_value);
  useEffect(() => {
    ref.current = data
  }, [data])
  return ref.current
}

const initialList = (data) => {
  let emails = []

  if (data) {
    notificationTypes.forEach((notificationType) => {
      if (data.hasOwnProperty(notificationType.code)) {
        if (data[notificationType.code] && data[notificationType.code].length > 0) {
          data[notificationType.code].forEach((email) => {
            if (!!email && !emails.includes(email)) {
              emails.push(email)
            }
          })
        }
      }
    })

    emails.sort((a, b) => a.localeCompare(b))

    emails = emails.map((email) => {
      const emailItem = {email: email}

      notificationTypes.forEach((notificationType) => {
        emailItem[notificationType.code] = false

        if (data.hasOwnProperty(notificationType.code)) {
          if (data[notificationType.code] && data[notificationType.code].length > 0 && data[notificationType.code].includes(email)) {
            emailItem[notificationType.code] = true
          }
        }
      })

      return emailItem
    })
  }

  return emails
}


const NotificationForm = ({initialData, onChange}) => {
  const [emails, setEmails] = useState(() => initialList(initialData))
  const [newEmail, setNewEmail] = useState('')
  const prevEmails = usePrevious(emails, initialList(initialData))

  useEffect(() => {
    if (!isEqual(prevEmails, emails)) {
      const emailData = {}

      notificationTypes.forEach((notificationType) => {
        emailData[notificationType.code] = []
        emails.forEach((emailEntry) => {
          if (!!emailEntry[notificationType.code]) {
            emailData[notificationType.code].push(emailEntry.email)
          }
        })
      })

      onChange(emailData);
    }

  }, [emails, prevEmails, onChange])

  const onAddEmail = (email) => {
    const newItems = emails.slice()
    const newItem = {email: email}
    notificationTypes.forEach((notificationType) => {
      newItem[notificationType.code] = true
    })
    newItems.push(newItem)
    newItems.sort((a, b) => a.email.localeCompare(b.email))
    setEmails(newItems)
  }

  const onCheckboxChange = (index, code, checked) => {
    const newItems = emails.slice()
    const item = {...newItems[index]}
    item[code] = checked
    newItems[index] = item
    setEmails(newItems)
  }

  const isChecked = (index, code) => {
    return !!emails[index][code]
  }

  const onRemoveEmail = (index) => {
    const newItems = emails.slice()
    newItems.splice(index, 1)
    setEmails(newItems)
  }

  return (
    <div>
      <div style={{paddingTop: 40, paddingBottom: 10}}>
        <H6 className={Classes.TEXT_MUTED}>NOTIFICATIONS</H6>
      </div>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (!!newEmail) {
              onAddEmail(newEmail)
            }
            setNewEmail('')
          }}
        >
          <ControlGroup>
            <InputGroup
              leftIcon="people"
              id="text-input"
              placeholder="email"
              rightElement={<Tag minimal style={{background: 'transparent'}}><span
                className="bp3-text-muted">@genomicprediction.com</span></Tag>}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Button icon="plus" type={'submit'}/>
          </ControlGroup>
        </form>
      </div>
      <div>
        {
          emails.length > 0 ? (
            <HTMLTable style={{width: '100%'}} striped>
              <thead>
              <tr>
                <th style={{verticalAlign: 'bottom'}}>Email</th>
                {
                  notificationTypes.map((notification, j) => {
                    return (
                      <th key={'nt-header-' + j} style={{width: 120, verticalAlign: 'bottom'}}>{notification.name}</th>
                    )
                  })
                }
                <th style={{width: 40, verticalAlign: 'bottom'}}> </th>
              </tr>
              </thead>
              <tbody>
              {
                emails.map((item, i) => {
                  return (
                    <tr key={'i-' + i}>
                      <td style={{verticalAlign: 'middle'}}>{item.email}@genomicprediction.com</td>
                      {
                        notificationTypes.map((notification, j) => {
                          return (
                            <td key={'nt-' + i + j} style={{verticalAlign: 'middle'}}>
                              <Checkbox
                                inline
                                className={'checkbox-no-margin'}
                                checked={isChecked(i, notification.code)}
                                onChange={(e) => {
                                  onCheckboxChange(i, notification.code, e.target.checked)
                                }}
                              />
                            </td>
                          )
                        })
                      }
                      <td style={{verticalAlign: 'middle'}}>
                        <Button
                          icon="cross"
                          minimal
                          intent={Intent.DANGER}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (window.confirm('Are you sure?')) {
                              onRemoveEmail(i)
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </HTMLTable>
          ) : (
            <p className="bp3-text-muted" style={{marginTop: 20}}>
              Notifications not set. Add email in the field above
            </p>
          )
        }

      </div>
    </div>
  )
}

export default NotificationForm
