import React from 'react';
import {H2} from "@blueprintjs/core";
import {Row, Col} from "react-flexbox-grid";
import CaseList from "components/TAT/CaseList";
import {useQuery, gql} from "@apollo/client";
import LoadingOverlay from "components/Common/LoadingOverlay";


const CASES = gql`
  query Cases {
    caseTatList {
      id,
      providerId,
      caseId,
      patientName,
      partnerName,
      clinicName,
      testTypes,
      dueIn,
      overDue,
      caseStatus,
      samplesReceivedAt,
      paymentStatus,
      createdAt
    }
  }
`;


export default (props) => {
  const {loading, error, data} = useQuery(CASES);

  return (
    <div className="page">
      <LoadingOverlay isOpen={loading}/>

      <div className="page-header">
        <div className="container container-fluid">
          <H2>Cases & TAT</H2>
        </div>
      </div>

      <div className="page-body">
        <div className="container">
          <Row>
            <Col xs>
              <div style={{marginBottom: 40}}>
                {error && <p>Error loading data: {error.message}</p>}
                {
                  !!data && <CaseList data={data}/>
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
