import React from "react";
import {Image, Page, StyleSheet} from "@react-pdf/renderer";
import CaseFooter from "components/Report/CaseFooter";
import stripePNG from "assets/images/stripe.png";
import LanguagePageContent from "components/Report/LanguagePageContent";
import CaseFooterNoPatient from "components/Report/CaseFooterNoPatient";

export const styles = StyleSheet.create({
  page: {
    position: 'relative',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: 7,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 100,
    paddingRight: 100
  },
});

export default ({data, ...props}) => {

  return (
    <Page size="LETTER" style={styles.page} orientation={data.layout === 1 ? 'portrait':'landscape'} ruler={false} wrap>
      <Image
        source={stripePNG}
        style={{
          width: 6,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0
        }}
        fixed
      />

      <LanguagePageContent data={data}/>

      {
        (data.blinded) ? (
          <CaseFooterNoPatient {...data}/>
        ) : (
          <CaseFooter {...data}/>
        )
      }
    </Page>
  )
}
