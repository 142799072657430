import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { CLINIC_DETAIL, UPDATE_CLINIC } from './Helpers/gql';
import { useLocation, useHistory } from 'react-router-dom';
import { FormContext } from './Context';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { FormGroup, HTMLSelect, Radio, InputGroup, RadioGroup, Button } from '@blueprintjs/core';
import { Assign } from './Assign';
import { ClinicFormToaster } from './Toaster';
import  EditSwitch  from './EditSwitch';
import { countries } from './CountryList';
import './styles.css';



const states = ['Choose State', 'Alabama','Alaska', 'Arizona', 'Arkansas', 
'California', 'Colorado', 'Connecticut', 'Delaware', 
'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 
'Indiana','Iowa', 'Kansas', 'Kentucky', 'Louisiana', 
'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
'Minnesota', 'Mississippi', 'Missouri', 'Montana', 
'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 
'New Mexico', 'New York', 'North Carolina', 'North Dakota', 
'Ohio', 'Oklahoma','Oregon', 'Pennsylvania', 'Rhode Island', 
'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 
'Utah', 'Vermont', 'Virginia', 'Washington', 
'West Virginia', 'Wisconsin', 'Wyoming', 'Other'];

const areas = ['Choose Region', 'North America', 'Latin America', 'Asia', 'Africa', 'Middle East']

const AccountDetails = ({ id, data }) => {

    let currentLocation = useLocation();
    let history = useHistory();

    const [state, dispatch] = useContext(FormContext);
    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: id
                }
            }    
        ], 
        onCompleted(){
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0, 0);
        }
    });


    const [values, setValues] = useState({
        isDistributor: "Standalone Clinic",
        assign: "No",
        name: "",
        address1: "",
        address2: "",
        city: "",
        postal: "",
        stateUS: "",
        provinceOther: "",
        country: "",
        email: "",
        telephone: "",
        fax: "",
        region: "", 
        distributor: {
            name: "",
            id: "",
            isDistributor: true
        },
    });


    useEffect(() => {
        if (data){
            const { clinicDetail } = data;
            console.log(clinicDetail)
            setValues(prev => ({
                ...prev,
                isDistributor: !clinicDetail.isDistributor ? "Standalone Clinic" : "Distributor",
                name: clinicDetail.name || "",
                address1:  clinicDetail.address && clinicDetail.address.address1 ? clinicDetail.address.address1 : "",
                address2: clinicDetail.address && clinicDetail.address.address2 ? clinicDetail.address.address2 : "",
                city: clinicDetail.address && clinicDetail.address.city ? clinicDetail.address.city : "",
                postal: clinicDetail.address && clinicDetail.address.postal ? clinicDetail.address.postal : "",
                stateUs:  clinicDetail.address && clinicDetail.address.stateUs ? clinicDetail.address.stateUs : "",
                provinceOther: clinicDetail.address && clinicDetail.address.provinceOther ? clinicDetail.address.provinceOther : "",
                country:  clinicDetail.address && clinicDetail.address.country ? clinicDetail.address.country : "",
                telephone: clinicDetail.telephone || "",
                fax: clinicDetail.fax || "",
                assign: clinicDetail.distributor ? "Yes" : "No", 
                distributor: clinicDetail.distributor ? {name: clinicDetail.distributor.name, id: clinicDetail.distributor.id, isDistributor: clinicDetail.distributor.isDistributor} : { name: "", id: "", isDistributor: true}
                }));
        }
    }, [data]);
    
    
    //converts value of radio button to type boolean from type string:
    const boolean = values.isDistributor === "Distributor";

    console.log(values)
    console.log('data:', data)
    const options = states.map((state, index)=> {
        return <option value={state} key={index+1}>{state}</option>
    });
        
    const regions = areas.map((region, i) => {
        return <option value={region} key={i+1}>{region}</option>
    })
    
    console.log(values)
    const handleChange = (e) => {
        const { value, name } = e.target;
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        })
        if (name !== 'distributor') {
            setValues({...values, [name]: value});
        } else {
           setValues({...values, [name]: JSON.parse(value)})
        
        }  
    };

    const handleSubmit = e =>{
        e.preventDefault();
        updateClinic({
            variables: {
                id: id,
                input: {
                    isDistributor: boolean,
                    name: values.name,
                    address: {
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        postal: values.postal,
                        stateUs: values.stateUs,
                        provinceOther: values.provinceOther,
                        country: values.country,
                    },
                    email: values.email,
                    telephone: values.telephone,
                    fax: values.fax,
                    distributor: values.distributor.id
                }
            }
        })
    };

    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };


return (
    <div className="form-container">
        <form style={{display: 'flex', flexDirection: 'column', }} onSubmit={handleSubmit}>
            <>
            {updateError && <p>Error updating clinic: {updateError} </p>}
            <EditSwitch />
            <RouteLeavingGuard 
                when={Object.keys(state.dirtyFields).length > 0}
                navigate={path=>history.push(path)}
                shouldBlockNavigation={location=>{
                    if (Object.keys(state.dirtyFields).length > 0) {
                        if (location.pathname !== currentLocation.pathname) {
                            return true
                        }
                    }
                    return false
                }}
            />
            </>
            <h1 className="form-header">Account Details:</h1> 
            <div style={{width: '100%'}}>
                <RadioGroup 
                disabled={!state.isEditing}
                name="isDistributor"
                label="Select type of account: *"
                selectedValue={values.isDistributor}
                onChange={handleChange}
                
                >
                    <Radio label="Standalone Clinic" value="Standalone Clinic" />
                    <Radio label="Distributor" value="Distributor"  />
                </RadioGroup>
                {(values.isDistributor === "Standalone Clinic" && !values.distributor.id.length) && 
                <RadioGroup
                    disabled={!state.isEditing}
                    label="Assign clinic to distributor: *"
                    name="assign"
                    selectedValue={values.assign}
                    onChange={handleChange}
                >
                    <Radio label="No" value="No" />
                    <Radio label="Yes" value="Yes" />
                </RadioGroup>
                }
                {(values.isDistributor === "Standalone Clinic" && values.assign === "Yes") && 
                    <Assign data={data} value={values.distributor} name="distributor" onChange={handleChange} />
                }
                <FormGroup
                    label={values.isDistributor === "Standalone Clinic" ? "Clinic Name" : "Distributor Name"}
                    labelFor="Clinic Name:"
                >
                    <InputGroup readOnly={!state.isEditing} type="text" id="Clinic Name" style={{marginRight: '0'}} value={values.name} onChange={handleChange} name="name" />
                </FormGroup>
                <FormGroup
                    label="Address: *"
                    labelFor="Clinic Address 1"
                    labelInfo="(Line 1)"
                >
                    <InputGroup readOnly={!state.isEditing} id="Clinic Address 1" type="text" value={values.address1} onChange={handleChange} name="address1" />
                </FormGroup>
                <FormGroup
                    labelFor="Clinic Address 2"
                    labelInfo="(Line 2)"
                    label="Address:"
                >
                    <InputGroup readOnly={!state.isEditing} id="Clinic Address 2" type="text" value={values.address2} onChange={handleChange} name="address2" />
                </FormGroup>
                <FormGroup
                    label="City: *"
                    labelFor="Clinic City"
                >
                    <InputGroup readOnly={!state.isEditing} id="Clinic City" value={values.city} onChange={handleChange} name="city" />
                </FormGroup>
                
                
                <FormGroup
                    labelFor="stateUS"
                    label="State: *"
                >
                    <HTMLSelect
                        disabled={!state.isEditing}
                        id="stateUs"
                        value={values.stateUs}
                        onChange={handleChange}
                        name="stateUs"
                    >
                        {options}
                    </HTMLSelect>
                </FormGroup>
                {
                    values.stateUS === 'Other' && 
                    <FormGroup
                        label="Enter Province:"
                        labelFor="province"
                    >
                        <InputGroup readOnly={!state.isEditing} id="province" value={values.provinceOther} onChange={handleChange} name="provinceOther" />
                    </FormGroup>
                }
                    <FormGroup 
                labelFor="country"
                label="Country: *"
                >
                    <HTMLSelect
                        disabled={!state.isEditing}
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                    >
                        {countries}
                    </HTMLSelect>
                </FormGroup>
                <FormGroup
                    labelFor="Clinic Region"
                    label="Region: *"
                >
                    <HTMLSelect
                        disabled={!state.isEditing}
                        id="Region"
                        name="region"
                        value={values.region}
                        onChange={handleChange}
                    >
                        {regions}
                    </HTMLSelect>   
                </FormGroup>
                <FormGroup
                    label="ZIP Code: *"
                    labelFor="Clinic ZIP"
                >
                    <InputGroup 
                        readOnly={!state.isEditing}
                        id="postal" 
                        name="postal"
                        value={values.postal}
                        onChange={handleChange}
                        
                        />
                </FormGroup>
                <FormGroup
                    label="Phone: *"
                    labelFor="Phone"
                >
                    <InputGroup
                        readOnly={!state.isEditing}
                        id="phone"
                        name="telephone"
                        type="tel"
                        minLength="9"
                        value={values.telephone}
                        onChange={handleChange}
                    >
                    </InputGroup>
                </FormGroup>
                <FormGroup
                    label="Fax:"
                    labelFor="Clinic Fax"
                >
                    <InputGroup
                        readOnly={!state.isEditing}
                        id="Clinic Fax"
                        name="fax"
                        type="tel"
                        value={values.fax}
                        onChange={handleChange}
                        
                    >
                    </InputGroup>
                </FormGroup>
                <FormGroup
                    label="Email: *"
                    labelFor="Clinic Email"
                >
                    <InputGroup
                        readOnly={!state.isEditing}
                        id="Clinic Email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                    >
                    </InputGroup>
                </FormGroup>
                <Button type="submit" text="Save" intent="primary" disabled={updateLoading}></Button>
            </div>
        </form>
    </div>
    )
}

export default AccountDetails;
