import React from 'react';
import {Card, Elevation, H5, HTMLTable} from "@blueprintjs/core";
import {Row, Col} from "react-flexbox-grid";
import LoadingOverlay from 'components/Common/LoadingOverlay';


export default (props) => {
  const {data, loading, error} = props;
  const clinics = data ? data.clinicSampleList.slice() : [];

  // let avg_fail_rate = 0;
  // let total = 0;
  // let totalFailed = 0;
  // let totalAneuploid = 0;
  // let avgAneuploidRate = 0;
  //
  // if (clinics.length > 0) {
  //   totalFailed = clinics.reduce((prev, cur) => {
  //     return prev + cur.samplesFailedCount
  //   }, 0);
  //   total = clinics.reduce((prev, cur) => {
  //     return prev + cur.samplesWithResultsCount
  //   }, 0);
  //   avg_fail_rate = Math.round(((totalFailed / total * 100) + Number.EPSILON) * 100) / 100
  // }
  if (error) return <div>{`Error: ${error.message}`}</div>
  return (
    <div>
      <LoadingOverlay show={loading} />
      <div style={{marginBottom: 20}}>
        <Row between="xs">
          <Col xs>
            <H5>
              Samples
            </H5>
          </Col>

          <Col xs={1}>

          </Col>
        </Row>
      </div>

      <Card interactive={true} elevation={Elevation.TWO}>
        <div className="card-body">
          <div className="card-table">
            <HTMLTable striped interactive>
              <thead>
              <tr>
                <th>Clinic</th>
                <th>Total</th>
                {/*<th>No Result</th>*/}
                <th>Euploid</th>
                <th>Aneuploid</th>
                <th>Aneuploidy Rate %</th>
                <th>Failed</th>
                <th>Fail Rate %</th>
              </tr>
              </thead>
              <tbody>
              {
                clinics.reverse().map((clinic) => {
                  return (
                    <tr key={'clinic-row-' + clinic.id}>
                      <td>{clinic.companyName}</td>
                      <td>{clinic.samplesTotalCount - clinic.samplesWithNoResultsCount}</td>
                      {/*<td>{clinic.samplesWithNoResultsCount}</td>*/}
                      <td>{clinic.samplesEuploidCount}</td>
                      <td>{clinic.samplesAneuploidCount}</td>
                      <td>
                        {
                          clinic.samplesTotalCount > 0 ?
                            Math.round(((clinic.samplesAneuploidCount / clinic.samplesWithResultsCount * 100) + Number.EPSILON) * 100) / 100 : '-'
                        }
                      </td>

                      <td>{clinic.samplesFailedCount}</td>
                      <td>{
                        clinic.samplesTotalCount > 0 ?
                          Math.round(((clinic.samplesFailedCount / clinic.samplesWithResultsCount * 100) + Number.EPSILON) * 100) / 100 : '-'
                      }</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </HTMLTable>
          </div>
        </div>
      </Card>
    </div>
  )
}
