import React, {useState, useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Button, Checkbox, FormGroup, HTMLTable, InputGroup} from "@blueprintjs/core";
import moment from 'moment';
import './style/main.css';
import {Col, Row} from "react-flexbox-grid";
import axios from "axios";


const DATA = gql`
    query qReviewPatientList {
        qReviewPatientList {
            id,
            firstName,
            lastName,
            phone,
            email,
            cases {
                id,
                caseId,
                completedAt,
                type
            }
        }
    }
`;


const QReviewContainer = (props) => {
  const {loading, error, data} = useQuery(DATA);
  const [patientList, setPatientList] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  // const [url, setUrl] = useState('http://demo.q-reviews.com/api/client/v2/recipient');
  // const [auth, setAuth] = useState('ApiKey sQDGmlFykmB99584PL5ZOjh00SgmeF:3e6d38116e279537b97ee24e1dc8eb937b0ed2c4');
  const [url, setUrl] = useState('https://qrev.ws/api/client/v2/recipient');
  const [auth, setAuth] = useState('ApiKey FJkKP7EfzSGfyUolI5hdPVLmLoZg6t:d528dc43640d34e5d591b6a472af285b4abf6878');
  const [done, setDone] = useState([]);
  const [rulId, setRulId] = useState(280);

  const token = window.localStorage.getItem('authToken');

  const interractionType = (t) => {
    let items = t.split(',');

    if (items.length === 1 && items[0] === 'A') {
      return 'pgt_a';
    } else if (items.length === 2) {
      const filtered = items.filter((item) => item !== 'A');
      return 'pgt_' + filtered[0].toLowerCase();
    }
  }

  const patientInterractionType = (cases) => {
    let items = cases.filter(i => i.completedAt && i.caseId);
    return interractionType(items[0].type)
  }

  useEffect(() => {
    if (data && !!data.qReviewPatientList) {
      let items = data.qReviewPatientList.filter((item) => !!item.phone);
      setPatientList(items);
    }
  }, [data]);


  useEffect(() => {
    done.forEach((item) => {
      const pp = {...selectedPatients}
      delete pp[item]
      setSelectedPatients(pp)
    })
  }, [done, selectedPatients])


  const itemProps = (item) => {
    return {
      first_name: item.firstName,
      last_name: item.lastName,
      uid: item.id.toString(),
      phone: item.phone,
      rule_id: rulId,
      interaction_type: patientInterractionType(item.cases)
    }
  }

  const selectPatient = (item) => {
    const p = {...selectedPatients}

    if (p.hasOwnProperty(item.id)) {
      delete p[item.id];
      setSelectedPatients(p);
    } else {
      p[item.id] = itemProps(item)

      setSelectedPatients({
        ...selectedPatients,
        ...p
      })
    }
  }


  const sendDataItem = (data) => {
    var config = {
      method: 'post',
      //url: url,
      url: process.env.REACT_APP_API_HOST + 'sendqreview/',
      // headers: {
      //   'Authorization': auth,
      //   'Content-Type': 'application/json'
      // },
      headers: {
        "Authorization": `Token ${token}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        'auth': auth,
        'url': url,
        'data': data
      })
    };

    return axios(config)

  }

  const sendData = (it) => {


    if (Object.keys(it).length > 0) {
      const itemKey = Object.keys(it)[0];
      const item = it[itemKey];

      console.log('Sending ' + item.uid);

      sendDataItem(item)
        .then(function (response) {
          console.log(item.uid + ' sent', response.status);

        })

        .catch(function (error) {
          console.log(item.uid + ' error', error);
          console.log(error.response)
        })

        .finally(() => {
          delete it[itemKey];
          //setSelectedPatients(it);
          //removeItemFromState(itemKey)
          const pp = [...done];
          pp.push(itemKey);
          setDone(pp);
          sendData(it);
        });
    } else {
      console.log('Done!')
    }
  }

  if (error) return <div>Error: {error}</div>
  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <div>
      <div style={{margin: '30px 0'}}>
        <Row>
          <Col xs>
            Total: {patientList.length}
          </Col>
          <Col xs>
            <Button
              disabled={Object.keys(selectedPatients).length === 0}
              intent={Object.keys(selectedPatients).length === 0 ? '' : 'primary'}
              onClick={() => sendData({...selectedPatients})}
            >Submit Selected ({Object.keys(selectedPatients).length})</Button>
          </Col>
        </Row>
      </div>

      <div>
        <FormGroup
          label="URL"
          labelFor="url"
        >
          <InputGroup
            id="url"
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="AUTH"
          labelFor="auth"
        >
          <InputGroup
            id="auth"
            value={auth}
            onChange={e => setAuth(e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="RUL_ID"
          labelFor="rul"
        >
          <InputGroup
            id="rul"
            value={rulId}
            onChange={e => setRulId(parseInt(e.target.value))}
          />
        </FormGroup>
      </div>

      <HTMLTable
        bordered={false}
        striped={false}
        className={'qreview-patient-table'}
      >
        <thead>
        <tr>
          <th>
            <Checkbox checked={false} label={''} onChange={() => {
              const p = {...selectedPatients}
              patientList.forEach((item) => {
                p[item.id] = itemProps(item)
              })

              setSelectedPatients(p)
            }}/>
          </th>
          <th>ID</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Phone</th>
          <th colSpan={5}>Cases</th>
          <th colSpan={3}>QReview Status</th>
        </tr>
        </thead>
        <tbody>
        {
          patientList.map((item, itemIndex) => {
            return item.cases.map((caseItem, caseItemIndex) => {
              const createdAt = caseItem.completedAt ? moment(caseItem.completedAt, "YYYY-MM-DDThh:mm:ss") : null;
              const rowSpan = !!item.cases.length ? item.cases.length : 1;
              return (
                <tr key={'patient-' + itemIndex + '-case-' + caseItemIndex}
                    className={itemIndex % 2 === 0 ? 'even' : 'odd'}>
                  {caseItemIndex === 0 && (
                    <td rowSpan={rowSpan}>
                      <Checkbox
                        checked={selectedPatients.hasOwnProperty(item.id)}
                        label={''}
                        onChange={(e) => {
                          selectPatient(item)
                        }}
                      />
                    </td>
                  )}

                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>{item.id}</td>}
                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>{item.lastName}</td>}
                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>{item.firstName}</td>}
                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>{item.phone}</td>}

                  <td>{caseItem.caseId ? caseItem.caseId : '-'}</td>
                  <td>{createdAt ? createdAt.calendar() : '-'}</td>
                  <td>{createdAt ? createdAt.fromNow() : '-'}</td>
                  <td>{caseItem.type}</td>
                  <td>{interractionType(caseItem.type)}</td>

                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>Submitted</td>}
                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>Status</td>}
                  {caseItemIndex === 0 && <td rowSpan={rowSpan}>Details</td>}
                </tr>
              )
            })
          })
        }
        </tbody>
      </HTMLTable>


    </div>
  )
}


export default QReviewContainer;
