import React from "react";
import SideBar from './SideBar';
import {Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import TopSection from "components/Report/TopSection";
import TableTitle from "components/Report/SampleTables/TableTitle";
import CaseFooter from "components/Report/CaseFooter";
import stripePNG from "assets/images/stripe.png";
import LanguagePageContent from "components/Report/LanguagePageContent";
import Disclaimer from './Disclaimer';
import EmbryoTable from "./SampleTables/EmbryoTable";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: 8,
    position: 'relative'
  },

  container : {
    padding: 30,
    paddingLeft: 200,
    minHeight: '100%'
  },

  container2 : {
    padding: 30,
    minHeight: '100%'
  },

  content: {
    padding: 10
  },

  sidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 180
  },

  rowSection: {
    display: 'flex',
    flexDirection: 'row',
  },

  rowSectionColumn: {
    flexGrow: 1,
    width: '50%'
  },
  disclaimer: {
    position: 'relative',
    backgroundColor: '#ededed',
    padding: '0 10 0 4',
    fontSize: 6,
    marginTop: 30
  },
  disclaimerAbsolute: {
    position: 'absolute', 
    bottom: 50, 
    left: 180, 
    right: 34,
    marginLeft: 30,
    backgroundColor: '#ededed',
    marginTop: 20,
    padding: '0 4',
    fontSize: 6
  }
});

export const RESULT_INTERPRETATION = {
  nm1:  'negative',
  nm2:  'heterozygous positive',
  m1m2: 'heterozygous positive',
  m1m1: 'negative',
  m2m2: 'homozygous positive',
  nn:   'negative',
  inconclusive: 'inconclusive',
  no_result: 'no result',
  "m1.5m2": 'heterozygous positive',
  "nm1.5": "negative"
}

/**
 * Are there any Whole-Embryo (arrested) samples present?
 * @param {object[]} samples - array of all sample objects
 * @returns {boolean} - true if there are whole-embryo (arrested) samples present
 */
const hasWholeEmbryo = (samples) => {
  return samples.some(sample => (('tissueType' in sample) && sample.tissueType === 'arrested'));
}

/**
 * <ReportPage> - render main body of PDF Report in browser
 *
 * @param {object} data - {patient/case data, selectPanel, diseases, language}
 * @param {{all: *[], euploid: *[], aneuploid: *[], failed: *[]}} sampleData - arrays of sample data
 *        see CASES in components/Report/index.js for sample fields
 * @param props
 * @returns {JSX.Element}
 */
export default ({data, sampleData, ...props}) => {
  const samplesAll = sampleData.all
  const samplesEuploid = sampleData.euploid
  const samplesAneuploid = sampleData.aneuploid
  const samplesFailed = sampleData.failed.map((item) => ({...item, sex: '-'}))
  const hasCustomId = samplesAll.every(sample => sample.embryoId.length)
  const reportingPreferences = {embryoId: data.reportEmbryoId, embryoGrade: data.reportEmbryoGrade, biopsyDay: data.reportBiopsyDay}

  return (
    <Page size="LETTER" style={styles.page} orientation={data.layout === 1 ? 'portrait':'landscape'} ruler={false} wrap>
      <Image
        source={stripePNG}
        style={{
          width: 6,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0
        }}
        fixed
      />

      <View style={styles.container}>
        <CaseFooter hideCaseData={true} {...data}/>

        <View style={styles.sidebar}>
          <SideBar data={data}/>
        </View>

        <View style={styles.content}>
          <TopSection sampleData={sampleData} {...data}/>

          {
            data.testTypes.includes('M2') ? (
              <View wrap={false} style={{
                fontSize: 12,
                paddingTop: 12,
                paddingBottom: 12,
                marginTop: 12
              }}>
                <Text>ANXA5 M2 Haplotype</Text>
                <View style={{marginTop: 6}}>
                  {
                    data.salivaSamples.map((item) => {
                      return (
                        <View>
                          <Text style={{textTransform: 'uppercase'}}>RESULT: <Text style={{fontStyle: 'normal', fontWeight: 'bold'}}>{RESULT_INTERPRETATION[item.status]}</Text></Text>
                        </View>
                      )
                    })
                  }
                </View>

                <View style={data.layout === 1 ? {marginTop: 30} : {marginTop: 10}}>
                  <LanguagePageContent data={data}/>
                </View>
              </View>
            ) : (
              <View>
                {
                  data.testTypes.includes('SR') ? (
                    <View style={{paddingTop: 30}}>
                      <TableTitle
                        text="Embryos"
                      />
                      <EmbryoTable testTypes={data.testTypes} data={samplesAll} cycleNumber={data.cycleNumber} layout={data.layout} hasCustomId={hasCustomId} reportingPreferences={reportingPreferences} />
                    </View>
                  ) : (
                   <View style={{marginTop: 30}}>
                      {
                        (samplesEuploid && samplesEuploid.length > 0) && (
                          <View wrap={false} style={data.layout === 1 ? {marginTop: 30} : {marginTop: 10}}>
                            <TableTitle
                              text="Euploid Embryos"
                              count={samplesEuploid.length}
                            />
                            <EmbryoTable testTypes={data.testTypes} data={samplesEuploid} cycleNumber={data.cycleNumber} hasCustomId={hasCustomId} reportingPreferences={reportingPreferences}/>
                          </View>
                        )
                      }
                      {
                        (samplesAneuploid && samplesAneuploid.length > 0) && (
                          <View wrap={false} style={data.layout === 1 ? {marginTop: 30} : {marginTop: 10}}>
                            <TableTitle
                              text="Aneuploid Embryos"
                              //subText="Unsuitable for transfer"
                              count={samplesAneuploid.length}
                            />
                            <EmbryoTable testTypes={data.testTypes} data={samplesAneuploid} cycleNumber={data.cycleNumber} hasCustomId={hasCustomId} reportingPreferences={reportingPreferences}/>
                          </View>
                        )
                      }
                      {
                        samplesFailed.length > 0 && (
                          <View wrap={false} style={data.layout === 1 ? {marginTop: 30} : {marginTop: 10}}>
                            <TableTitle
                              text="No Result"
                              //subText="Unsuitable for transfer"
                              count={samplesFailed.length}
                            />
                            <EmbryoTable testTypes={data.testTypes} data={samplesFailed} cycleNumber={data.cycleNumber} hasCustomId={hasCustomId} reportingPreferences={reportingPreferences}/>
                          </View>
                        )
                      }
                      {(data.testTypes.length === 1 && data.testTypes[0] === 'A' && samplesAneuploid && samplesAneuploid.length > 0) &&
                        <View style={styles.disclaimer} wrap={false}>
                          <Text>
                            For embryos identified with aneuploidy, PGT-A+ is available to determine whether the aneuploidy derived from the egg or sperm;
                            or occurred post-fertilization in the embryo, suggestive of possible mosaicism.
                            This information may be used to help inform future treatment decisions.
                          </Text>
                        </View>
                      }
                      {!data.testTypes.includes('SR') && (reportingPreferences.embryoId || reportingPreferences.embryoGrade || reportingPreferences.biopsyDay || hasWholeEmbryo(samplesAll)) && (
                        <Disclaimer style={styles.disclaimer} hasCustomId={hasCustomId} hasWholeEmbryo={hasWholeEmbryo(samplesAll)} reportingPreferences={reportingPreferences} />
                      )}
                    </View>
                  )
                }
              </View>
            )
          }
        </View>
       {data.testTypes.includes('SR') && (reportingPreferences.embryoId || reportingPreferences.embryoGrade || reportingPreferences.biopsyDay) && (
         <Disclaimer style={styles.disclaimerAbsolute} hasCustomId={hasCustomId} hasWholeEmbryo={false} reportingPreferences={reportingPreferences} />
       )}
      </View>
      {/*<Layout1 data={data} sampleData={sampleData}/>*/}


      {/*{*/}
      {/*  data.layout === 1 && (*/}
      {/*    <Layout1 data={data} sampleData={sampleData}/>*/}
      {/*  )*/}
      {/*}*/}

      {/*{*/}
      {/*  data.layout === 2 && (*/}
      {/*    <Layout2 data={data} sampleData={sampleData}/>*/}
      {/*  )*/}
      {/*}*/}
 
    </Page>
  );
}
