import React, {FunctionComponent} from 'react';


const NotFound: FunctionComponent = () => {
  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
  )
}

export default NotFound;
