import { gql } from '@apollo/client';

export const PROVIDERS = gql`
query clinicList {
    clinicList {
        id
        name
        isDistributor,
        accountStatus {
          username
          reason
          currentStatus
        }
    }
}
`;

export const UPDATE_CONTACT = gql`
    mutation updateContact($id: ID! $input: ClinicData!) {
        updateClinic(id: $id, input: $input) {
            clinic {
                shipping {
                    address {
                        address1,
                        address2,
                        city,
                        postal,
                        stateUs,
                        provinceOther,
                        country
                    }
                    urgency,
                    preference,
                    notes {
                        id,
                        text,
                        author,
                        createdAt
                    }
                }
            }
        }
    }
`;


export const UPDATE_CLINIC = gql`
    mutation updateClinic($id: ID!, $input: ClinicData! ) {
        updateClinic(id: $id, input: $input) {
            clinic {
                name,
                isDistributor,
                address {
                    address1,
                    address2,
                    city,
                    postal,
                    stateUs,
                    provinceOther,
                    country
                }
                email,
                telephone,
                fax, 
                contact {
                    name,
                    email,
                    role,
                    telephone,
                    report,
                    tags {
                        tagName,
                        index
                    },
                    notes {
                        id,
                        text,
                        author,
                        createdAt
                    }
                }
                shipping {
                    address {
                        address1,
                        address2,
                        city,
                        postal,
                        stateUs,
                        provinceOther,
                        country
                    }
                    urgency,
                    preference,
                    notes {
                        id,
                        text,
                        author,
                        createdAt
                    }
                }, 
                accountStatus {
                    username,
                    reason, 
                    currentStatus
                },
                billingType,
                billing {
                    a {
                        setUp,
                        perEmbryo,
                        notes
                    },
                    m {
                        setUp,
                        perEmbryo,
                        notes
                    },
                    p {
                        setUp,
                        perEmbryo,
                        notes
                    },
                    sr {
                        setUp,
                        perEmbryo,
                        notes
                    },
                    m2 {
                        perEmbryo,
                        notes
                    },
                }
                biopsyKitPackage,
                biopsyKitNotes {
                    id
                    text
                    author
                    createdAt
                },
                salivaKitNotes {
                    id
                    text
                    author
                    createdAt
                },
                pgtAConsent,
                reportInEmail,
                gc,
                includeSsf,
                reportInPortal,
                salesRep,
                pgtAVolumePerMonth,
                pgtAIsBatched,
                protocolIsSent,
                onboardingCallIsConducted,
                distributor {
                    name,
                    id,
                    isDistributor
                }
                clinics {
                    name,
                    id,
                    isDistributor,
                    telephone
                } 
            }   
        }
    }
`



export const CLINIC_DETAIL = gql`
    query clinicDetail($id: ID) {
        clinicDetail(id: $id) {
            name,
            isDistributor,
            address {
                address1,
                address2,
                city,
                postal,
                stateUs,
                provinceOther,
                country
            }
            email,
            telephone,
            fax, 
            contact {
                name,
                email,
                role,
                telephone,
                report,
                tags {
                    tagName,
                    index
                },
                notes {
                    id,
                    text,
                    author,
                    createdAt
                }
            }
            shipping {
                address {
                    address1,
                    address2,
                    city,
                    postal,
                    stateUs,
                    provinceOther,
                    country
                }
                urgency,
                preference,
                notes {
                    id,
                    text,
                    author,
                    createdAt
                }
            },
            accountStatus {
                username,
                reason, 
                currentStatus
            },
            billingType,
            billing {
                a {
                    setUp,
                    perEmbryo,
                    notes
                },
                m {
                    setUp,
                    perEmbryo,
                    notes
                },
                p {
                    setUp,
                    perEmbryo,
                    notes
                },
                sr {
                    setUp,
                    perEmbryo,
                    notes
                },
                m2 {
                    perEmbryo,
                    notes
                },
            }
            biopsyKitPackage,
            biopsyKitNotes {
                id
                text
                author
                createdAt
            },
            salivaKitNotes {
                id
                text
                author
                createdAt
            },
            pgtAConsent,
            gc,
            includeSsf,
            reportInEmail,
            reportInPortal,
            salesRep,
            pgtAVolumePerMonth,
            pgtAIsBatched,
            protocolIsSent,
            onboardingCallIsConducted,
            attachments {
                id
                fileName
                fileType
                createdAt
                s3Path
                url
            },
            distributor {
                name,
                id,
                isDistributor
            }
            clinics {
                name,
                id,
                isDistributor,
                telephone
            }
        }
    }
`;

export const DELETE_CONTACT = gql`
    mutation deleteContact($clinicId: ID!, $contactName: String!) {
        deleteContact(clinicId: $clinicId, contactName: $contactName) {
            status
        }
    }
`;

export const ADD_NOTE = gql`
    mutation addNote($clinicId: ID!, $text: String!, $type: String!) {
        addNote(clinicId: $clinicId, text: $text, type: $type) {
            note {
                id,
                author,
                text,
                createdAt
            }
        }
    }
`;

export const DELETE_NOTE = gql`
    mutation deleteNote($clinicId: ID!, $noteId: String!, $type: String!) {
        deleteNote(clinicId: $clinicId, noteId: $noteId, type: $type) {
            status
        }
    } 
`;
export const UPLOAD_ATTACHMENT = gql`
    mutation uploadAttachment($clinicId: ID!, $file: Upload!) {
        uploadAttachment(clinicId: $clinicId, file: $file) {
            attachment {
                id
                fileName
                fileType
                createdAt
                s3Path
                url
            }
        }
    }
`;

