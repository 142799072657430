import React from 'react';
import {Checkbox, FormGroup, HTMLSelect, InputGroup, TextArea} from "@blueprintjs/core";
import {PANEL_OPTIONS, CONDITIONS, CONDITIONS_BY_PANEL} from "components/Report/index";


const removeDuplicates = (arr) => {
  const obj = {};
  arr.forEach(value => {
    obj[value] = ''
  });
  return Object.keys(obj);
}


const MainSection = ({values, setValues}) => {
  const onChange = (t, checked, varName) => {
    const newValue = removeDuplicates(values[varName].slice());
    const index = newValue.indexOf(t);

    if (checked) {
      if (!newValue.includes(t)) {
        newValue.push(t);
      }
    } else {
      if (newValue.includes(t)) {
        newValue.splice(index, 1);
      }
    }

    setValues({...values, [varName]: newValue});
  }

  const {
    caseId,
    patientName,
    partnerName,
    testTypes,
    clinicianName,
    showPTableForAneuploid,
    reportInfo,
    blinded,
    show_db,
    hidePartner,
    hideSexInKaryotype,
    isAmended,
    selectedPanel,
    panel,
    analysisVersion,
    reportVersion
  } = values;

  return (
    <div>
      <FormGroup
        label="Analysis Version"
        labelFor="analysisVersion"
        inline
      >
        <InputGroup
          id="analysisVersion"
          value={analysisVersion}
          onChange={e => setValues({...values, analysisVersion: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Report Version"
        labelFor="reportVersion"
        inline
      >
        <InputGroup
          id="reportVersion"
          value={reportVersion}
          onChange={e => setValues({...values, reportVersion: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Case ID"
        labelFor="caseId"
        inline
      >
        <InputGroup
          id="caseId"
          value={caseId}
          onChange={e => setValues({...values, caseId: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Patient Name"
        labelFor="patientName"
        inline
      >
        <InputGroup
          id="patientName"
          value={patientName}
          onChange={e => setValues({...values, patientName: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Partner Name"
        labelFor="partnerName"
        inline
      >
        <InputGroup
          id="partnerName"
          value={partnerName}
          onChange={e => setValues({...values, partnerName: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Clinician Name"
        labelFor="clinicianName"
        inline
      >
        <InputGroup
          id="clinicianName"
          value={clinicianName}
          onChange={e => setValues({...values, clinicianName: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Amended"
        labelFor="isAmended"
        inline
      >
        <Checkbox
          checked={!!isAmended}
          label=""
          onChange={e => setValues({...values, isAmended: e.target.checked})}
          inline
        />
      </FormGroup>

      <FormGroup
        label={panel.charAt(0).toUpperCase() + panel.slice(1) + ' panel'}
        labelFor="panel"
        inline
      />

      <FormGroup
        label="PGT-P Panel"
        labelFor="selectedPanel"
        inline
      >
        <HTMLSelect
          value={selectedPanel}
          onChange={e => setValues({...values, selectedPanel: e.currentTarget.value, diseases: CONDITIONS_BY_PANEL[e.currentTarget.value]})}
        >
          <option value={null}>Select...</option>
          {
            Object.keys(PANEL_OPTIONS).map((key) => {
              return (
                <option key={key} value={key}>{PANEL_OPTIONS[key]}</option>
              )
            })
          }
        </HTMLSelect>
      </FormGroup>

      {panel === 'custom' && (
        <FormGroup
          label="Сonditions"
          inline
        >
          {
            Object.keys(CONDITIONS).map((key) => {
              return (
                <Checkbox key={key}
                  checked={values.diseases.includes(key)}
                  label={CONDITIONS[key]}
                  onChange={(e) => onChange(key, e.target.checked, 'diseases')}
                  disabled={!CONDITIONS_BY_PANEL[selectedPanel].includes(key)}
                  inline
                />
              )
            })
          }
        </FormGroup>
      )}

      <FormGroup
        label="Info"
        labelFor="reportInfo"
        style={{minHeight: 120}}
      >
        <TextArea
          growVertically={true}
          fill={true}
          id="reportInfo"
          value={reportInfo}
          onChange={e => setValues({...values, reportInfo: e.target.value})}
          style={{minHeight: 120}}
        />
      </FormGroup>

      <FormGroup
        label="Test Types"
        labelFor="clinicianName"
        inline
      >
        <Checkbox
          checked={testTypes && testTypes.includes('A')}
          label="A"
          onChange={(e) => onChange('A', e.target.checked, 'testTypes')}
          disabled
          inline
        />
        <Checkbox
          checked={testTypes && testTypes.includes('A+')}
          label="A+"
          onChange={(e) => onChange('A+', e.target.checked, 'testTypes')}
          disabled
          inline
        />
        <Checkbox
          checked={testTypes && testTypes.includes('M')}
          label="M"
          onChange={(e) => onChange('M', e.target.checked, 'testTypes')}
          disabled
          inline
        />
        <Checkbox
          checked={testTypes && testTypes.includes('P')}
          label="P"
          onChange={(e) => onChange('P', e.target.checked, 'testTypes')}
          disabled
          inline
        />
        <Checkbox
          checked={testTypes && testTypes.includes('SR')}
          label="SR"
          onChange={(e) => onChange('SR', e.target.checked, 'testTypes')}
          disabled
          inline
        />
      </FormGroup>

      <FormGroup
        label="Blinded"
        labelFor="blinded"
        inline
      >
        <Checkbox
          checked={!!blinded}
          label=""
          onChange={e => setValues({...values, blinded: e.target.checked})}
          inline
        />
      </FormGroup>

      <FormGroup
        label="Show DoB if blinded"
        labelFor="show_db"
        inline
      >
        <Checkbox
          checked={!!show_db}
          label=""
          onChange={e => setValues({...values, show_db: e.target.checked})}
          inline
        />
      </FormGroup>

      <FormGroup
        label="Show table PGT-P when aneuploid"
        labelFor="clinicianName"
        inline
      >
        <Checkbox
          checked={!!showPTableForAneuploid}
          label=""
          onChange={e => setValues({...values, showPTableForAneuploid: e.target.checked})}
          inline
        />
      </FormGroup>

      <FormGroup
        label="Hide Partner"
        labelFor="hidePartner"
        inline
      >
        <Checkbox
          checked={!!hidePartner}
          label=""
          onChange={e => setValues({...values, hidePartner: e.target.checked})}
          inline
        />
      </FormGroup>

      <FormGroup
        label="Hide sex in karyotype"
        labelFor="hideSexInKaryotype"
        inline
      >
        <Checkbox
          checked={!!hideSexInKaryotype}
          label=""
          onChange={e => setValues({...values, hideSexInKaryotype: e.target.checked})}
          inline
        />
      </FormGroup>
    </div>
  )
}

export default MainSection;
