import React from 'react';
import {View, Text} from "@react-pdf/renderer";


const TableTitle = ({text, subText, count, ...props}) => {
  return (
    <View style={{marginBottom: 5}}>
      <Text style={{fontSize: 14, fontStyle: 'normal', fontWeight: 'semibold'}}>{text}</Text>
      {subText && <Text style={{fontSize: 10, fontStyle: 'normal', fontWeight: 'semibold'}}>{subText}</Text>}
    </View>
  )
}

export default TableTitle;
