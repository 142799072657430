/**
 * Output value for cell at given row and column
 * @param {object} columnDef - column definition
 * @param {object} rowData - object containing data for row.
 * @param cycleNumber
 * @returns {*|string} - value to output for cell
 */
const cellValue = (columnDef, rowData, cycleNumber) => {
  if (columnDef.header === 'Cycle|Number' || columnDef.header === 'Cycle Number') return cycleNumber;
  if (rowData.tissueType === 'arrested') {    // Whole Embryo
    if (columnDef.accessor === 'embryoNumber') return `WE ${rowData[columnDef.accessor]}`;
    if (columnDef.accessor === 'embryoGrade') return '';
  }
  return rowData[columnDef.accessor];  // row's value at given column
}

/**
 * Transform sample data and column defs into rows of cells with values
 *
 * @param {object[]} data - rows of sample data.  Sample data from db + derived test-specific fields
 *                          see CASES in components/Report/index.js for sample fields.  See EmbryoTable for others.
 * @param {object[]} columns - column definitions, in display order - {header, accessor, relativeWidth}
 * @param {number} cycleNumber - value to plug into Cycle Number column
 * @returns {{newRows: *[], newHeaderRows: [{cells: *[]}]}}
 *        Array of HeaderRows.  Each headerRow contains {data: {}, cell: array of cells}.
 *        Array of rows - Each row contains {data: row's sample data, cell: array of cells}.
 *        Each cell is object {value, width, style, index, accessor}
 */
const createRows = (data, columns, cycleNumber) => {
  let newRows = [];
  const newHeaderRows = [{data: {}, cells: []}];

  // For each row of sample data ...
  data.forEach((item, i) => {
    const newRow = {data: item, cells: []}

    columns.forEach((column, j) => {
      if (i === 0) {
        newHeaderRows[0].cells.push({
          value: column.header,
          width: column.width ? column.width : null,
          style: column.style ? column.style : {},
          index: j,
          accessor: column.accessor
        });
      }

      newRow.cells.push({
        value: cellValue(column, item, cycleNumber),
        width: column.width ? column.width : null,
        style: column.style ? column.style : {},
        index: j,
        accessor: column.accessor
      });
    });

    newRows.push(newRow);
  })
  return {newRows, newHeaderRows}
}

/**
 * Transform sample data and column defs into rows of cells with values
 *
 * @param {object[]} data - rows of sample data.  Sample data from db + derived test-specific fields
                         see CASES in components/Report/index.js for sample fields.  See EmbryoTable for others.
 * @param {object[]} columns - column definitions, in display order - {header, accessor, relativeWidth}
 * @param {number} cycleNumber - value to plug into Cycle Number column
 * @returns {{headerRows: [{cells: *[]}], rows: *[]}}
 *      Array of HeaderRows.  Each headerRow contains {data: {}, cell: array of cells}.
 *      Array of rows - Each row contains {data: row's sample data, cell: array of cells}.
 *      Each cell is object {value, width, style, index, accessor}
 */
export default ({data, columns, cycleNumber}) => {
  const dVal = createRows(data, columns, cycleNumber)

  return {
    rows: dVal.newRows,
    headerRows: dVal.newHeaderRows
  };
}
