//Note: commented out code calculates the percentages between previous and current period.
//There has been a request to display the absolute differences between these metrics instead -- keeping old code commented in case there is a request to display the percentages at some point in the future.

import React, { useState, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
import LoadingOverlay from '../Common/LoadingOverlay';

import './styles.css';




const VolumeKPI = (props) => {
    const { data, loading, error } = props;



//calc num of cycles -- compare to prev period -- calculate delta percentage
const [cycles, setCycles] = useState(0);
const [samples, setSamples] = useState(0);
const [deltaCycles, setDeltaCycles] = useState(0);
const [deltaSamples, setDeltaSamples] = useState(0);

// const infinity = `\u221e`;

  useEffect(()=> {
    if(data) {
      const calculateDiff = (stat, name) => {
        let diff;
        const previous = stat === cycles ? data.clinicKpi.previous.cyclesTotalCount : data.clinicKpi.previous.samplesTotalCount;
    
          if (previous && stat !== null) {
            diff = stat - previous;
            console.log('diff', diff, 'previous', data.clinicKpi.previous.samplesTotalCount, 'curernt', data.clinicKpi.current.samplesTotalCount)
          }
          else if (!previous || stat === null) {
            diff = undefined;
          }
          else {
            console.log('error in calc:', stat, previous);
          }
          return diff;
      }
      setCycles(data.clinicKpi.current.cyclesTotalCount);
      setSamples(data.clinicKpi.current.samplesTotalCount);
      setDeltaCycles(calculateDiff(cycles, 'cycles'));
      setDeltaSamples(calculateDiff(samples, 'samples'));
    }
  }, [data, cycles, samples]);

// const accountForEdgeCases = (stat) => {
//   let diffCycles;
//   let deltaCycleNum;
//   let deltaCyclePercent;
//   let diffSamples;
//   let deltaSampleNum;
//   let deltaSamplePercent;

//   if (stat === cycles){
//     if (cycles === 0 && data.clinicKpi.previous.cyclesTotalCount === 0) {
//       deltaCycleNum = 0;
//       deltaCyclePercent = 0;
//     }
//     else if (data.clinicKpi.previous.cyclesTotalCount === 0) {
//       if (cycles > data.clinicKpi.previous.cyclesTotalCount) {
//         deltaCycleNum = Infinity;
//         deltaCyclePercent = Infinity;
//       }
//       else {
//         deltaCycleNum = -Infinity;
//         deltaCyclePercent = -Infinity;
//       }
//     }
//     else if (cycles === 0) {
//       if (data.clinicKpi.previous.cyclesTotalCount > 0) {
//         deltaCycleNum = -Infinity;
//         deltaCyclePercent = -Infinity;
//       }
//       else {
//         deltaCycleNum = Infinity;
//         deltaCyclePercent = Infinity;
//       }
//     }
//     else {
//       diffCycles = cycles - data.clinicKpi.previous.cyclesTotalCount;
//       deltaCycleNum = ((diffCycles / data.clinicKpi.previous.cyclesTotalCount) * 100);
//       deltaCyclePercent = parseFloat(deltaCycleNum.toPrecision(2));
//     }
//     return deltaCyclePercent;
//   }
//   if (stat === samples) {
//     if (samples === 0 && data.clinicKpi.previous.samplesTotalCount === 0) {
//       deltaSampleNum = 0;
//       deltaSamplePercent = 0;
//     }
//     else if (data.clinicKpi.previous.samplesTotalCount === 0) {
//       if (samples > data.clinicKpi.previous.samplesTotalCount) {
//         deltaSampleNum = Infinity;
//         deltaSamplePercent = Infinity;
//       }
//       else {
//         deltaSampleNum = -Infinity;
//         deltaSamplePercent = -Infinity;
//       }
//     }
//     else if (samples=== 0) {
//       if (data.clinicKpi.previous.samplesTotalCount > 0) {
//         deltaSampleNum = -Infinity;
//         deltaSamplePercent = -Infinity;
//       }
//       else {
//         deltaSampleNum = Infinity;
//         deltaSamplePercent = Infinity;
//       }
//     }
//     else {
//       diffSamples = samples - data.clinicKpi.previous.samplesTotalCount;
//       deltaSampleNum = ((diffSamples / data.clinicKpi.previous.samplesTotalCount) * 100);
//       deltaSamplePercent = parseFloat(deltaSampleNum.toPrecision(2));
//     }
//     return deltaSamplePercent;
//   }
// }
  const showIcon = (input) => {
    return (
      <>
      {input !== undefined ?
      <div>
        <h4 style={{display: 'inline'}}>{input <= 0 ? input : `+${input}`}</h4>
        {
          input > 0 &&
          <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
        }
        {
          input < 0 &&
          <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
        }
      </div>
      : null
      }
      </>
    )
  }
  // const showIcon = (input) => {
  //   const abs = isFinite(input) ? Math.abs(input) : null;

  //   return (
  //     <>
  //     {isFinite(input) ?
  //       <div>
  //         <h4 style={{display: 'inline'}}>{abs}%</h4>
  //         {
  //           input > 0 &&
  //         <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
  //         }
  //         {
  //           input < 0 &&
  //           <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
  //         }
  //         {
  //           ((input === 0 || isNaN(input)) && input !== Infinity && input !== -Infinity) &&
  //           <Icon icon="symbol-square" size={10} style={{color: 'lightgrey'}} />
  //         }
  //       </div>
  //     : <div>
  //         <p style={{display: 'inline'}}>{infinity}</p>
  //         {
  //           input === Infinity &&
  //           <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
  //         }
  //         {
  //           input === -Infinity &&
  //           <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
  //         }
  //       </div>
  //     }
  //     </>
  //   )
  // }

    if (loading) return <div><LoadingOverlay isOpen={loading}/></div>
    if (error) return <div>{`Error: ${error.message}`}</div>

    return (
      <>
          <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: '5%'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
              <div className="statContainer">
                  <h1 className="statHeader">{cycles}</h1>
                  <span className="deltaHistory">{showIcon(deltaCycles)}</span>
              </div>
              <h3 className="refHeader"># of cycles</h3>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className="statContainer">
                <h1 className="statHeader">{samples}</h1>
                <span className="deltaHistory">{showIcon(deltaSamples)}</span>
              </div>
              <h3 className="refHeader"># of embryos</h3>
            </div>
          </div>
          <p className="rebiopsy">{`# of rebiopsy samples: ${data ? data.clinicKpi.current.samplesRebiopsyCount : null}`}</p>
      </>

    )
}

export default VolumeKPI;
