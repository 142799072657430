import React, {FunctionComponent, useState, useEffect} from "react"
import {useParams} from "react-router-dom"
import {gql, useQuery} from "@apollo/client"
import SampleTrackingCase from "components/SampleTracking/Case/SampleTrackingCase";
import {Classes, EditableText, H2, Icon} from "@blueprintjs/core";
import CaseDetail from "components/SampleTracking/Case/CaseDetail";
import moment from "moment";
import Attachment from "components/SampleTracking/Case/Attachment";


const DATA = gql`
    query caseDetail($caseId: ID!) {
        caseDetail(caseId: $caseId) {
            id
            caseId
            patientName
            partnerName
            clinicName
            providerId
            clinicInfoId
            samples {
                id
            }
        }
        caseFlows(caseId: $caseId) {
            id,
            case {
                id
            },
            title,
            description,
            steps {
                id,
                type,
                required,
                order,
                active,
                title,
                description,
                durationDays,
                alertOnStart,
                alertOnComplete,
                alertOnDeadline,
                alertOnNote,
                alertBeforeDeadline,
                alertAfterDeadline,
                alertOnNote,
                deadline,
                started,
                completed,
                canceled,
                canceledReason
                startedAt,
                completedAt,
                createdAt,
                updatedAt,
                canceledAt,
                notes {
                    id
                    content,
                    createdAt,
                    userName
                }
                subFlow {
                    id,
                    steps {
                        id,
                        type,
                        required,
                        order,
                        active,
                        title,
                        description,
                        durationDays,
                        alertOnStart,
                        alertOnComplete,
                        alertOnDeadline,
                        alertOnNote,
                        alertBeforeDeadline,
                        alertAfterDeadline,
                        alertOnNote,
                        deadline,
                        started,
                        completed,
                        canceled,
                        startedAt,
                        completedAt,
                        createdAt,
                        updatedAt,
                        canceledAt,
                    }
                }
            }
            template {
                id
            }
        }
    }
`;


const SampleTrackingCaseContainer: FunctionComponent = () => {
  const {case_id} = useParams<any>()
  const [flow, setFlow] = useState<any | null>(null)
  const [caseData, setCaseData] = useState<any | null>(null)

  const [notes, setNotes] = useState<any[]>([])
  const [currentNote, setCurrentNote] = useState('')

  const {loading, error, data} = useQuery(DATA, {
    fetchPolicy: 'network-only',
    //pollInterval: 2000,
    variables: {
      caseId: case_id
    },
  })

  const saveNote = (value: string) => {
    if (!!value) {
      const newNotes = notes.slice()
      newNotes.push({text: value, user: 'admin',
        createAt: undefined})
      setNotes(newNotes)
      setCurrentNote('')
    }
  }

  useEffect(() => {
    if (data && data.caseFlows && data.caseFlows[0]) {
      setFlow(data.caseFlows[0])
    }
    if (data && data.caseDetail) {
      setCaseData(data.caseDetail)
    }
  }, [data])

  if (loading)           return <p>Loading...</p>
  if (error)             return <p>`Error! {error.message}`</p>
  if (flow === null)      return <p>This case does not have any flows associated with it.</p>
  if (caseData === null) return <p>No such case.</p>

  return (
    <div
      style={{
        margin: '0 auto 0 auto',
        maxWidth: 1024
      }}
    >
      <div
        style={{
          marginTop: 40,
          marginBottom: 40
        }}
      >
        <CaseDetail data={caseData}/>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

        }}
      >
        <div
          style={{
            width: '50%',
            paddingRight: 10,
          }}
        >
          <H2>{flow.title ? flow.title : `Flow #${flow.template.id}`}</H2>

          <SampleTrackingCase
            flowData={flow}
          />
        </div>

        <div
          style={{
            width: '50%',
            paddingLeft: 10
          }}
        >
          <H2>Case Notes</H2>

          <div style={{marginTop: 20, marginBottom: 30}}>
            {
              (notes.length > 0) && (
                notes.map((note, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: 12,
                        position: 'relative',
                        paddingLeft: 30
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          opacity: 0.5
                        }}
                      >
                        <Icon icon={'circle'}/>
                      </div>

                      <p>
                        {note.text}<br/>
                        <span style={{fontSize: 12}} className={Classes.TEXT_MUTED}>
                          By: {note.user} | {moment(note.createdAt).format("MM/DD/YYYY HH:mm")}
                        </span>
                      </p>
                    </div>
                  )
                })
              )
            }
          </div>

          <div>
            <div style={{marginTop: 20}}>
              <EditableText
                maxLength={255}
                maxLines={12}
                minLines={3}
                multiline={true}
                placeholder="Enter here..."
                selectAllOnFocus={false}
                confirmOnEnterKey={true}
                value={currentNote}
                onChange={(value) => setCurrentNote(value)}
                onConfirm={(value) => saveNote(value)}
              />
            </div>
          </div>

          <div style={{marginTop: 30}}>
            <H2>Attachments</H2>

            {
              caseData && <Attachment clinicId={4} />
            }
          </div>
        </div>
      </div>


    </div>
  )
}

export default SampleTrackingCaseContainer
