import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import SampleTracking from "components/SampleTracking/SampleTracking";
import LoadingOverlay from "../../components/Common/Loading/LoadingOverlay";
import {useHistory} from "react-router-dom";


export interface Flow {
  id: number;
  title: string;
  description: string;
  isTemplate: boolean;
  createdAt: string;
  updatedAt: string;
  template: object;
  steps: FlowStep[];
}

export interface FlowStep {
  id: number;
  title: string;
  description: string;
  required: boolean;
  order: number;
}

export interface Flows {
  flows: Flow[]
}

export const GET_FLOWS = gql`
    query flowList {
        flows {
            id
            title
            description
            isTemplate
            createdAt
            template {
                id
            }
        }
    }
`;


const CREATE_FLOW = gql`
    mutation createFlow($isTemplate: Boolean!) {
        createFlow(isTemplate: $isTemplate) {
            flow {
                id,
                title,
                description,
                createdAt,
                steps {
                    id
                    title
                    description
                    required
                }
            }
        }
    }
`;

const DELETE_FLOW = gql`
    mutation deleteFlow($id: ID!) {
        deleteFlow(id: $id) {
            ok,
            nextId
        }
    }
`;

const UPDATE_FLOW = gql`
    mutation updateFlow($input: FlowInput!) {
        updateFlow(input: $input) {
            flow {
                id,
                title
            }
        }
    }
`;

const Tracking = () => {
  const history = useHistory();
  const {loading, error, data} = useQuery(GET_FLOWS, {
    fetchPolicy: 'network-only',
  });

  const [createFlow, {loading: createFlowLoading, error: createFlowError}] = useMutation(CREATE_FLOW, {
    update: (cache, {data}) => {
      const existingFlows = cache.readQuery<Flows>({query: GET_FLOWS});
      const newFlow = data?.createFlow.flow;

      if (existingFlows && newFlow) {
        cache.writeQuery({
          query: GET_FLOWS,
          data: {
            flows: [
              newFlow,
              ...existingFlows?.flows
            ]
          }
        })

        history.push(`/tracking/flow/${newFlow.id}/`);
      }
    }
  });

  const [updateFlow, {loading: updateFlowLoading}] = useMutation(UPDATE_FLOW, {
    refetchQueries: [
      {
        query: GET_FLOWS
      }
    ]
  });

  const [deleteFlow, {loading: deleteFlowLoading}] = useMutation(DELETE_FLOW, {
    onCompleted: (resp) => {
        if (resp && resp.deleteFlow) {
          if (resp.deleteFlow.nextId) {
            history.push(`/tracking/flow/${resp.deleteFlow.nextId}/`);
          } else {
            // TODO: Redirect to "No Flow View"
          }
        }
    },
    refetchQueries: [
      {
        query: GET_FLOWS
      }
    ]
  });
  if (error) return <p>Error fetching flows: {error.message}</p>
  return (
    <div>
      <LoadingOverlay show={loading || createFlowLoading || updateFlowLoading || deleteFlowLoading}/>
      {
        (data && data.flows) && (
          <SampleTracking
            items={data.flows}
            loading={loading || createFlowLoading}
            onCreateNewFlow={() => createFlow({variables: {isTemplate: true}})}
            onDeleteFlow={(id: number) => {
              if (window.confirm('Are you sure')) {
                deleteFlow({variables: {id: id}});
              }
            }}
            onUpdateFlow={(id: number, patchData: any) => {
              updateFlow({
                variables: {
                  input: {
                    id: id,
                    patch: patchData
                  }
                }
              })
            }}
          />
        )
      }
      {createFlowError && <p>Error creating new flow: {createFlowError.message}</p>}
    </div>
  )
}


export default Tracking;
