import React, {useEffect} from 'react';

const DownloadInBrowser  = ( { downloadFile, link }) => {

    useEffect(() => {
      if (link) {
          downloadFile(link)
      }
    }, [downloadFile, link]);

    return <div>hidden div</div>
};

export default DownloadInBrowser;