import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, Card, Checkbox } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import {useQuery, gql} from '@apollo/client';
import { Row, Col } from "react-flexbox-grid";
import styled from 'styled-components';
import LoadingOverlay from "components/Common/LoadingOverlay";
import KPIContainer from '../../containers/KPI';
import SearchBar from "../../components/Metrics/SearchBar";
import moment from 'moment';
import '../../components/Metrics/styles.css';

const PROVIDERS = gql`
  query clinicSampleList {
    clinicSampleList {
      id
      companyName
    }
  }
`;

const KPI = () => {

  const [showModal, setShowModal] = useState(false);
  const [week, setWeek] = useState('');
  const [companyName, setCompanyName] = useState(null);
  const [selectAllClinics, setSelectAllClinics] = useState(true);
  const [clinicId, setClinicId] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [keyword, setKeyword] = useState('');
  const [newList, setNewList] = useState([]);

  
  const shortcuts =[
    {label: 'Current Quarter', dateRange: [moment().startOf('quarter').toDate(), moment().endOf('quarter').toDate()]}, 
    {label: 'Past Week', dateRange: [moment().subtract(1, 'weeks').toDate(), moment().toDate()]}, 
    {label: 'Past Month', dateRange: [moment().subtract(1, 'months').toDate(), moment().toDate()]},
    {label: 'Past 3 Months', dateRange: [moment().subtract(3, 'months').toDate(), moment().toDate()]}, 
    {label: 'Past 6 Months', dateRange: [moment().subtract(6, 'months').toDate(), moment().toDate()]},
    {label: 'Past Year', dateRange: [moment().subtract(1, 'years').toDate(), moment().toDate()]}
  ]; 

  console.log('here' , dateRange);

  const Title = styled.h1`
    text-align: center;
    font-size: 3.5em;
    margin: 0;
  `;
  const Subtitle = styled.h1`
    font-size: 1.5em;
    color: #602A48;
    margin: 0;
  `;

  useEffect(()=>{
    const now = moment().format("ddd, MMM Do YYYY");
    setWeek(now);

}, []);

//this needs to be here: there are cases (like anova) where the same clinic has been entered twice into the database by mistake.
const removeDuplicates = (originalArray, prop) => {
  const newArray = [];
  const lookupObject  = {};

  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
    console.log('lookup obj', lookupObject)

  }

  for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
  return newArray;
};

  const {loading, error, data } = useQuery(PROVIDERS);


  useEffect (() => {
    if (!loading && data) {
      setNewList(removeDuplicates(data.clinicSampleList, "companyName"))
    }
  }, [loading, data]);


  if (loading) return 'Loading...';
  if (error) return `Error: ${error.message}`;

  const handleCheck = () => {
    // if (selectAllClinics) {
    //   setCompanyName(null);
    //   setClinicId(null);
    //   console.log('comp name', companyName)
    // }
    if (companyName) {
      setCompanyName(null);
      setClinicId(null);
      setSelectAllClinics(!selectAllClinics)
    }
    if (!companyName) {
      setSelectAllClinics(!selectAllClinics);
    }
    // refetch()
  }

  const handleChange = async (keyword) => {
    setSelectAllClinics(false);
    console.log('keyword', keyword)
    const filtered =  await removeDuplicates(data.clinicSampleList, "companyName").filter(clinic => {
      return clinic.companyName.toLowerCase().includes(keyword.toLowerCase())
      });
      setKeyword(keyword);
      setNewList(filtered);
  }


  const handleClickMain = (name, id) => {
      setCompanyName(name);
      setClinicId(id);
      setSelectAllClinics(false);
      setKeyword('');
      setNewList(removeDuplicates(data.clinicSampleList, "companyName"));
  }

  console.log('newList', newList)
  console.log(dateRange)

  return (
      <div className="kpiPage">
        <LoadingOverlay show={loading}/>
        <Title>KPI Report</Title>
              <Row className="subheader" between="xs">
                    <Subtitle>
                      {companyName ? companyName : "Clinics"}
                    </Subtitle>

                  {!showModal && <Button id="calendarBtn" className="bp3-button" icon="calendar" text={`Week of ${week}`}onClick={()=>setShowModal(!showModal)} />}
                  {showModal &&
                      <Card className="dateRangeCard">
                         <DateRangeInput
                            className="datePickerInput"
                            maxDate={new Date()}
                            formatDate={date => moment(date).format('MM/DD/YYYY')}
                            onChange={(range) => setDateRange(range)}
                            parseDate={str => moment(str, 'MM/DD/YYYY').toDate()}
                            value={dateRange}
                            timePickerProps={undefined}
                            format={'MM/DD/YYYY'}
                            shortcuts={shortcuts}
                            onShortcutChange={(shortcut) => setDateRange(shortcut.dateRange)}
                          />
                      </Card>
                  }
              </Row>
              <Row between="xs">
                  <Col xs={12} lg={12}>

                    <div className="clinicControls">

                      <Checkbox
                        inline={true}
                        label="Select All Clinics"
                        onChange={()=>handleCheck()}
                        large={false}
                        checked={selectAllClinics}
                        />

                        <SearchBar onChange={handleChange} data={data} value={keyword} />
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={3} lg={3}>
                    <Card >
                      <Menu text="Clinic Name" className="providerMenu">
                          {newList.map((provider) => (
                            <MenuItem text={provider.companyName} key={provider.id} onClick={()=>handleClickMain(provider.companyName, provider.id)} />
                          ))
                          // :
                          // data.clinicSampleList.map((provider)=>(
                          //     <MenuItem text={provider.companyName} key={provider.id} onClick={()=>handleClickMain(provider.companyName, provider.id)} />
                          // ))
                          }
                      </Menu>
                    </Card>
                  </Col>
                  {(companyName || selectAllClinics) && ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1]))?
                    <>
                    <KPIContainer clinicId={clinicId} companyName={companyName} dateRange={dateRange[0] && dateRange[1] ? dateRange : [moment().toISOString(), moment().subtract(1, 'weeks').toISOString()]} setSelectAllClinics={setSelectAllClinics} setCompanyName={setCompanyName} setClinid={setClinicId} selectAllClinics={selectAllClinics} handleCheck={handleCheck}/>
                    </>
                  : null }
          </Row>
      </div>
  )
}

export default KPI;
