import React, {FunctionComponent, useState} from "react"
import {Button, Card, Classes, Dialog, H6} from "@blueprintjs/core";
import StatusStripe from "./StatusStripe";
import StepTypeIndicator from "./StepTypeIndicator";
import RequiredIndicator from "./RequiredIndicator";
import StepForm from "./StepForm";
import moment from "moment";


type ComponentProps = {
  stepData: any | null
}


const FlowStep: FunctionComponent<ComponentProps> = ({stepData}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  if (stepData === null) return <p>Step data does not exist.</p>

  return (
    <div
      style={{
        marginTop: 15,
        marginBottom: 15,
        position: 'relative'
      }}
    >
      <Card
        interactive
        onClick={() => setDialogIsOpen(true)}
      >
        <StatusStripe
          started={stepData.started}
          completed={stepData.completed}
          canceled={stepData.canceled}
        />

        <RequiredIndicator isRequired={stepData.required}/>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <div style={{paddingRight: 6}}>
            <StepTypeIndicator stepType={stepData.type}/>
          </div>

          <div>
            <H6 style={{margin: 0}}>{stepData.title}</H6>
          </div>

          <div>
            {
              stepData.completedAt && stepData.completedAt
            }
          </div>


        </div>

        {
          (stepData.notes && stepData.notes.length > 0) && (
            <div style={{
              marginTop: 15,
              fontSize: 12
            }}>
              {
                stepData.notes.map((note: any) => {
                  return (
                    <div style={{marginTop: 6}}>
                      <div style={{color: '#969696'}}>
                        {note.userName} | {' '}
                        {moment(note.createdAt).format("MM/DD/YYYY HH:mm")}
                      </div>
                      {note.content}
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </Card>


      <Dialog
        icon="tick-circle"
        onClose={() => setDialogIsOpen(false)}
        title={stepData.title}
        isOpen={dialogIsOpen}
        style={{position: 'relative'}}
      >
        <div className={Classes.DIALOG_BODY}>
          <div style={{paddingTop: 10, paddingBottom: 40}}>
            <StepForm
              data={stepData}
              onChange={(data) => {

              }}
            />
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setDialogIsOpen(false)}>Close</Button>
            {/*<Button onClick={() => setDialogIsOpen(false)} intent={Intent.PRIMARY}>Save</Button>*/}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default FlowStep
