import React from "react";
import {Overlay, Spinner, Classes} from "@blueprintjs/core";


export default (props) => {
  const {isOpen, onClose} = props;

  return (
    <div>
      <Overlay
        onClose={onClose}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
        isOpen={isOpen}
        backdropProps={{
          style: {
            background: 'rgba(255,255,255,0.72)'
          }
        }}
      >
        <div style={{
          color: '#ffffff',
          height: '100%',
          position: 'relative'
        }}>
          <div style={{
            position: 'relative',
            top: '50%',
            marginTop: -16,
            fontSize: 24
          }}>
            <Spinner size={42}/>
          </div>
        </div>
      </Overlay>
    </div>
  )
}
