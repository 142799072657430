import React, {useState, useEffect} from 'react';
import {ResponsiveLine} from "@nivo/line";

const ITEMS = ['All', 'PGT-A', 'PGT-M', 'PGT-SR', 'PGT-P', 'M2'];

export default (props) => {
  const [data, setData] = useState(props.data);
  const [visibleItems, setVisibleItems] = useState(ITEMS);

  useEffect(() => {
    const newData = props.data.slice()
    setData(newData.map(item => {
      return ({
        ...item,
        color: !visibleItems.includes(item.id) ? 'transparent' : item.color
      })
    }))
  }, [visibleItems, props.data])

  return (
    <ResponsiveLine
      data={data}
      curve="natural"
      margin={{top: 50, right: 110, bottom: 50, left: 60}}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day',
        // tickValues: 'every week'
      }}
      xFormat="time:%m/%d/%Y"
      yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%m/%y',
        tickValues: 'every 360 days',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'count',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      colors={{ datum: 'color' }}
      enableArea={false}
      enablePoints={false}
      pointSize={4}
      pointBorderColor="#fff"
      pointBorderWidth={1}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ],
          onClick: (e) => {
            let newItems = visibleItems.slice();

            if (visibleItems === ITEMS) {
              newItems = [];
            }

            if (!newItems.includes(e.id)) {
              newItems.push(e.id)
            } else {
              newItems.splice(newItems.indexOf(e.id), 1);
            }

            if (newItems.length === 0) {
              newItems = ITEMS;
              newItems.splice(newItems.indexOf(e.id), 1);
            }

            setVisibleItems(newItems);
          }
        }
      ]}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        let xVal = '';

        try {
          xVal = slice.points[0].data.xFormatted;
        } catch (e) {
          console.log(e)
        }

        return (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <div>x: {xVal}</div>
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong>{point.serieId}</strong> [{point.data.yFormatted}]
              </div>
            ))}
          </div>
        )
      }}
    />
  )
}
