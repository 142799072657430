//Note: commented out code calculates the percentages between previous and current period.
//There has been a request to display the absolute differences between these metrics instead -- keeping old code commented in case there is a request to display the percentages at some point in the future.
import React from 'react';
import LoadingOverlay from '../Common/LoadingOverlay';
import { Icon } from '@blueprintjs/core';
import './styles.css'
// import styled from 'styled-components';

const KPIRowBox = ({ data, loading, error, title, name, previous, stat, referenceRange }) => {

    const plusMinus = '\u00B1';
    // const infinity = `\u221e`;

    const setDelta = (stat, previous) => {
      let diff;
      if (previous && stat !== null) {
        //remove scientific notation/extra decimal places:
        diff = Math.round((stat - previous) * 100);
      }
      else if (!previous || stat === null) {
        diff = undefined;
      }
      else {
        console.log('error in calculation:', stat, previous)
      }
      return (
              <>
              {diff !== undefined ?
                <div>
                  <h4 style={{display: 'inline'}}>{diff <= 0? diff : `+${diff}`}</h4>
                  {
                    diff > 0 &&
                   <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
                  }
                  {
                    diff < 0 &&
                    <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
                  }
                </div>
              : null
               }
              </>
              )
    }
    // const setDelta = (stat, previous) => {
    //     let diff;
    //     let deltaNum;
    //     let deltaPercent;
    //     let abs;

    //     if (previous !== null) {
    //       if (previous === 0 && stat > 0) {
    //         deltaNum = Infinity;
    //         deltaPercent = Infinity;
    //       }
    //       else if (previous > 0 && stat === 0) {
    //         deltaNum = -Infinity;
    //         deltaPercent = -Infinity;
    //       }
    //       else if (previous > 0 && stat === null) {
    //         deltaNum = -Infinity;
    //         deltaPercent = -Infinity;
    //       }
    //       else {
    //         diff = stat - previous;
    //         console.log('diff', diff)
    //         deltaNum = previous === 0 && stat === 0 ? 0 : diff/previous;
    //         deltaPercent = parseFloat((100 * deltaNum).toPrecision(2));
    //         abs = Math.abs(deltaPercent)
    //         console.log('stat', stat)
    //         console.log('previous', previous);
    //         console.log('deltaNym', deltaNum)
    //         console.log('deltaPercent', isFinite(deltaNum));
    //         console.log('abs', abs);
    //       }
    //     }
    //     else if (previous === null && stat !== null) {
    //       diff = stat;
    //       deltaNum = diff/0;

    //     }
    //     else if (previous === null && stat === null) {
    //       deltaNum = 0;
    //       deltaPercent = 0;
    //       abs = 0;
    //     }
    //     else {
    //       console.log('error in stat:' , stat, "previous:" , previous)
    //     }
    //     return (
    //       <>
    //       {isFinite(deltaNum) ?
    //         <div>
    //           <h4 style={{display: 'inline'}}>{abs}%</h4>
    //           {
    //             deltaPercent > 0 &&
    //            <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
    //           }
    //           {
    //             deltaPercent < 0 &&
    //             <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
    //           }
    //         </div>
    //       : <div>
    //          <p style={{display: 'inline'}}>{infinity}</p>
    //          {
    //             deltaNum === Infinity &&
    //             <Icon icon="symbol-triangle-up" size={10} style={{color: 'green'}}/>
    //          }
    //          {
    //             deltaNum === -Infinity &&
    //             <Icon icon="symbol-triangle-down" size={10} style={{color: 'red'}} />
    //          }
    //         </div>
    //        }
    //       </>
    //       )

    // }

    if (loading) return <div><LoadingOverlay isOpen={loading}/></div>
    if (error) return <div>{`Error: ${error.message}`}</div>
    return (
        <div style={{width: '100%'}}>
            <div className="statContainer">
                <h1 className={`statHeader-${name}`}>{parseFloat((stat * 100).toPrecision(2))}% </h1>
                <span className="deltaHistory">{data && setDelta(stat, previous)}</span>
            </div>
            <div className="refContainer">
                <h3 className="refHeader">{title}</h3>
                <h4 className="refRange">Reference Range:</h4>
                <h4 className="refRange">{referenceRange !== null ? `${parseFloat(referenceRange[0] * 100).toPrecision(2)} ${plusMinus} ${parseFloat(referenceRange[1] * 100).toPrecision(2)}` : "N/A"}</h4>
            </div>
        </div>



    )
}
export default KPIRowBox;