import React from 'react';
import {Card, Elevation, H5} from "@blueprintjs/core";
import {Row, Col} from "react-flexbox-grid";
import { ResponsiveBar } from '@nivo/bar'


export default (props) => {
  const {data} = props;
  let clinics = data ? data.clinicSampleList : [];

  clinics = clinics.filter((item) => item.samplesWithResultsCount > 0)

  return (
    <div>
      <div style={{marginBottom: 20}}>
        <Row between="xs">
          <Col xs>
            <H5>

            </H5>
          </Col>

          <Col xs={1}>

          </Col>
        </Row>
      </div>

      <Card interactive={true} elevation={Elevation.TWO}>
        <div className="card-body">
          <div style={{height: 900}}>
            <ResponsiveBar
              layout="horizontal"
              data={clinics}
              keys={['samplesWithNoResultsCount', 'samplesEuploidCount', 'samplesAneuploidCount', 'samplesFailedCount']}
              // keys={[ 'all_samples_count' ]}
              indexBy="companyName"
              margin={{ top: 50, right: 200, bottom: 50, left: 260 }}
              padding={0.3}
              colors={{ scheme: 'nivo' }}

              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}
