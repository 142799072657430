import React from 'react';
import { InputGroup } from '@blueprintjs/core';
import './styles.css';

const SearchBar = ({ onChange, value }) => {


 const handleChange = (e) => {
     onChange(e.target.value)
 }

    return (
        <div style={{width: '25%'}}>
        <InputGroup
        // className="bp3-large"
        type="search"
        fill={true}
        value={value}
        placeholder="search clinics"
        onChange={handleChange}
        leftIcon="search"
        />
        </div>
    )
}
export default SearchBar;