import React, { useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from 'components/Report/PDFDocument';

/**
 * <Preview> component - Display PDF Report in browser
 * @param {object} data - {case data, selectPanel, diseases, language}
 *                         see CASES in components/Report/index.js for case data fields
 * @param {{all: *[], euploid: *[], aneuploid: *[], failed: *[]}} sampleData - arrays of sample data
 * @param {string} title - 'Report.pdf'   or    case-id + '.pdf'
 * @returns {false|JSX.Element}
 */
const Preview = ({ data, sampleData, title }) => {
    const [show, setShow] = useState(false);

    setTimeout(() => {
        setShow(true)
    }, [1000])

    return !!show && (
        <PDFViewer
        style={{
            width: '100%',
            height: '100%',
            border: 'none',
        }}
        >
            <PDFDocument data={data} sampleData={sampleData} title={title} />
        </PDFViewer>
    )
};

export default Preview
