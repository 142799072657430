import React, {useState, useEffect} from 'react';
import {Button, Callout, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {useMutation, gql} from '@apollo/client';
import {SET_AUTH} from 'services/graphql/auth';


const AUTHENTICATE = gql`
    mutation TokenAuth($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
            token
            payload
            refreshExpiresIn
        }
    }
`


export default (props) => {
  const [tokenAuth, { loading: mutationLoading, error: mutationError, data: mutationData, client }] = useMutation(AUTHENTICATE);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('')
  const [displayError, setDisplayError] = useState(null);
  
  useEffect(() => {
    if (mutationData) {
      const token = mutationData.tokenAuth.token;

      if (!!token) {
        localStorage.setItem('authToken', token);
        client.cache.writeQuery({
          query: SET_AUTH,
          data:
            {
              auth: {
                isAuth: true,
                __typename: 'Auth'
              }
            }
        });
      }
    }
  }, [mutationData, client.cache])
  if (mutationError) return <p>{`Error logging in: ${mutationError.message}`}</p>
  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          setDisplayError(null);
          tokenAuth({variables: {username: username, password: password}}).catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
              setDisplayError(e.graphQLErrors[0].message);
            }
            if (e.networkError) {
              setDisplayError('Network Error');
            }
          });
          setUsername('');
          setPassword('');
        }}
      >
        <FormGroup
          label="Username"
          labelFor="username"
          labelInfo="(required)"
          disabled={mutationLoading}
        >
          <InputGroup
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="Password"
          labelFor="password"
          labelInfo="(required)"
          disabled={mutationLoading}
        >
          <InputGroup
            id="password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>

        <Button
          text="Submit"
          type="submit"
          loading={mutationLoading}
        />

        {
          displayError && (
            <div style={{marginTop: 20}}>
              <Callout
                intent={Intent.DANGER}
              >
                {displayError}
              </Callout>
            </div>
          )
        }
      </form>
    </div>
  )
}
