import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CLINIC, CLINIC_DETAIL } from './Helpers/gql';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { ClinicFormToaster } from './Toaster';
import  EditSwitch  from './EditSwitch';
import { Button, RadioGroup, Radio, Checkbox } from '@blueprintjs/core';
import { FormContext } from './Context';


const Workflows = ({ data, id }) => {
    let currentLocation = useLocation();
    let history = useHistory();

    const [state, dispatch] = useContext(FormContext);
    const [values, setValues] = useState({
        pgtAConsent: "",
        gc: "",
        reportInEmail: false,
        reportInPortal: false,
        includeSsf: false
    });

    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: id
                }
            }    
        ], 
        onCompleted(){
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0, 0);
        }
    });
    console.log(state.dirtyFields);
    useEffect(() => {
        if (data) {
            const { clinicDetail } = data;
            setValues(prev => ({
                ...prev,
                pgtAConsent: clinicDetail.pgtAConsent === true ? "Yes" : "No", 
                gc: clinicDetail.gc === true ? "Yes" : "No",
                reportInEmail: clinicDetail.reportInEmail || false,
                reportInPortal: clinicDetail.reportInPortal || false,
                consentLanguage: (clinicDetail.consentLanguage && clinicDetail.consentLanguage.includes('Other')) ? 'Other' : clinicDetail.consentLanguage,
                consentLanguageOther: (clinicDetail.consentLanguage && clinicDetail.consentLanguage.includes('Other')) ? clinicDetail.consentLanguage.slice(7) : "",
                consentVersion: clinicDetail.consentVersion, 
                includeSsf: clinicDetail.includeSsf || false
            }))
        }                
    }, [data]);
   
    const handleChange = (e) => {
        
        const { value, name, type } = e.target;
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        });
        if (type === 'checkbox') {
            console.log(values[name])
            if (values[name] === true) setValues({...values, [name]: false})
            else setValues({...values, [name]: true})
        }
        else setValues({...values, [name]: value})
    };
    
    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };

    const handleSubmit = e => {
        e.preventDefault();
        updateClinic({
            variables: {
                id: id, 
                input: {
                    pgtAConsent: values.pgtAConsent === "Yes" ? true : false,
                    gc: values.gc === "Yes" ? true : false,
                    reportInEmail: values.reportInEmail,
                    reportInPortal: values.reportInPortal,
                    consentLanguage: values.consentLanguage === 'Other' && values.consentLanguageOther.length ?  `Other: ${values.consentLanguageOther}` : values.consentLanguage,
                    consentVersion: values.consentVersion, 
                    includeSsf: values.includeSsf

                }
            }
        });
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                {updateError && <p>Error updating information: {updateError.message}</p>}
                <EditSwitch />
                <RouteLeavingGuard 
                    when={Object.keys(state.dirtyFields).length > 0}
                    navigate={path=>history.push(path)}
                    shouldBlockNavigation={location=>{
                        if (Object.keys(state.dirtyFields).length > 0) {
                            if (location.pathname !== currentLocation.pathname) {
                                return true
                            }
                        }
                        return false
                    }}
                />
                <h1 className="form-header">Workflows:</h1>
                <h3>Consent:</h3>
                    <RadioGroup 
                            disabled={!state.isEditing}
                            label="Will Genomic Prediction be consenting the patient for PGT-A testing?"
                            selectedValue={values.pgtAConsent}
                            name="pgtAConsent"
                            onChange={handleChange}
                    >
                        <Radio value="Yes" label="Yes" />
                        <Radio label="No" value="No" />
                    </RadioGroup>
                    <h3>GC:</h3>
                    <RadioGroup 
                        disabled={!state.isEditing}
                        label="Genetic Counseling:"
                        name="gc"
                        selectedValue={values.gc}
                        onChange={handleChange}
                        
                    >
                        <Radio value="Yes" label="Yes" />
                        <Radio label="No" value="No" />
                    </RadioGroup>
                    <h3>Reporting Preferences:</h3>
                    <Checkbox 
                        disabled={!state.isEditing}
                        checked={values.includeSsf}
                        label="Include Sample Submission Form with Report"
                        name="includeSsf"
                        onChange={handleChange}
                    />
                    <Checkbox 
                        disabled={!state.isEditing}
                        checked={values.reportInEmail}
                        label="Report in Email:"
                        name="reportInEmail"
                        onChange={handleChange}
                    />
                     <Checkbox 
                        disabled={!state.isEditing}
                        checked={values.reportInPortal}
                        label="Report in Portal"
                        name="reportInPortal"
                        onChange={handleChange}
                    />
                    <Button intent="primary" type="submit" disabled={updateLoading}>Save</Button>
            </form>
        </div>
    )
};

export default Workflows;