import React from 'react';
import {Text, View} from "@react-pdf/renderer";


const CircosIndexContent = ({data, width, height}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >

      <View style={{
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <View>
          <Text
            style={{
              fontSize: 10
            }}
          >
            Embryo
          </Text>
        </View>

        <View>
          <Text
            style={{
              fontSize: 10
            }}
          >
            Health Score
          </Text>
        </View>
      </View>

      <View style={{flexShrink: 0, paddingVertical: 6}}>
        <Text
          style={{
            fontSize: 22,
            fontFamily: 'RobotoMono'
          }}
        >
          {data.index ? data.index : '-'}
        </Text>
      </View>

      <View
        style={{
          width: width / 2.8,
          height: 1,
          borderTop: '1 solid #000000'
        }}
      />

      <View style={{flexShrink: 0, paddingTop: 3}}>
        <Text
          style={{
            fontSize: 10
          }}
        >
          {data.karyotype}
        </Text>
      </View>
    </View>
  )
}


export default CircosIndexContent;
