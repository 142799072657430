import React, { useContext } from 'react';
import { FormContext } from './Context';
import { Switch } from '@blueprintjs/core';


const EditSwitch = () => {
    const [state, dispatch] = useContext(FormContext);
    const handleSwitch = () => {
        dispatch({
            type: "SET_EDITING"
        })
    };
    return (
        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '2%', marginTop: '2%' }}>
            <Switch checked={state.isEditing} label="Editing Mode" onChange={handleSwitch} inline={true}></Switch> 
        </div>
    )
};

export default EditSwitch;