import React from 'react';
import QReviewContainer from "containers/QReview";


const QReviewPage = (props) => {
  return (
    <div>
      <QReviewContainer/>
    </div>
  )
}

export default QReviewPage;
