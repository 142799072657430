import React, {useState, useEffect} from 'react';
import {useQuery, gql} from '@apollo/client';
import {Card, Checkbox, H1, H5, Tag} from "@blueprintjs/core";
import CaseVolumePlot from 'components/Stats/CaseVolumePlot';


const COLORS = {
  'PGT-A': 'hsl(189,80%,68%)',
  'PGT-M': 'hsl(271,79%,71%)',
  'PGT-P': 'hsl(327,92%,66%)',
  'PGT-SR': 'hsl(40,92%,64%)',
  'M2': 'hsl(97,80%,67%)',
  'All': 'hsl(209,19%,78%)',
}


const CASE_VOLUME = gql`
    query caseVolume($filterCommand: String) {
        caseVolume(filterCommand: $filterCommand){
            id,
            data {
                x,
                y
            }
        }
    }
`;


export default (props) => {
  const [plotData, setPlotData] = useState(null);
  const [totalVolume, setTotalVolume] = useState(null);
  const [filterCommand, setFilterCommand] = useState(null);

  const {loading, error, data} = useQuery(CASE_VOLUME, {
    fetchPolicy: 'no-cache',
    variables: {
      filterCommand: filterCommand,
    },
  });

  useEffect(() => {
    if (data) {
      if (data.caseVolume.length > 0) {
        setPlotData(
          data.caseVolume.map((item) => {
            const id = item.id;
            const volumes = item.data;

            return ({
              id: id,
              color: COLORS[id],
              data: volumes
            })
          })
        );

        setTotalVolume(
          data.caseVolume.map((item) => {
            const id = item.id;
            const volumes = item.data;

            return ({
              id: id,
              color: COLORS[id],
              data: volumes.map((item, index) => {
                const t = volumes.slice(0, index + 1);
                const ts = t.reduce((a, b) => a + (b['y'] || 0), 0)

                return (
                  {
                    x: item.x,
                    y: ts
                  }
                )
              })
            })
          })
        );
      }
    }
  }, [data])

  if (error) return <div>Error: {error}</div>
  return (
    <div>
      <div style={{paddingTop: 30, paddingBottom: 10}}>
        <H1>
          Case Volume
        </H1>
      </div>

      <div>
        <div style={{visibility: loading ? 'visible' : 'hidden'}}>
          <Tag large minimal round>Loading...</Tag>
        </div>
      </div>

      <div style={{margin: '20px 0'}}>
        <Checkbox
          checked={filterCommand === 'completed_only'}
          label={'Completed Only'}
          inline={true}
          onChange={(e) => setFilterCommand(e.target.checked ? 'completed_only' : null)}
          style={{margin: 0}}
        />
      </div>

      <div>
        <Card>
          <H5>New Cases</H5>
          <div style={{height: 400}}>
            {
              plotData && (
                <CaseVolumePlot data={plotData}/>
              )
            }
          </div>
        </Card>
      </div>
      <div style={{marginTop: 40}}>
        <Card>
          <H5>Accumulative Volume</H5>
          <div style={{height: 400}}>
            {
              totalVolume && (
                <CaseVolumePlot data={totalVolume}/>
              )
            }
          </div>
        </Card>
      </div>
    </div>
  )
}
