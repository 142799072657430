import React from 'react';
import {View} from "@react-pdf/renderer";


export default (props) => {
  return (
    <View>
      {props.children}
    </View>
  )
}
