import React, {FunctionComponent, useState} from 'react';
import {useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import Step from "./FlowPreview/Step";
import StepDetailView from './FlowPreview/StepDetailView';

const GET_FLOW_BY_ID = gql`
    query flowById($id: ID) {
        flowById(id: $id) {
            id
            title
            description
            isTemplate
            createdAt
            template {
                id
            }
            steps {
                id
                title
                description
                required
                alertOnStart
                alertOnComplete
                alertBeforeDeadline
                alertOnDeadline
                alertAfterDeadline
                alertOnNote
                type
                durationDays
                completed
                active
                canceled
                started
                subFlow {
                    id
                    title
                    steps {
                        id
                        title
                        description
                        required
                        alertOnStart
                        alertOnComplete
                        alertBeforeDeadline
                        alertOnDeadline
                        alertAfterDeadline
                        alertOnNote
                        type
                        durationDays
                        completed
                        active
                        canceled
                        started
                    }
                }
            }
        }
    }
`;

const UPDATE_STEP = gql`
    mutation updateFlowStep($input: StepInput!) {
        updateFlowStep(input: $input) {
            flowStep {
                id
                title
                description
                required
                alertOnStart
                alertOnComplete
                alertBeforeDeadline
                alertOnDeadline
                alertAfterDeadline
                alertOnNote
                type
                durationDays
                completed
                active
                canceled
                started
            }
        }
    }
`;

type ComponentProps = {
  flowId?: string
  detailView?: boolean
}

const FlowPreview: FunctionComponent<ComponentProps> = ({flowId= null, detailView= false}) => {
  const [stepOpen, setStepOpen] = useState<number | null>(null);

  const params: any = useParams();
  if (params.flowId) {
    flowId = params.flowId
  }

  const [updateFlowStep, {loading: updateFlowStepLoading}] = useMutation(UPDATE_STEP, {
    update: () => {
      setStepOpen(null)
    }
  });

  const update = (data: any) => {
    const id = data.id
    delete data['id']
    delete data['__typename']
    const {started, completed, canceled} = data;
    // const patch = data
    updateFlowStep({
      variables: {
        input: {
          id: id,
          patch: {
            started,
            completed,
            canceled
          }
        }
      }
    })
  }

  const {loading, error, data} = useQuery(GET_FLOW_BY_ID, {
    fetchPolicy: 'network-only',
    // pollInterval: 2000,
    variables: {
      id: flowId ? parseInt(flowId) : null
    },
  });

  let flowById: any = null

  if (data) {
    flowById = data.flowById
  }

  if (loading || updateFlowStepLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error...</p>
  }

  return flowById ? (
    <div>
      {
        detailView ? (
          <div>
            {
              flowById.steps && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  {
                    flowById.steps.map((step: any, stepIndex: number) => {
                      return (
                        <div key={`step-detail-${stepIndex}`}>
                          <StepDetailView
                            data={step}
                            onUpdate={update}
                            showTitle={detailView}
                            showArrow={false}
                            openID={stepOpen}
                            onClose={(id: number) => setStepOpen(null)}
                            onOpen={(id: number) => setStepOpen(id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        ) : (
          <div>
            {
              flowById.steps && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  {
                    flowById.steps.map((step: any, stepIndex: number) => {
                      return (
                        <div key={`flow-by-id-step-${stepIndex}`}>
                          <Step
                            data={step}
                            onUpdate={update}
                            showTitle={detailView}
                            showArrow={(stepIndex + 1) !== flowById.steps.length}
                            openID={stepOpen}
                            onClose={(id: number) => setStepOpen(null)}
                            onOpen={(id: number) => setStepOpen(id)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        )
      }

    </div>
  ) : (
    <div>No data</div>
  )
}

export default FlowPreview
