import React, {FunctionComponent} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {HTMLSelect} from "@blueprintjs/core";
import FlowPreview from "../SampleTracking/FlowPreview";


type ComponentProps = {
  caseId: number,
  allFlows?: any[]
}


const GET_CASE_FLOWS = gql`
    query caseFlows($caseId: ID) {
        caseFlows(caseId: $caseId) {
            id,
            template {
                id
            }
        }
    }
`;

const CLONE_FLOW = gql`
    mutation cloneFlowToCase($caseId: ID!, $flowId: ID) {
        cloneFlowToCase(caseId: $caseId, flowId: $flowId) {
            flow {
                id,
                title,
                description,
                createdAt,
                steps {
                    id
                    title
                    description
                    required
                }
            }
        }
    }
`;


const Tracking: FunctionComponent<ComponentProps> = ({caseId, allFlows = []}) => {

  const {loading, error, data} = useQuery(GET_CASE_FLOWS, {
    fetchPolicy: 'network-only',
    //pollInterval: 1000,
    variables: {
      caseId: caseId
    },
  });

  const [createFlow, {error: createFlowError}] = useMutation(CLONE_FLOW, {
    // update: () => {
    //
    // }
    refetchQueries: [{
      query: GET_CASE_FLOWS,
      variables: {
        caseId: caseId
      }
    }]
  })

  const assign = (flowID: string) => {
    if (caseId) {
      createFlow({
        variables: {
          caseId: caseId,
          flowId: flowID !== '' ? flowID : null
        }
      })
    }
  }

  const currentFlow = () => {
    if (data && data.caseFlows && data.caseFlows[0]) {
      return data.caseFlows[0]
    }

    return null
  }

  if (error || createFlowError) return <div>Error: {error}</div> 
  if (loading) return <div>Loading...</div>
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div>
        {
          !!allFlows && (
            <div>
              <HTMLSelect
                onChange={(e) => {
                  assign(e.target.value)
                }}
              >
                <option value={''}>
                  {currentFlow() ? 'Clear' : 'Assign Flow'}
                </option>
                {
                  allFlows.filter((item) => {return !item.isSubFlow}).map((item) => {
                    return (
                      <option value={item.id} key={item.id} selected={currentFlow() && currentFlow().template.id === item.id}>
                        {item.title ? item.title : ('Flow #' + item.id)}
                      </option>
                    )
                  })
                }
              </HTMLSelect>
            </div>
          )
        }
      </div>
      <div style={{paddingLeft: 20, paddingTop: 5}}>
        {
          currentFlow() && <FlowPreview flowId={currentFlow().id}/>
        }
      </div>
    </div>
  )
}

export default Tracking
