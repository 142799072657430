import React, { useState, useEffect, useContext }from 'react';
import { FormGroup, ControlGroup, HTMLSelect, InputGroup, Button, Checkbox, Alignment, MenuItem } from '@blueprintjs/core';
import { ContactsList } from './AddSelect';
import { UPDATE_CLINIC, ADD_NOTE } from './Helpers/gql';
import { useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { ClinicFormToaster } from './Toaster';
import { FormContext } from './Context';
import EditSwitch from './EditSwitch';
import RoleMultiSelect from './RoleMultiSelect';
import './styles.css';

const roles = ['Choose a role...', 'Primary', 'Physician', 'Billing Recipient', 'Nurse', 'Coordinator', 'Other'];
const options = roles.map((role, i) => {
    return <option value={role} label={role} key={i+1}>{role}</option>
});

export const roleTags = [{tagName: 'TRF'}, {tagName: 'Sample Submission'}, {tagName: 'IVF Lab'}, {tagName: 'Clinical Team'}, {tagName: 'M2 Reporting'}, {tagName: 'PGT Reporting'}, {tagName: 'Billing'}, {tagName:'Invoicing'}].map((t, i) => ({ ...t, index: i}));
export const renderTags = ( tag, { modifiers, handleClick, query }) => {
    if (!modifiers.matchesPredicate) {
        return null
    }
    const text = `${tag.tagName}`
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={tag.tagName}
            key={`${tag.tagName}-${tag.index}`}
            onClick={handleClick}
            text={text}
        />

    )
}
export const filterTags = (query, tag) => {
    return (
        tag.tagName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
};

export const tagSelectProps = {
    itemPredicate: filterTags,
    itemRenderer: renderTags,
    items: roleTags
};

export const ClinicContact = ({ data, id }) => {

    const [state, dispatch] = useContext(FormContext);
    const [activeItem, setActiveItem] = useState(null);
    const [tags, setTags] = useState([]);
    const [query, setQuery] = useState("");

    let currentLocation = useLocation();
    let history = useHistory();

    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
        // refetchQueries: [
        //     {
        //         query: CLINIC_DETAIL,
        //         variables: {
        //             id: id
        //         }
        //     }
        // ],
        onCompleted(clinicData) {
            const currentContacts = data.clinicDetail.contact
            const updatedContacts = clinicData.updateClinic.clinic.contact

              console.log(currentContacts, updatedContacts)

              if ((updatedContacts.length - 1) === currentContacts.length && values.notes.length) {
                addNote({
                  variables: {
                    clinicId: id,
                    type: `contact_notes_${(updatedContacts.length - 1)}`,
                    text: values.notes
                  }
                })
              }
            showToast();
            dispatch({
                 type: "RESET_DIRTY_FIELDS"
             });
             window.scrollTo(0, 0);
             setTags([]);
          }


    });
    const [addNote, { loading: addLoading, error: addError }] = useMutation(ADD_NOTE, {
        onCompleted(noteData) {
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0, 0);
         }
    });

    const [values, setValues] = useState({
        name: "",
        email: "",
        role: "",
        notes: "",
        telephone: "",
        isBilling: false,
        report: false,
        roleOther: ""
    });

    useEffect(()=> {
        setValues({
            name: "",
            email: "",
            role: "",
            notes: "",
            report: false,
            telephone: "",
            isBilling: false,
            roleOther: ""
        });
        setTags([]);
    }, [data]);


    const handleChange = (e) => {
        const { value, name } = e.target;
        console.log('value', value, 'name', name);
        setValues({...values, [name]: value});
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        });
    };

    const handleActiveItemChange = item => {
        if (state.isEditing) {
            setTags([...tags, item]);
            setActiveItem(item);
            setQuery("");
        }
    };

    const handleSubmit = e =>{
        e.preventDefault();
        let newContactData = data.clinicDetail.contact.map((contact) => {
            const res = {}
            Object.keys(contact).filter(key => key !== 'notes').forEach((key) => {
                res[key] = contact[key]
            })
            return res
        })

        updateClinic({
            variables: {
                id: id,
                input: {
                    contact: [
                      ...newContactData,
                      {
                        name: values.name,
                        role: (values.role === 'Other' && values.roleOther.length) ? values.roleOther : values.role,
                        email: values.email,
                        telephone: values.telephone,
                        report: values.report,
                        tags: [...tags]
                      }
                    ]
                }
            }
        })
    };

    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };

        return (
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <>
                        {updateError && <p>Error updating clinic: {updateError.message}</p>}
                        {addError && <p>Error adding note: {addError.message}</p>}
                        <EditSwitch />
                        <RouteLeavingGuard
                            when={Object.keys(state.dirtyFields).length > 0}
                            navigate={path=>history.push(path)}
                            shouldBlockNavigation={location=>{
                                if (Object.keys(state.dirtyFields).length > 0) {
                                    if (location.pathname !== currentLocation.pathname) {
                                        return true
                                    }
                                }
                                return false
                            }}
                        />
                    </>
                    <h1 className="form-header">Contact Details:</h1>
                    <FormGroup
                        labelFor="Name"
                        label="Contact Name:"
                    >
                        <InputGroup  readOnly={!state.isEditing} id={`Name`} type="text" name="name" placeholder="Enter name..." value={values.name} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup
                        labelFor="Email"
                        label="Contact Email"
                    >
                        <InputGroup readOnly={!state.isEditing} id={`Email`} placeholder="Enter email..." type="email" name="email" value={values.email} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup
                        labelfor="Phone"
                        label="Contact telephone:"
                        type="tel"
                        minLength="9"
                    >
                        <InputGroup readOnly={!state.isEditing} id="Phone" placeholder="Enter telephone..." type="tel" minLength={9} name="telephone" value={values.telephone} onChange={handleChange}  />
                    </FormGroup>
                    <ControlGroup
                        label="Role:"
                    >
                        <HTMLSelect disabled={!state.isEditing} name="role" value={values.role} onChange={handleChange} >
                            {options}
                        </HTMLSelect>
                        <Checkbox
                            style={{marginLeft: '5%'}}
                            alignIndicator={Alignment.RIGHT}
                            label="Reporting:"
                            checked={values.report}
                            onChange={()=>{setValues({...values, report: !values.report})}}
                            inline={true}
                            disabled={!state.isEditing}
                        />
                    </ControlGroup>
                    {values.role === "Other" ?
                        <FormGroup
                            labelFor="roleOther"
                            label="Enter Role:"
                        >
                            <InputGroup id="roleOther" name="roleOther" required={true} onChange={handleChange} value={values.roleOther} />
                        </FormGroup>
                    : null}
                    <RoleMultiSelect tags={tags} setTags={setTags} query={query} setQuery={setQuery} onActiveItemChange={handleActiveItemChange} itemdata={tagSelectProps} activeItem={activeItem} setActiveItem={setActiveItem} isEditable={state.isEditing} />
                    {/* <div>
                        <FormGroup
                            labelFor="note"
                            label="Add Note:"
                        >
                        <TextArea
                            readOnly={!state.isEditing}
                            id="notes"
                            name="notes"
                            value={values.notes}
                            onChange={handleChange}
                        />
                        </FormGroup>
                    </div> */}
                    <div style={{margin: '2% 0'}}>
                        <Button intent="primary" type="submit" disabled={updateLoading || addLoading}>Save Contact</Button>
                    </div>
                    {data.clinicDetail.contact.length ? <ContactsList options={options} isEditable={state.isEditing} data={data} id={id}/> : null}
                </form>
            </div>
        )
};

