import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import moment from 'moment';
import Address from './Address';
import TopSectionPGTPEthnicity from "./TopSectionPGTPEthnicity";


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: 8
  },

  section: {
    margin: 10,
    padding: 10,
    paddingLeft: 200,
  },

  rowSection: {
    display: 'flex',
    flexDirection: 'row',
  },

  rowSectionColumn: {
    flexGrow: 1,
    width: '50%'
  }
});


export default (props) => {
  const {
    caseId,
    clinicName,
    clinicianName,
    testTypes,
    sampleType,
    samplesReceivedAt,
    analysisVersion,
    reportSigned,
    clinicianPhone,
    clinicianEmail,
    clinicAddress,
    clientReference,
    reportInfo,
    isAmended,
    salivaSamples,
    patient,
    partner,
    selectedPanel,
    reportVersion, 
    layout,
    reportCycleNumber,
    cycleNumber
  } = props;

  const isM2 = !!testTypes && testTypes.includes('M2')

  const tubeLabels = () => {
    let res = null
    if (!!salivaSamples && salivaSamples.length > 0) {
      res = salivaSamples.map((item) => item.label).join(', ')
    }

    return res
  }

  const collectionDates = () => {
    let res = null

    if (!!salivaSamples && salivaSamples.length > 0) {
      res = salivaSamples.map((item) => !!item.collectionDate && moment(item.collectionDate).format("MM/DD/YYYY")).join(', ')
    }

    return res
  }

  return (
    <View>
      <View style={[styles.rowSection, {marginTop: 0}]}>
        <View style={styles.rowSectionColumn}>
          {(isM2 && !!collectionDates()) && <Text>Samples collected: {collectionDates()}</Text>}
          <Text>Samples received: {samplesReceivedAt ? moment(samplesReceivedAt).format("MM/DD/YYYY") : '-'}</Text>
          <Text>Report signed: {moment(reportSigned).format("MM/DD/YYYY")}</Text>
          <Text>Analysis version: {analysisVersion}</Text>
          {
            (testTypes && testTypes.includes('P') && !!reportVersion) && (
              <Text>Clinical report: {reportVersion}</Text>
            )
          }
        </View>

        <View style={styles.rowSectionColumn}>
          <Text>Case ID: {caseId}</Text>
          { 
            (!isM2 && reportCycleNumber) && (
              <Text>Cycle Number: {cycleNumber}</Text>
            )
          }
          {
            (isM2 && !!clientReference) && <Text>Your Reference #: {clientReference}</Text>
          }
          {
            (isM2 && tubeLabels()) && <Text>Tube Label: {tubeLabels()}</Text>
          }
          { sampleType === 'Trophectoderm and Whole Embryo' ? (
              <Text>Sample Type: Trophectoderm and {'\n'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whole Embryo</Text>
            ) : (
              <Text>Sample Type: {(isM2 && !sampleType.length) ? 'Saliva' : sampleType}</Text>
           )
          }
          {
            isM2 ? (
              <Text>Test Type: quantitative real-time PCR</Text>
            ) : (
              <Text>Test Type: {testTypes.join(', ')} {'\n'} 24 Chromosome Microarray</Text>
            )
          }
        </View>
      </View>

      <View style={layout === 1 ? [styles.rowSection, {marginTop: 40}] : [styles.rowSection, {marginTop: 10}]}>
        <View style={styles.rowSectionColumn}>
          <View style={{paddingRight: 5}}>
            <Text>Referring Clinician</Text>
            <Text style={{fontSize: 10, fontStyle: 'normal', fontWeight: 'semibold', marginTop: 5, marginBottom: 5}}>{clinicianName}</Text>
            <Text>{clinicName}</Text>
            <Text>{clinicianEmail}</Text>
            <Text>{clinicianPhone}</Text>
            <Address value={clinicAddress}/>
          </View>
        </View>

        <View style={styles.rowSectionColumn}>
          <Text>Processing Laboratory</Text>
          {layout === 1 ? 
            <View style={{fontSize: 10, fontStyle: 'normal', fontWeight: 'semibold', marginTop: 5, marginBottom: 5}}>
              <Text>Genomic Prediction</Text>
              <Text>Clinic Laboratory, Inc.</Text>
            </View> 
          : 
            <View style={{fontSize: 10, fontStyle: 'normal', fontWeight: 'semibold', marginTop: 5, marginBottom: 5}}>
              <Text>Genomic Prediction Clinic Laboratory, Inc.</Text>
            </View>
          }
          <Text>service@genomicprediction.com</Text>
          <Text>+1 (973) 529 4223</Text>
          <Text>671 B US Highway One,</Text>
          <Text>North Brunswick,</Text>
          <Text>NJ United States, 08902</Text>
        </View>
      </View>

      {
        !!reportInfo && (
          <View style={{
            marginTop: 12,
            marginLeft: -30,
            marginRight: -40,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 30,
            paddingRight: 40,
            backgroundColor: '#f8f8f8'
          }}>
            {isAmended && <Text>AMENDED REPORT</Text>}
            <Text>{reportInfo}</Text>
          </View>
        )
      }

      {
        (testTypes && testTypes.includes('P')) && (
          <TopSectionPGTPEthnicity patient={patient} partner={partner} selectedPanel={selectedPanel} />
        )
      }
    </View>
  )
}
