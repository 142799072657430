import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {ETHNICITIES_ALL, PANEL_OPTIONS} from "components/Report/index";


export default ({patient, partner, selectedPanel}) => {

  return (
    <View style={{
      marginTop: 12,
      marginLeft: -30,
      marginRight: -40,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 30,
      paddingRight: 40,
      backgroundColor: '#f8f8f8'
    }}>
      {
        selectedPanel && (
          <View
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
          >
            <View style={{width: '30%', fontSize: 7}}>
              <Text style={{fontStyle: 'normal', fontWeight: 'semibold'}}>PGT-P Panel: {PANEL_OPTIONS[selectedPanel] ? PANEL_OPTIONS[selectedPanel] : '-'}</Text>
            </View>
            <View style={{fontSize: 7}}>
              {
                (patient && patient.ethnicity) && (
                  <Text>Patient (or egg donor) self-reported ancestry: {ETHNICITIES_ALL[patient.ethnicity] ? ETHNICITIES_ALL[patient.ethnicity] : '-'}</Text>
                )
              }
              {
                (partner && partner.ethnicity) && (
                  <Text>Partner (or sperm donor) self-reported ancestry: {ETHNICITIES_ALL[partner.ethnicity] ? ETHNICITIES_ALL[partner.ethnicity] : '-'}</Text>
                )
              }
            </View>
          </View>
        )
      }
    </View>
  )
}
