import React, { useEffect } from 'react';
import AppRouter from 'router';
import axios from 'axios';
import {ApolloProvider, ApolloClient, InMemoryCache, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {SET_AUTH} from 'services/graphql/auth';
import { createUploadLink } from 'apollo-upload-client';

const storage = window.localStorage;

export const initialState = {
  loading: false,
  access_token: "",
  refresh_token: "",
  cp_persist_token: ""
};


export const MainContext = React.createContext(initialState);

export const MainContextProvider = (props) => {
  const [state, updateState] = React.useState(initialState);

  const urlParams = new URLSearchParams(window.location.search).get('t');
  const referringUrl = window.location.href;

  const appCache = new InMemoryCache({
    addTypename: false
  });
  
  //@ts-ignore
  const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_API_HOST}graphql/`,
  })
  
  const logoutLink = onError(({graphQLErrors, operation}) => {

    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  
        if (message === "PermissionDenied") {
          localStorage.removeItem('authToken');
          localStorage.removeItem('refreshToken');
          operation.getContext().cache.writeQuery({
            query: SET_AUTH,
            data: {
              auth: {
                isAuth: false,
                __typename: 'Auth'
              }
            }
          });
        }
      });
    }
  })
  
  const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('authToken');  
    // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          "Authorization": token ? `JWT ${token}` : "",
        }
      }
  });
  
  const client = new ApolloClient({
    // link: logoutLink.concat(authLink.concat(uploadLink)),
    link: from([
      logoutLink,
      authLink,
      uploadLink
    ]),
    cache: appCache
  });
  
  const persistentState = JSON.parse(storage.getItem('store'));

  const updatePersistentState = (data) => {
    storage.setItem('store', JSON.stringify(data));
  };

  const setLoadingIndicator = (value) => {
    updateState({
      ...state,
      loading: value,
    })
  };

  useEffect(() => {
    if (urlParams) {
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        url: `${process.env.REACT_APP_API_HOST}jwt-token/`,
        data: JSON.stringify({token: urlParams})
      })
      .then(resp => {
        const { data } = resp;
        window.localStorage.setItem('refreshToken', data.refresh_token);
        window.localStorage.setItem('authToken', data.access_token);
        window.localStorage.setItem('persist:clinic-portal', data.cp_persist_token)

        updateState(prev => ({
          ...prev,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          cp_persist_token: data.cp_persist_token
        }))
        window.location.href = referringUrl.split('?')[0];
      })
      .catch(e => console.log('error:', e))
    }
    else if (!urlParams && window.localStorage.getItem('authToken')) {
      updateState(prev =>({
        ...prev,
        access_token: window.localStorage.getItem('authToken'),
        refresh_token: window.localStorage.getItem('refreshToken') ? window.localStorage.getItem('refreshToken') : "",
        cp_persist_token: window.localStorage.getItem('persist:clinic-portal') ? window.localStorage.getItem('clinic-portal: persist') : ""
      }))
    }
  }, [urlParams, referringUrl]);

  return (
    <MainContext.Provider
      value={{...state, ...persistentState, updateState, updatePersistentState, setLoadingIndicator}}>
      <ApolloProvider client={client}>
        <AppRouter />
      </ApolloProvider>
    </MainContext.Provider>
  )
};
