import React, {useMemo} from 'react';
import {Text, View} from "@react-pdf/renderer";
import getAddressLines from "components/Report/services/getAddressLines";


const Address = ({value, ...props}) => {
  const address = useMemo(() => getAddressLines(value), [value])

  return (
    <View style={{marginTop: 5}}>
      {!!address.line1 && <Text>{address.line1}</Text>}
      {!!address.line2 && <Text>{address.line2}</Text>}
      {!!address.line3 && <Text>{address.line3}</Text>}
    </View>
  )
}

export default Address;
