import React from 'react';
import {Grid, Row, Col} from "react-flexbox-grid";
import {H2, H4} from "@blueprintjs/core";


export default (props) => {
  return (
    <div className="summary">
      <H4 className="text-center" style={{marginBottom: 60}}>Summary</H4>
      <Grid fluid>
        <Row>
          <Col xs={3}>
            <div className="text-center">
              <H2>Cases</H2>
              {/*<p>Cases</p>*/}
            </div>
          </Col>
          {/*<Col xs={3}>*/}
          {/*  <div className="text-center">*/}
          {/*    <H2>2</H2>*/}
          {/*    <p>Pending</p>*/}
          {/*  </div>*/}
          {/*</Col>*/}
          {/*<Col xs={3}>*/}
          {/*  <div className="text-center">*/}
          {/*    <H2>0</H2>*/}
          {/*    <p>Canceled</p>*/}
          {/*  </div>*/}
          {/*</Col>*/}
          {/*<Col xs={3}>*/}
          {/*  <div className="text-center">*/}
          {/*    <H2>$1000.00</H2>*/}
          {/*    <p>Received</p>*/}
          {/*  </div>*/}
          {/*</Col>*/}
        </Row>
      </Grid>
    </div>
  )
}
