import React, { useMemo } from 'react';
import { Button } from "@blueprintjs/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocument from "components/Report/PDFDocument";

const DownloadPDFLink = ({ data, sampleData, title }) => {

  return useMemo(
    ()=>(
      <PDFDownloadLink
      document={<PDFDocument data={data} sampleData={sampleData} title={title} />}
      fileName={title}>
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <Button icon="download" intent="primary">Download</Button>
      }
    </PDFDownloadLink>
    ),
  [data, sampleData, title],
  )
};

export default DownloadPDFLink;