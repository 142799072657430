import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CLINIC, CLINIC_DETAIL } from './Helpers/gql';
import { FormGroup, Radio, Checkbox, InputGroup, RadioGroup, Button } from '@blueprintjs/core';
import { FormContext } from './Context';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import { ClinicFormToaster } from './Toaster';
import  EditSwitch  from './EditSwitch';

const Sales = ({ data, id }) => {
    const [state, dispatch]= useContext(FormContext);
    let currentLocation = useLocation();
    let history = useHistory();

    const [values, setValues] = useState({
        pgtAIsBatched: "",
        pgtAVolumePerMonth: "",
        salesRep: "",
        protocolIsSent: false,
        onboardingCallIsConducted: false
    });

    const [updateClinic, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CLINIC, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: id
                }
            }    
        ], 
        onCompleted(){
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0, 0);
        }
    });

    useEffect(() => {
        if (data){
            const { clinicDetail } = data;
            setValues(prev => ({
                ...prev,
                pgtAIsBatched: clinicDetail.pgtAIsBatched || "",
                pgtAVolumePerMonth: clinicDetail.pgtAVolumePerMonth || "",
                salesRep: clinicDetail.salesRep || "",
                protocolIsSent: clinicDetail.protocolIsSent || false,
                onboardingCallIsConducted: clinicDetail.onboardingCallIsConducted || false,
            }));
        }
    }, [data]);

    const handleChange = (e) => {    
        const { value, name, type } = e.target;
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        });
        if (type === 'checkbox') {
            console.log(values[name])
            if (values[name] === true) setValues({...values, [name]: false})
            else setValues({...values, [name]: true})
        }
        else setValues({...values, [name]: value})
    };
 
    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };

    //converts value of radio button to type boolean from type string:
    const boolean = values.pgtAIsBatched === "Yes";


    const handleSubmit = e => {
        e.preventDefault();
        updateClinic({
            variables: {
                id: id, 
                input: {
                    pgtAIsBatched: boolean,
                    pgtAVolumePerMonth: parseFloat(values.pgtAVolumePerMonth) || 0,
                    salesRep: values.salesRep,
                    protocolIsSent: values.protocolIsSent,
                    onboardingCallIsConducted: values.onboardingCallIsConducted
                }
            }
        })
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                {updateError && <p>Error updating information: {updateError.message}</p>}
                    <EditSwitch />
                    <RouteLeavingGuard 
                        when={Object.keys(state.dirtyFields).length > 0}
                        navigate={path=>history.push(path)}
                        shouldBlockNavigation={location=>{
                            if (Object.keys(state.dirtyFields).length > 0) {
                                if (location.pathname !== currentLocation.pathname) {
                                    return true
                                }
                            }
                            return false
                        }}
                    />
                <h1 className="form-header">Sales:</h1>
                <FormGroup
                    label="Rep Assigned to Clinic:"
                    labelFor="rep"
                >
                    <InputGroup readOnly={!state.isEditing} type="text" id="rep" name="salesRep" value={values.salesRep} onChange={handleChange} />
                </FormGroup>
                <FormGroup
                    labelFor="test-volume"
                    label="PGT-A Volume per month:">
                    <InputGroup readOnly={!state.isEditing} id="test-volume" name="pgtAVolumePerMonth" value={values.pgtAVolumePerMonth} onChange={handleChange} />
                </FormGroup>
                <RadioGroup
                        disabled={!state.isEditing}
                        labelFor="batched"
                        label="Is PGT-A batched?"
                        name="pgtAIsBatched"
                        selectedValue={values.pgtAIsBatched}
                        onChange={handleChange}
                >
                    <Radio label="Yes" name="yes" value="Yes" />
                    <Radio label="No" name="no" value="No" />
                </RadioGroup>
                <h1>Onboarding:</h1>
                    <Checkbox
                        disabled={!state.isEditing}
                        checked={values.protocolIsSent}
                        name="protocolIsSent"
                        label="Has protocol been sent to clinic?"
                        onChange={handleChange}
                    />
                    <Checkbox
                        disabled={!state.isEditing}
                        checked={values.onboardingCallIsConducted}
                        name="onboardingCallIsConducted"
                        label="Has an official onboarding call been conducted with the staff?"
                        onChange={handleChange}
                    />
                    <div>
                        <Button intent="primary" type="submit" disabled={updateLoading}>Save</Button>
                    </div>
            </form>
        </div>
    )
};

export default Sales;