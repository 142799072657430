import React, {FunctionComponent, useState} from 'react';

type ComponentProps = {
    id: number;
    title: string;
    active: boolean;
}

const FlowListItem: FunctionComponent<ComponentProps> = (
    {id = null, title = null, active = false}
    ) => {
    const [hovered, setHovered] = useState(false)

    return (
        <div
            style={{
                background: hovered ? '#eaeaea' : 'transparent',
                padding: '6px 20px 6px 20px',
                color: active ? '#ef7f2a' : '#0a5d7c',
                //fontWeight: 'bold'
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {title ? title : <span>Flow #{id}</span>}
        </div>
    )
}

export default FlowListItem;
