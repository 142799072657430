import React, {FunctionComponent, useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import {AnchorButton, Button, EditableText, H4} from "@blueprintjs/core";
import {gql, useMutation, useQuery} from "@apollo/client";
import FlowStepList from "./FlowStepList";
import {Flow as FlowInterface} from 'containers/Tracking'
import moment from "moment";
import LoadingOverlay from 'components/Common/Loading/LoadingOverlay';

export const GET_FLOW_BY_ID = gql`
    query flowById($id: ID) {
        flowById(id: $id) {
            id
            title
            description
            isTemplate
            createdAt
            template {
                id
            }
            steps {
                id
                title
                description
                required
                alertOnStart
                alertOnComplete
                alertBeforeDeadline
                alertOnDeadline
                alertAfterDeadline
                alertOnNote
                type
                durationDays
                subFlow {
                    id
                    title
                }
            }
        }
    }
`;

const CREATE_STEP = gql`
    mutation createFlowStep($flowId: ID!) {
        createFlowStep(flowId: $flowId) {
            flowStep{
                id
            }
        }
    }
`;


const DELETE_STEP = gql`
    mutation createFlowStep($id: ID!) {
        deleteFlowStep(id: $id) {
            ok
        }
    }
`;


const CLONE_FLOW = gql`
    mutation cloneFlowToFlow($flowId: ID) {
        cloneFlowToFlow(flowId: $flowId) {
            flow {
                id,
                title,
                description,
                createdAt,
                steps {
                    id
                    title
                    description
                    required
                }
            }
        }
    }
`;


type ComponentProps = {
  onDeleteFlow: (id: number) => void,
  onUpdateFlow: (id: number, args?: {title?: string, description?: string, isTemplate?: boolean, steps?: any[]}) => void
}

type paramsType = {
  flowId: string
}

const FlowDetail: FunctionComponent<ComponentProps> = ({onDeleteFlow, onUpdateFlow}) => {
  const params: paramsType = useParams();
  const location = useLocation();
  const {flowId}= params;

  const [flowData, setFlowData] = useState<FlowInterface | any>({});
  const [title, setTitle] = useState('');

  const [deleteStep, {loading: deleteStepLoading}] = useMutation(DELETE_STEP, {
    refetchQueries: [
      {
        query: GET_FLOW_BY_ID,
        variables: {id: flowId}
      }
    ]
  });

  const [createFlowStep, {loading: createFlowStepLoading}] = useMutation(CREATE_STEP, {
    refetchQueries: [
      {
        query: GET_FLOW_BY_ID,
        variables: {id: flowId}
      }
    ]
  });

  const {loading, error, data} = useQuery(GET_FLOW_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      id: parseInt(flowId)
    },
  });

  const [cloneFlow, {loading: cloneFlowLoading, error: cloneFlowError}] = useMutation(CLONE_FLOW)

  useEffect(() => {
    if (data && data.flowById) {
      setFlowData(data.flowById)
    }
  }, [data]);

  useEffect(() => {
    if (flowData && flowData.steps) {
      setTitle(flowData.title)
    }
  }, [flowData])

  const onStepOrderUpdate = (items: any) => {
    if (flowData) {
      onUpdateFlow(flowData.id, {steps: items.map((item: { id: any; }, index: number) => ({id: item.id, order: index}))})
    }
  }

  const onStepDelete = (id: number) => {
    deleteStep({
      variables: {
        id: id
      }
    })
  }


  if (error || cloneFlowError) {
    //return <Redirect to={'/tracking/'} />
    return <p>Error: {error}</p>
  }
  if (loading) {
    return <p>Loading...</p>
  }
  return data ? (
    <div style={{padding: 20}}>
      <LoadingOverlay show={createFlowStepLoading || cloneFlowLoading || deleteStepLoading}/>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <H4 style={{margin: 0}}>
          <EditableText
            alwaysRenderInput={false}
            placeholder={'Flow #' + flowData.id}
            selectAllOnFocus={true}
            value={title}
            onChange={(value) => setTitle(value)}
            onConfirm={(value) => {
              onUpdateFlow(flowData.id, {title: value})
            }}
          />
        </H4>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <div style={{padding: '0 5px'}}>Created: {moment(flowData.createdAt).format('MM/DD/YYYY')}</div>
          <div style={{padding: '0 5px'}}>
            <Button
              icon="document"
              intent={'none'}
              onClick={() => {
                cloneFlow({
                  variables: {
                    flowId: flowData ? flowData.id : null
                  }
                })
              }}
            >
              Clone this flow
            </Button>
          </div>
          <div style={{padding: '0 5px'}}>
            <Button
              icon={'plus'}
              intent={'none'}
              onClick={() => {
                createFlowStep({
                  variables: {
                    flowId: flowData.id
                  }
                })
              }}
            >
              Add Step
            </Button>
          </div>
          <div style={{padding: '0 5px'}}>
            <AnchorButton href={location.pathname + 'preview/'}>Preview</AnchorButton>
          </div>
          <div style={{padding: '0 5px'}}>
            <Button
              icon={'trash'}
              intent={'danger'}
              onClick={() => onDeleteFlow(flowData.id)}
            >
              Delete
            </Button>
          </div>

        </div>
      </div>

      <FlowStepList
        data={data.flowById}
        onStepOrderUpdate={onStepOrderUpdate}
        onStepDelete={onStepDelete}
      />
    </div>
  ) : (
    <div>
      Create a flow
    </div>
  )
}

export default FlowDetail
