import React from 'react';


function usePrevious(data, default_value = null) {
  const ref = React.useRef(default_value);
  React.useEffect(() => {
    ref.current = data
  }, [data])
  return ref.current
}

export default usePrevious
