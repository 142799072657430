import React, { useState, useContext, useEffect } from 'react';
import { FormContext }from './Context';
import { useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteLeavingGuard } from './RouteLeavingGuard';
import  EditSwitch  from './EditSwitch';
import { ClinicFormToaster } from './Toaster';
import { CLINIC_DETAIL, UPDATE_CLINIC, ADD_NOTE } from './Helpers/gql';
import { Button, TextArea, RadioGroup, Radio } from '@blueprintjs/core';
import { KitNotes } from './Notes';
import './styles.css';

const Kits = ({ data, id }) => {
    const [state, dispatch] = useContext(FormContext);
    let currentLocation = useLocation();
    let history = useHistory();

    const [values, setValues] = useState({
        biopsyKitPackage: "",
        biopsyKitNotes: {type: "", text: ""},
        salivaKitNotes: {type: "", text: ""}
    });
    const [addNote, { error: addNoteError }] = useMutation(ADD_NOTE, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: id
                }
            }    
        ], 
        onCompleted(){
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0, 0);
        }
    });

    const [updateClinic, { error: updateError }] = useMutation(UPDATE_CLINIC, {
        refetchQueries: [
            {
                query: CLINIC_DETAIL, 
                variables: {
                    id: id
                }
            }    
        ], 
        onCompleted(){
            showToast();
            dispatch({
                type: "RESET_DIRTY_FIELDS"
            });
            window.scrollTo(0,0);
        }
    });

    useEffect(() => {
        if (data) {
            const { clinicDetail } = data;
            setValues({
                biopsyKitPackage: clinicDetail.biopsyKitPackage || "",
                biopsyKitNotes: {type: "", text: ""},
                salivaKitNotes: {type: "", text: ""}
            })
        }
        
    }, [data]);
  
    useEffect(() => {
        console.log('values', values)
    }, [values])

    const handleChange = (e) => {
        const { value, name } = e.target;
        dispatch({
            type: "MARK_DIRTY_FIELD",
            payload: name
        })
        if (name !== "biopsy_kit_notes" && name !== "saliva_kit_notes") {
            console.log('value', value, 'name', name);
            setValues({...values, [name]: value})
        }
        else if (name === "biopsy_kit_notes") {
            setValues({...values, biopsyKitNotes: {type: name, text: value}});
        }
        else if (name === "saliva_kit_notes") {
            setValues({...values, salivaKitNotes: {type: name, text: value}})
        }
        else console.log(values)
    };

    const handleSubmit = e => {
        e.preventDefault();
        updateClinic({
            variables: {
                id: id, 
                input: {
                    biopsyKitPackage: values.biopsyKitPackage,
                }
            }
        })
    };

    const submitNote = e => {
        if (e.currentTarget.id === 'biopsy_kit_notes-addBtn' && values.biopsyKitNotes.text.length > 0) {
            addNote({
                variables: {
                    clinicId: id,
                    type: values.biopsyKitNotes.type,
                    text: values.biopsyKitNotes.text
                }
            })
        }
        else if (e.currentTarget.id === 'saliva_kit_notes-addBtn' && values.salivaKitNotes.text.length) {
            addNote({
                variables: {
                    clinicId: id,
                    type: values.salivaKitNotes.type,
                    text: values.salivaKitNotes.text
                }
            })
        }
    }
    //data.clinicDetail.biopsyKitNotes ? [...data.clinicDetail.biopsyKitNotes, values.biopsyKitNotes] : 
    //data.clinicDetail.salivaKitNotes ? [...data.clinicDetail.salivaKitNotes, values.salivaKitNotes] : 
    const showToast = () => {
        ClinicFormToaster.show({message: "Clinic information updated."})
    };
 
    
    return(
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <EditSwitch />
                <RouteLeavingGuard 
                    when={Object.keys(state.dirtyFields).length > 0}
                    navigate={path=>history.push(path)}
                    shouldBlockNavigation={location=>{
                        if (Object.keys(state.dirtyFields).length > 0) {
                            if (location.pathname !== currentLocation.pathname) {
                                return true
                            }
                        }
                        return false
                    }}
                />
                <h1 className="form-header">Kits</h1>
                <div className="kitPackage">
                    <RadioGroup
                        disabled={!state.isEditing}
                        label="Select biopsy kit package:"
                        selectedValue={values.biopsyKitPackage}
                        name="biopsyKitPackage"
                        onChange={handleChange}
                    >
                        <Radio label="Standard" name="biopsyKitPackage" value="standard" />
                        <Radio label="High Capacity" name="biopsyKitPackage" value="high capacity"/>
                    </RadioGroup>
                    <div>
                        <Button className="buttonContainer" intent="primary" type="submit" text="Save"></Button>
                        {updateError && <p>Error updating biopsy kits: {updateError.message} </p>}
                    </div>
                </div>
                <div className="notesContainer">
                    {addNoteError && <p>Error adding note: {addNoteError.message}</p>}      
                    <div className="biopsyNotesColumn">     
                    <h2>Biopsy Kit Notes:</h2>
                        <>
                            <TextArea
                                readOnly={!state.isEditing}
                                name="biopsy_kit_notes"
                                growVertically={true}
                                value={values.biopsyKitNotes.text}
                                onChange={handleChange}
                            /> 
                            <div className="buttonContainer">
                                <Button id="biopsy_kit_notes-addBtn" onClick={submitNote}>Add Note</Button>
                            </div>
                        {data.clinicDetail.biopsyKitNotes.length ? <KitNotes name="biopsyKitNotes" type="biopsy_kit_notes" data={data} id={id} isEditable={state.isEditing}/> : null }
                        </>
                    </div>
                    <div className="salivaNotesColumn">
                        <h2>Saliva Kit Notes:</h2>
                        <>
                            <TextArea
                                readOnly={!state.isEditing}
                                name="saliva_kit_notes"
                                growVertically={true}
                                value={values.salivaKitNotes.text}
                                onChange={handleChange}
                            />
                            <div className="buttonContainer">
                                <Button id="saliva_kit_notes-addBtn" onClick={submitNote}>Add Note</Button>
                            </div>
                            {data.clinicDetail.salivaKitNotes.length ? <KitNotes name="salivaKitNotes" type="saliva_kit_notes" data={data} id={id} /> : null }
                        </>
                    </div>
                </div>
        </form>
    </div>
    )  
};

// const useFormInput = (initialValue) => {
//     const [value, setValue] = useState(initialValue);

//     const handleChange = e => {
//         setValue(e.target.value);  
//     };
//     const handleReset = () => {
//         setValue("");
//     };

    
//     return {
//         value,
//         onChange: handleChange,
//         onReset: handleReset
//     };
// };
export default Kits;

//     const add = () => {
//         const bNote = `biopsy-note-${additionalBiopsyNotes.length}`;

//         setAdditionalBiopsyNotes([...additionalBiopsyNotes, {[bNote] : biopsyNote} ]);
        
//     };
//     const handleChange = e => {
//         setBiopsyNote(e.target.value)
//     };


//     const save = () => {
//         // const copy = JSON.parse(JSON.stringify(additionalBiopsyNotes));
//         // const text = bNoteRef.current.props.value.trim();
//         // const {id, value } = additionalBiopsyNotes[additionalBiopsyNotes.length-1];
//         // console.log('id', id, 'value', value)
//         // if (text.length) {
//         //     for (let obj in copy) { 
//         //         for (let [key, val] of Object.entries(copy[obj])) {
//         //             console.log('key', key)
//         //             if (key === 'id' && copy[obj][key] === id) {
//         //                 // copy[obj][key] = biopsyNote;
//         //                 console.log('true')
                        
//         //             }
//         //         }
//         //     }
//         // }
     
//         // setAdditionalBiopsyNotes([...copy]);
//     }
    
//    console.log(additionalBiopsyNotes);

//     return(
//         <div>
//             <h1>Kits:</h1>
//                 <Button onClick={()=>add()}>Add Note</Button>
            
//             {/* <FormGroup
//                 label="Biopsy Note:"
//                 labelFor={`biopsy-note-0`}
//             >
//                 <TextArea
//                     ref={bNoteRef}
//                     id={`biopsy-note-0`}
//                     growVertically={true}
//                     large={true}
//                     onChange={handleChange}
//                     value={biopsyNote} />
//             </FormGroup> */}
            
//             {/* <Button id="saveBiopsyKitNote">Save Note</Button> */}
//             {additionalBiopsyNotes.length ? additionalBiopsyNotes.map((el, i) => (
//                 <AddNote 
//                     ref={bNoteRef}
//                     id={`biopsy-note-${i}`}
//                     key={`biopsy-note-${i}`}
//                     value={biopsyNote}
//                     add={add}
//                     onChange={handleChange}
//                     save={save}

//                 />
//             ))
//             : null
//             }
//         </div>
//     )