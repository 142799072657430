import React from 'react';
import 'layouts/Main/style/main.css';
import {
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Alignment,
  NavbarDivider,
  AnchorButton
} from "@blueprintjs/core";
import {useApolloClient} from '@apollo/client';
import {SET_AUTH} from "services/graphql/auth";

export default (props) => {
  const client = useApolloClient();

  return (
    <div className="wrap">
      <Navbar style={{zIndex: 9999}} fixedToTop>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>
            Dashboard
          </NavbarHeading>
          <NavbarDivider />
          <AnchorButton minimal icon="home" text="Home" href="/" />
          <AnchorButton minimal icon="settings" text="Settings" href="/report/settings" />
          {/* <AnchorButton minimal icon="clipboard" text="Clinics" href="/clinics" />
          <AnchorButton minimal icon="scatter-plot" text="Stats" href="/stats" /> */}
          <AnchorButton minimal icon="send-to" text="QReview" href="/qreview" />
          <AnchorButton minimal icon="satellite" text="Tracking" href="/tracking" />
          <AnchorButton minimal icon="timeline-line-chart" text="Performance" href="/performance" />
          {/* <AnchorButton minimal icon="timeline-line-chart" text="Metrics & KPIs" href="/kpi" /> */}
          <AnchorButton minimal icon="form" text="Clinic Form" href="/clinicform" />
        </NavbarGroup>
        {/*<NavbarGroup align={Alignment.RIGHT}>*/}
        {/*  {loading ? <Spinner size={20}/> : ''}*/}
        {/*</NavbarGroup>*/}
        <NavbarGroup align={Alignment.RIGHT}>
          <AnchorButton
            minimal
            icon="log-out"
            text="Logout"
            href="/auth/login"
            onClick={(e) => {
              //e.preventDefault();
              localStorage.removeItem('authToken');
              client.resetStore().then(() => {
                client.cache.writeQuery({
                  query: SET_AUTH,
                  data: {
                    auth: {
                      isAuth: false,
                      __typename: 'Auth'
                    }
                  }
                });
                //history.push("/auth/login");
              });
            }}
          />
        </NavbarGroup>
      </Navbar>

      <div className="content">
        {props.children}
      </div>
    </div>
  )
}
